import React from "react";
import Button from "@material-ui/core/Button";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { logout } from "../../redux/modules/UserAuth/action-creator";
import { checkAuthentication } from "../../redux/modules/UserAuth/action-creator";
import "./dashboard.scss";

class Dashboard extends React.Component {
  constructor() {
    super();
    this.logoutUser = this.logoutUser.bind(this);
  }

  componenetDidMount() {
    // if(!this.props.checkAuthentication())
    //   this.props.history.push("/");
  }

  logoutUser() {
    localStorage.clear();
    this.props.logout();
    this.props.history.push("/");
  }

  render() {
    return (
      <div>
        <h1>Dashboard</h1>
        <Button
          variant="contained"
          className="logout-btn"
          onClick={() => this.logoutUser()}
        >
          LOGOUT
        </Button>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    authUser: state.userAuthReducer.authUser,
    isFetching: state.userAuthReducer.isFetching
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      logout,
      checkAuthentication
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Dashboard);
