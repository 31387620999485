import React, { Component } from "react";
import HttpsRedirect from "react-https-redirect";
import { Route, Switch, Redirect } from "react-router-dom";
import ForgotPassword from './pages/ForgotPassword/ForgotPassword';
import ResetPassword from './pages/ResetPassword/ResetPassword';
import {
  Home,
  Campaign,
  Checkout,
  Whychooseus,
  Contactus,
  Team,
  Thankyou,
  Login
} from "./pages";
import AddVolunteer from "./components/Signup/AddVolunteer";
import JoinCampaign from './pages/JoinCampaign/JoinCampaign';
// import ContactForm from './pages/ContactForm'; 
import VolunteerTeam from './components/VolunteerTeam/VolunteerTeam';
import NotFound404 from "./components/notfound/notfound404";

import AddTeam from "./components/AddTeam/AddTeam";
import VolunteerConfirmation from "./components/Signup/Thankyou";

import HeaderContainer from "./containers/Base/HeaderContainer";
import FooterContainer from "./containers/Base/FooterContainer";
import Helpdesk from "./components/Helpdesk/Helpdesk";

import Dashboard from "./layouts/Dashboard/Dashboard";
import storage from "./lib/storage";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import AOS from "aos";
import "aos/dist/aos.css";
import "./responsive.scss";
import { setUserInfo } from "./redux/modules/UserAuth/action-creator";

class App extends Component {
  initializeUserInfo = async () => {
    const loggedInfo = storage.get("loggedInfo");
    if (!loggedInfo) return;

    const { UserActions } = this.props;
    UserActions.setLoggedInfo(loggedInfo);
    try {
      await UserActions.checkStatus();
    } catch (e) {
      storage.remove("loggedInfo");
      window.location.href = "/auth/login?expired";
    }
  };

  enableAnimation = () => {
    AOS.init({
      duration: 1000
    });
  };

  componentDidMount() {
    //this.initializeUserInfo();
    this.enableAnimation();
  }

  render() {
    const {
      location: { pathname }
    } = this.props;
    return (
      <HttpsRedirect>
        {pathname !== "/login" && pathname.indexOf("/dashboard") === -1 ? (
          <div>
            <HeaderContainer pathname={pathname} />
            <div className="row">
              <Helpdesk pathname={pathname} />
            </div>
            <Switch>
              <Route exact path="/" component={Home} />
              <Route exact path="/checkout" component={Checkout} />
              <Route exact path="/whychooseus" component={Whychooseus} />
              <Route exact path="/contactus" component={Contactus} />
              {/* <Route exact path="/cupad" component={ContactForm} /> */}
              <Route exact path="/thank-you" component={Thankyou} />
              <Route exact path="/not-found-404" component={NotFound404} />
              <Route exact path="/forgot-password" component={ForgotPassword} />
              <Route exact path="/reset-password/:email/:id" component={ResetPassword} />
              <Route
                exact
                path="/signup-volunteer/:organization/:campaign"
                component={AddVolunteer}
              />
              <Route
                exact
                path="/join-campaign/:organization/:campaign"
                component={JoinCampaign}
              />
              <Route
                exact
                path="/signup-volunteer-team/:organization/:campaign/:campaignPage"
                component={VolunteerTeam}
              />
                            <Route
                exact
                path="/signup-volunteer-team/:organization/:campaign"
                component={VolunteerTeam}
              />
              <Route
                exact
                path="/add-team/:organization/:campaign"
                component={AddTeam}
              />
              <Route
                exact
                path="/volunteer-confirmation/:organization/:campaign"
                component={VolunteerConfirmation}
              />
              <Route exact path="/:campaign/" component={Campaign} />
              <Route exact path="/:campaign/:team" component={Team} />
            </Switch>
            <FooterContainer />
          </div>
        ) : (
            <Switch>
              {pathname === "/login" ? (
                <Route path="/login" component={Login} />
              ) : !localStorage.userInfo ? (
                <Redirect to="/login" component={Login} />
              ) : JSON.parse(localStorage.getItem("userInfo")).role ===
                "admin" || "super-admin" ? (
                      <Route path="/dashboard" component={Dashboard} />
                    ) : (
                      <Redirect to="/" component={Home} />
                    )}
            </Switch>
          )}
      </HttpsRedirect>
    );
  }
}

const mapStateToProps = state => {
  return {
    authUser: state.userAuthReducer.authUser
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      setUserInfo
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(App);
