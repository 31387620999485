import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Paper, Button, Avatar, CssBaseline, FormControl, Input, InputLabel, Typography, withStyles } from '@material-ui/core';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import ErrorIcon from '@material-ui/icons/Error';
import Snackbar from '../../components/Snackbar/Snackbar';
import { MSGS } from '../../messages';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import './ResetPassword.scss';
import { resetPassword } from '../../redux/modules/UserAuth/action-creator'
import LoadingOverlay from 'react-loading-overlay';


const styles = theme => ({
    main: {
        width: 'auto',
        display: 'block', // Fix IE 11 issue.
        marginLeft: theme.spacing.unit * 3,
        marginRight: theme.spacing.unit * 3,
        [theme.breakpoints.up(400 + theme.spacing.unit * 3 * 2)]: {
            width: 400,
            marginLeft: 'auto',
            marginRight: 'auto',
        },
    },
    paper: {
        marginTop: theme.spacing.unit * 8,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${theme.spacing.unit * 3}px`,
    },
    avatar: {
        margin: theme.spacing.unit,
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing.unit,
    },
    submit: {
        marginTop: theme.spacing.unit * 3,
    },
});

class ResetPassword extends Component {
    constructor(props) {
        super(props)
        this.state = {
            user: { password: '', confirmPassword: '' },
            showMessage: false,
            message: "",
            error: false,
        }
        this.send = this.send.bind(this)
        this.inputChange = this.inputChange.bind(this)
        this.userId = this.props.match.params.id;
        this.email = this.props.match.params.email;
    }

    componentWillMount() {

    }

    async send() {
        const { password, confirmPassword } = this.state.user;
        const { resetPassword } = this.props;
        if (password == '' || password == undefined || confirmPassword == '' || confirmPassword == undefined) return this.showSnackBar(MSGS.REQUIRED_ERR, true);
        if (password != confirmPassword)
            return this.showSnackBar(MSGS.PASSWORD_UNEQUAL, true);
        resetPassword(this.userId, this.email, password);
    } 3

    showSnackBar(message, isError) {
        this.setState({ showMessage: true, message, error: isError })
        setTimeout(() => {
            this.setState({ showMessage: false, message: "" })
        }, 5000)
    }

    inputChange = (event) => {
        let user = this.state.user;
        user[event.target.name] = event.target.value;
        this.setState({ user });
    }

    render() {
        const { classes } = this.props;
        return (
            <LoadingOverlay
                active={this.props.isFetching}
                spinner
                text='Please Wait..'
            >
                <div className='login-container'>
                    <Snackbar
                        message={this.state.message}
                        color={this.state.error ? 'danger' : 'success'}
                        icon={this.state.error ? ErrorIcon : CheckCircleIcon}
                        place='tc'
                        open={this.state.showMessage}
                    />
                    <main className={classes.main}>
                        <CssBaseline />
                        <Paper className={classes.paper}>
                            <Avatar className={classes.avatar}>
                                <LockOutlinedIcon />
                            </Avatar>
                            <Typography component="h1" variant="h5">
                                Reset Password
                      </Typography>
                            <form className={classes.form}>
                                <FormControl margin="normal" required fullWidth>
                                    <InputLabel htmlFor="password">New Password</InputLabel>
                                    <Input name="password" type="password" id="password" autoComplete="current-password" value={this.state.user.password} onChange={this.inputChange} />
                                </FormControl>
                                <FormControl margin="normal" required fullWidth>
                                    <InputLabel htmlFor="password">Confirm Password</InputLabel>
                                    <Input name="confirmPassword" type="password" id="confirmPassword" autoComplete="current-password" value={this.state.user.confirmPassword} onChange={this.inputChange} />
                                </FormControl>
                                <Button
                                    fullWidth
                                    variant="contained"
                                    color="primary"
                                    className={classes.submit}
                                    onClick={() => this.send()}
                                >
                                    Reset
                      </Button>
                            </form>
                        </Paper>
                    </main>
                </div>
            </LoadingOverlay>
        );
    }
}

const mapStateToProps = state => {
    return {
        authUser: state.userAuthReducer.authUser,
        isFetching: state.userAuthReducer.isFetching
    }
}

const mapDispatchToProps = dispatch => bindActionCreators({
    resetPassword
}, dispatch)


ResetPassword.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(ResetPassword));