import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import { ModalContent } from './ModalContent';
import { currencies } from '../../Common/currencies';

import './preCampaign.scss';

const styles = () => ({
  paper: {
    position: 'absolute',
    width: '100%',
    backgroundColor: 'transparent',
    outline: 'none',
    textAlign: 'center',
    height: '100%',
    overflowY: 'auto',
    overflowX: 'hidden',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
});

let countdown = null;

class preCampaign extends Component {
  constructor(props) {
    super(props);

    this.state = {
      remaining: '',
      currentTime: '',
      rem: {},
      total_days: '',
      name: '',
      matchFactor: ''
    }
  }

  componentDidMount() {
    countdown = setInterval(() => {
      if(this.check_zero()) {
        this.setState(prevState => ({
          currentTime: prevState.currentTime - 1000,
          rem: this.getRemaining(prevState.currentTime - 1000)
        }));
      } else {
        clearInterval(countdown);
        window.location.reload();
      }
    }, 1000);
  }

  componentWillReceiveProps(nextProps) {
    const { details: { preCampaignStartDate, preCampaignEndDate, name, matchFactor } } = nextProps;

    this.setState({
      remaining: (new Date(preCampaignEndDate)) - new Date(),
      currentTime: (new Date(preCampaignEndDate)) - new Date(),
      rem: this.getRemaining(new Date(preCampaignEndDate) - new Date()),
      total_days: ((new Date(preCampaignEndDate) - new Date(preCampaignStartDate)) / (60*60*24*1000) ),
      name,
      matchFactor
    })
  }

  getRemaining = (millisec) => {
    let seconds = Math.floor(millisec / 1000).toFixed(0);
    let minute = Math.floor(seconds / 60);
    seconds = seconds % 60;
    let hour = Math.floor(minute / 60);
    minute = minute % 60;
    let day = Math.floor(hour / 24);
    hour = hour % 24;
    if(seconds <= 0 && minute <= 0 && hour <= 0 && day <= 0 ) {
      return { day: 0, hour: 0, minute: 0, seconds: 0 }
    }
    return {
        day,
        hour,
        minute,
        seconds
    };
  }

  check_zero = () => {
    const { rem: { day, hour, minute, seconds } } = this.state;
    if(seconds <= 0 && minute <= 0 && hour <= 0 && day <= 0 ) {
      return false;
    }
    return true
  }

  handleDonate = (amount, donation_type, matchFactor) => {
    const { onCampaignActions, history, details: { campaignCurrency } } = this.props;
    const currency = currencies.find(e => e.code === campaignCurrency);

    clearInterval(countdown);
    onCampaignActions.setDonation({amount, donation_type, matchFactor, currency});
    history.push('/checkout');
  }

  render() {
    const { classes, details: { preCampaignVideoURL,teamInfo, status, preCampaignEndDate, campaignCurrency },teamPage } = this.props;
    const { total_days, rem: { day, hour, minute, seconds }, name, matchFactor } = this.state;
    return (
      <Modal
        open={true}
        className='backdrop'
      >
        <div className={'pre-campaign-container ' + classes.paper}>
          <ModalContent currency={campaignCurrency} preCampaignEnd={preCampaignEndDate} teamPage={teamPage} teamInfo={teamInfo} status={status} preCampaignVideoURL={preCampaignVideoURL} matchFactor={matchFactor} name={name} sec={seconds} mins={minute} hours={hour} days={day} total_days={total_days} handleDonate={this.handleDonate} />
        </div>
      </Modal>
    );
  }
}

const PreCampaign = withStyles(styles)(preCampaign);

export default PreCampaign;