import { config } from "../config";
import firebase from "firebase";

firebase.initializeApp(config);
const AUTH = firebase.auth();
const FIRESTORE = firebase.firestore();

const parseUser = user => {
  let parsedData = {};
  parsedData["email"] = user.user.email;
  parsedData["userId"] = user.user.uid;
  return parsedData;
};

export const login = (email, password) => {
  return new Promise(function (resolve, reject) {
    AUTH.signInWithEmailAndPassword(email, password)
      .then(user => {
        resolve(parseUser(user));
      })
      .catch(error => reject(error));
  });
};

export const resetPassword = email => {
  return new Promise(function (resolve, reject) {
    AUTH.sendPasswordResetEmail(email)
      .then(success => resolve(success))
      .catch(error => reject(error));
  });
};

export const createuser = (user) => {
  FIRESTORE.settings({
    timestampsInSnapshots: true
  });

  return new Promise(function (resolve, reject) {
    FIRESTORE.collection('users').add(user)
      .then(success => resolve(success))
      .catch(error => reject(error));
  });
}

export default firebase;
