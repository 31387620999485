import React, { Component } from "react";
import Countdown from "react-countdown-now";
import { Carousel } from "react-bootstrap";
import { connect } from "react-redux";

import "./Videobox.scss";

export default class Videobox extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {}

  render() {
    return (
      <div>
        <section className="videobox">
          <div className="container">
            <div className="row">
              <div className="col-md-5 col-md-offset-1 col-sm-6">
                <div className="video_iframe">
                  <div className="video_box_header">
                    <div
                      className="heading_tag_left heading_tag_left--custom text-right text-uppercase"
                      style={{ backgroundImage: "url(images/bgblue1.png)" }}
                    >
                      Meet Pledje
                    </div>
                  </div>
                  <iframe
                    className="border_frame"
                    width="100%"
                    height="215"
                    src="https://www.youtube.com/embed/RKkhH44iKfs"
                    frameBorder="0"
                    allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                  />
                </div>
              </div>

              <div className="col-md-5 col-sm-6">
                <div className="video_iframe">
                  <div className="video_box_header">
                      <div
                      className="heading_tag_right heading_tag_right--custom  text-left text-uppercase"
                      style={{ backgroundImage: "url(images/bluebg2.png)" }}
                    >
                      Testimonial
                    </div>
                  </div>
                  <iframe
                    className="border_frame"
                    width="100%"
                    height="215"
                    src="https://www.youtube.com/embed/ObSQ-Hjl9Jk"
                    frameBorder="0"
                    allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                  />
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="testimonial">
          <div className="container">
            <div className="row">
              <div className="col-sm-10 col-sm-offset-1">
                <div
                  className="carousel slide"
                  data-ride="carousel"
                  id="quote-carousel"
                >
                  <Carousel>
                    <Carousel.Item>
                      <p>
                        <img className="left_quote" src="/images/b1.png" />
                        With <span>Pledje,</span> we raised 3x more than previous campaigns!  <small className="small-custom"> R’ David Horowirtz </small>
                        <img className="right_quote" src="/images/b2.png" />
                      </p>
                      {/* <small>
                        <strong>David Cohen</strong>, Yachad Outreach Center
                      </small> */}
                    </Carousel.Item>
                    <Carousel.Item>
                      <p>
                        <img className="left_quote" src="/images/b1.png" />
                        They have cracked the code to get people to give larger donations. <small className="small-custom">  R’ Gavriel Hershoff </small>
                        <img className="right_quote" src="/images/b2.png" />
                      </p>
                      {/* <small>
                        <strong>David Cohen</strong>, Yachad Outreach Center
                      </small> */}
                    </Carousel.Item>
                    <Carousel.Item>
                      <p>
                        <img className="left_quote" src="/images/b1.png" />
                        <span>Pledje</span>{" "}
                        made a believer out of us. We weren’t even sure if we can meet our goal, yet made it to a bonus round!   Jason Moss – <small className="small-custom"> Jewish Federation </small>
                        <img className="right_quote" src="/images/b2.png" />
                      </p>
                      {/* <small>
                        <strong>David Cohen</strong>, Yachad Outreach Center
                      </small> */}
                    </Carousel.Item>
                  </Carousel>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}
