import React, { Component } from 'react';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { Donars } from '../../components/CampaignDetails/Donars';

class DonorList extends Component {
  constructor() {
    super();

    this.state = {
      value: 0,
      team: [],
      all: [],
      top: []
    }
  }

  componentWillReceiveProps(nextProps) {
    const { donors, id, donationDetails } = nextProps;
    const team = donors.filter(donor => donor.teamId === id);
    const top = donationDetails.slice(0, 4);

    this.setState({
      team: team,
      all: donors,
      top: top
    })
  }

  handleChange = (event, value) => {
    this.setState({ value });
  };

  render() {
    const { value, team, all, top } = this.state;
    const { campaignCurrency } = this.props; 

    return(
      <section className="donor-list">
        <Tabs className='tabs' value={value} indicatorColor="primary"
          textColor="primary" onChange={this.handleChange}>
          <Tab label="Team Donors" />
          <Tab label="All Donors" />
          <Tab label="Top Teams" />
        </Tabs>
        {value === 0 && <Donars donors={team} />}
        {value === 1 && <Donars donors={all} />}
        {value === 2 && <Donars donors={top} team={true} campaignCurrency={campaignCurrency} />}
      </section>
    );
  }
}

export { DonorList };
