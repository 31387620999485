import { handleActions } from 'redux-actions';
import * as action from './action';

const initialState = {
  organizations: [],
  campaigns: [],
  success: false,
  loading: false,
  error: ''
};

const getCampaigns = (payload) => {
  let camp = [];
  for(const p in payload) {
    let obj = payload[p];
    obj.campaignId = p;
    obj.bonusGoalEndDate = obj.bonusGoalEndDate ? new Date(obj.bonusGoalEndDate) : new Date()
    obj.campaignEndTime = obj.campaignEndTime ? new Date(obj.campaignEndTime) : new Date()
    obj.campaignStartTime = obj.campaignStartTime ? new Date(obj.campaignStartTime) : new Date()
    obj.preCampaignEndDate = obj.preCampaignEndDate ? new Date(obj.preCampaignEndDate) : new Date()
    obj.preCampaignStartDate = obj.preCampaignStartDate ? new Date(obj.preCampaignStartDate) : new Date()
    obj.imageURL = obj.mainContent ? obj.mainContent.imageURL : '';
    camp.push(obj);
  }

  return camp;
}

const handlers = {
  "LOADING": state => ({ ...state, loading: true }),
  [action.GET_ORGANIZATION] : (state, action) => ({ ...state, organizations: action.payload, loading: false }),
  [action.ORGANIZATION_ERR]: (state, action) => ({ ...state, loading: false, error: action.payload }),
  [action.FETCH_CAMPAIGNS]: (state, action) => ({ ...state, campaigns: getCampaigns(action.payload.campaigns) }),
  [action.ORG_CREATE]: state => ({ ...state, loading: false, success: true }),
  [action.ORG_CREATE_ERR]: (state, action) => ({ ...state, loading: false, success: false, error: action.payload }),
  [action.ROUTE_CREATE]: state => ({ ...state, loading: false, success: true }),
  [action.ROUTE_ERR]: (state, action) => ({ ...state, loading: false, success: false, error: action.payload }),
  [action.CAMPAIGN_CREATE]: state => ({ ...state, loading: false, success: true }),
  [action.CAMPAIGN_ERR]: (state, action) => ({ ...state, loading: false, success: false, error: action.payload }),
}
export default handleActions(handlers, initialState);
