import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { getOrganizationCollection, fetchCampaigns, addOrganization, removeOrganization, addRoute, addCampaign } from "../../redux/modules/NewCampaign/action-creator";
import { add } from "../../redux/modules/Users/action-creator";
import NewCampaign from "./index";

const mapStateToProps = state => {
  return {
    organizations: state.newCampaign.organizations,
    campaigns: state.newCampaign.campaigns,
    success: state.newCampaign.success,
    loading: state.newCampaign.loading,
    errorMessage: state.newCampaign.error,
    authUser: state.userAuthReducer.authUser
  }
};

const mapDispatchToProps = dispatch => {
  return {
    getOrganizations: bindActionCreators(getOrganizationCollection, dispatch),
    fetchCampaigns: bindActionCreators(fetchCampaigns, dispatch),
    addOrganization: bindActionCreators(addOrganization, dispatch),
    addRoute: bindActionCreators(addRoute, dispatch),
    addCampaign: bindActionCreators(addCampaign, dispatch),
    addUser: bindActionCreators(add, dispatch),
    removeOrganization: bindActionCreators(removeOrganization, dispatch)
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(NewCampaign);
