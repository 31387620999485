import React from 'react';
import { Select, MenuItem, Button, InputBase } from '@material-ui/core';
import { getStatuses, getStatusColor } from "../../helperFuntions";

export const Prospect = ({ details, handleViewMore, viewMore, editable, makeEditable, onChange, submit }) => {
  return (
    <div className='prospect-content'>
      <h2>{details.firstName} {details.lastName}</h2>
      <div className='suggestion'>Suggested Donation: <b>${details.suggestedDonation || 0}</b></div>
      <div className='prospect-btn'>
        <Select style={{ backgroundColor: details.status === 'Clear' ? '#d3e8e3' : getStatusColor(details.status) }} className='select-status' disableUnderline={true} onChange={onChange} name='status' value={details.status || 'Clear'}>
          { getStatuses().map((status, index) =>
              <MenuItem style={{ background: getStatusColor(status.status) }} id='status-item' key={index} value={status.status}>{status.status}</MenuItem>
            )
          }
        </Select>
      </div>
      <div className='info'>
        <div className='p-row'>
          <div className='col'>
            <i className="fa fa-phone" aria-hidden="true"></i>
            <div className='data'>
              <div>Mobile:</div>
              { !editable ?
                <div>{details.mobilePhone || '-'}</div> :
                <InputBase className='input' name='mobilePhone' value={details.mobilePhone} onChange={onChange} />
              }
            </div>
          </div>
          <div className='col'>
            <i className="fa fa-briefcase" aria-hidden="true"></i>
            <div className='data'>
              <div>Work:</div>
              { !editable ?
                <div>{details.workPhone || '-'}</div> :
                <InputBase className='input' name='workPhone' value={details.workPhone} onChange={onChange} />
              }
            </div>
          </div>
        </div>
        <div className='p-row'>
          <div className='col'>
            <i className="fa fa-home" aria-hidden="true"></i>
            <div className='data'>
              <div>Home:</div>
              { !editable ?
                <div>{details.homePhone || '-'}</div> :
                <InputBase className='input' name='homePhone' value={details.homePhone} onChange={onChange} />
              }
            </div>
          </div>
          <div className='col'>
            <i className="fa fa-envelope" aria-hidden="true"></i>
            <div className='data'>
              <div>Email:</div>
              { !editable ? 
                <div>{details.email.length > 20 ? `${details.email.substr(0, 17)}...` : details.email || '-'}</div> :
                <InputBase className='input' name='email' value={details.email} onChange={onChange} />
              }
            </div>
          </div>
        </div>
        { viewMore &&
          <div className='hidden-items'>
            <div className='p-row'>
              <div className='col'>
                <i className="fa fa-phone" aria-hidden="true"></i>
                <div className='data'>
                  <div>Address:</div>
                  { !editable ?
                    <div>{details.billingAddress || '-'}</div> :
                    <InputBase className='input' name='billingAddress' value={details.billingAddress} onChange={onChange} />
                  }
                </div>
              </div>
              <div className='col'>
                <i className="fa fa-briefcase" aria-hidden="true"></i>
                <div className='data'>
                  <div>City</div>
                  { !editable ?
                    <div>{details.billingCity || '-'}</div> :
                    <InputBase className='input' name='billingCity' value={details.billingCity} onChange={onChange} />
                  }
                </div>
              </div>
            </div>
            <div className='p-row'>
              <div className='col'>
                <i className="fa fa-home" aria-hidden="true"></i>
                <div className='data'>
                  <div>State:</div>
                  { !editable ? 
                    <div>{details.billingState || '-'}</div> :
                    <InputBase className='input' name='billingState' value={details.billingState} onChange={onChange} />                    
                  }
                </div>
              </div>
              <div className='col'>
                <i className="fa fa-home" aria-hidden="true"></i>
                <div className='data'>
                  <div>Country:</div>
                  { !editable ?
                    <div>{details.billingCountry || '-'}</div> :
                    <InputBase className='input' name='billingCountry' value={details.billingCountry} onChange={onChange} />
                  }
                </div>
              </div>
            </div>
          </div>
        }
         <div className='prospect-actions'>
          { !editable && <Button className='blue' onClick={makeEditable}>Edit</Button> }
          { editable && <Button className='green' onClick={submit}>Update</Button> }
          <Button className='blue' onClick={handleViewMore}>{viewMore ? 'Show Less' : 'Show more'}</Button>
          { editable && <Button className='blue' onClick={makeEditable}>Cancel</Button> }
        </div>
      </div>
    </div>
  )
}
