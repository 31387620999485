import React, { Component } from 'react';
import Countdown from 'react-countdown-now';
import { Carousel } from 'react-bootstrap';
import { connect } from 'react-redux';


import './Whypledje.scss';

export default class Whypledje extends Component {

    constructor(props) {
        super(props);
        this.state = {
        }

    }



    componentDidMount() {

    }

    render() {
        return (
            <div>
                <section className="why_pledje">
                    <div className="head_section">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-md-4 col-sm-6 col-xs-10 p0">
                                    <div className="heading_tag text-right text-uppercase" style={{ "backgroundImage": "url(/images/green_tag_left.png)" }}>Why Pledje?</div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="container">
                        <div className="row">
                            <div className="col-md-6 col-sm-6">
                                <div className="why_grid_main clearfix">
                                    <div className="w_grid clearfix" data-aos="zoom-out-right">
                                        <img src="images/w1.png" />
                                        <div className="why_txt">
                                            <h3>Larger Donations</h3>
                                            <p>Proprietary solutions that result in larger donations from your current donors</p>
                                        </div>
                                    </div>

                                    <div className="w_grid clearfix" data-aos="zoom-out-right">
                                        <img src="/images/w2.png" />
                                        <div className="why_txt">
                                            <h3>Credit Card Processing Fees as Low As 1%</h3>
                                            <p></p>
                                        </div>
                                    </div>

                                    <div className="w_grid clearfix" data-aos="zoom-out-right">
                                        <img src="/images/w3.png" />
                                        <div className="why_txt">
                                            <h3>Keep Up to 100%</h3>
                                            <p>Utilizing proven tactics to secure business sponsors covering the entire cost of campaign</p>
                                        </div>
                                    </div>

                                    {/* <div className="w_grid clearfix" data-aos="zoom-out-right">
                                <img src="/images/w2.png" />
                                <div className="why_txt">
                                <h3>Help you to grow Faster</h3>
                                <p>Utilizing proven tactics to secure business sponsors covering the entire cost of campaign</p>
                                </div>
                            </div> */}

                                </div>
                            </div>

                            <div className="col-md-6 col-sm-6">
                                <div className="why_grid_main clearfix">
                                    <div className="w_grid clearfix" data-aos="zoom-out-left">
                                        <img src="/images/w5.png" />
                                        <div className="why_txt">
                                            <h3>Unique Team</h3>
                                            <p>Google certified marketing team, award winning media production team</p>
                                        </div>
                                    </div>
                                    <div className="w_grid clearfix" data-aos="zoom-out-left">
                                        <img src="/images/w6.png" />
                                        <div className="why_txt">
                                            <h3>10 Unique Campaign Types to Choose from</h3>
                                            <p>Offered for the 1st time in the industry make your campaign unique & exciting</p>
                                        </div>
                                    </div>
                                    <div className="w_grid clearfix" data-aos="zoom-out-left">
                                        <img src="/images/w7.png" />
                                        <div className="why_txt">
                                            <h3>Tools to Succeed</h3>
                                            <ul className="tools-succeed-text">
                                               <li> Key-turn volunteer management tools + Mobile App </li>
                                                <li>Raffle & Monthly giving solution</li>
                                                <li>Call center module</li>
                                                <li>Pledge tracking module</li>
                                                <li>Industry’s 1st - Mass communication modules to simplify donation requests </li> 
                                            </ul>
                                        </div>
                                    </div>
                                    {/* <div className="w_grid clearfix" data-aos="zoom-out-left">
                                <img src="/images/w6.png" />
                                <div className="why_txt">
                                <h3>Unique marketing</h3>+++++++++++++++++++
                                <p>Utilizing proven tactics to secure business sponsors covering the entire cost of campaign</p>
                                </div>
                            </div> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>

        )
    }
}



