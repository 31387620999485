import React from "react";
import { CampaignVideo } from "../../pages/Campaign/CampaignVideo";
import { Calculator } from "./Calculator";
import { Countdown } from "./Countdown";

export const ModalContent = ({
  name,
  sec,
  mins,
  hours,
  days,
  total_days,
  handleDonate,
  matchFactor,
  preCampaignVideoURL,
  status,
  teamPage,
  teamInfo,
  preCampaignEnd,
  currency
}) => {
  
  return (
    <section className="pre-campaign">
      {teamPage && <p>
        <h2>
          Welcome To {teamInfo.pageName} 's Page
        </h2>
      </p>}
      <h1>TIME TO START OF CAMPAIGN</h1>
      <p>
        YOU CAN MAKE A DONATION TODAY{" "}
        <span className="green-text">
          WHICH WILL BE COUNTED TOWARDS THE CAMPAIGN
        </span>
      </p>
      <Countdown
        sec={sec}
        mins={mins}
        hours={hours}
        days={days}
        total_days={total_days}
      />
      <div className="main-section">
        { preCampaignVideoURL && <CampaignVideo video={preCampaignVideoURL} status={status} preCampaignEnd={preCampaignEnd} />}
        <div className="banner">
          YOUR NAME & DONATION WILL APPEAR ON THE CAMPAIGN PAGE ONCE THE
          CAMPAIGN IS LIVE
          <img
            className="left"
            alt="triangle"
            src="/images/Rectangle 5 copy.png"
          />
          <img className="right" alt="triangle" src="/images/Rectangle 5.png" />
        </div>
        <Calculator
          currency={currency}
          matchFactor={matchFactor}
          name={name}
          handleDonate={handleDonate}
        />
      </div>
    </section>
  );
};
