import React, { Component } from 'react';
import { Dialog, Input, Table, TableBody, TableCell, TableHead, TableRow, TablePagination, Button, withStyles } from '@material-ui/core';

const paperStyle = {
  userPopover: {
    width: '500px',
    maxHeight: '500px'
  },
  tableRow: {
    fontSize: 14
  },
  caption: {
    fontSize: 14,
  },
  toolbar: {
    padding: 0,
    height: 40,
    minHeight: 40
  }
};

class UserList extends Component {
  constructor() {
    super();

    this.state = {
      page: 0,
      rowsPerPage: 10
    }
  }

  handleChangePage = (e, p) => {
    this.setState({ page: p });
  }

  render() {
    const { openUserList, closeUserList, searchUser, userList, selectUser, classes, authUser } = this.props;
    const { page, rowsPerPage } = this.state;

    return (
      <Dialog
        classes={{ paper: classes.userPopover }}
        id='user-popover'
        open={openUserList}
        onClose={closeUserList}
      >
        <div className='close-btn'>
          <i className="fa fa-times" aria-hidden="true" onClick={closeUserList}></i>
        </div>
        <div className='search-user'>
          <Input disableUnderline={true} className='input' placeholder='Search' onChange={searchUser} />
        </div>
        <Button onClick={() => selectUser(authUser.uid)}>
          Assign to yourself
        </Button>
        <Table className='list'>
          <TableHead>
            <TableRow>
              <TableCell component='th'>First Name</TableCell>
              <TableCell component='th'>Last Name</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            { userList.length > 0 ?
              userList && userList.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((user, i) => 
                <TableRow key={i} hover onClick={() => selectUser(user.id)}>
                  <TableCell classes={{ root: classes.tableRow }}>{user.firstName}</TableCell>
                  <TableCell classes={{ root: classes.tableRow }}>{user.lastName}</TableCell>
                </TableRow>
              ) :
              <TableRow>
                <TableCell classes={{ root: classes.tableRow }}>No User Found</TableCell>
              </TableRow>
            }
          </TableBody>
        </Table>
        <TablePagination
          classes={{ caption: classes.caption, toolbar: classes.toolbar }}
          rowsPerPageOptions={[]}
          component="div"
          count={userList.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={this.handleChangePage}
        />
      </Dialog>
    )
  }
}

export default withStyles(paperStyle)(UserList);
