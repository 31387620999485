import React, { Component } from 'react';
import GeneralDonation from './GeneralDonation';
import { Items } from './Items';
import { currencies as currencyList } from "../../Common/currencies";
import './index.scss';

class CampaignItems extends Component {
  constructor() {
    super();

    this.state = {
      items: [],
      donate: [],
      showMore: '',
      currency: {
        name: 'United States Dollar (USD)',
        code: 'usd',
        sign: '$'
      }
    }
  }

  componentDidMount() {
    const { items, details, location } = this.props;
    this.setState({ items });
    let c = '';

    if (details.campaignCurrency) {
      c = currencyList.find(e => e.code === details.campaignCurrency);
    } else {
      c = currencyList.find(e => e.code === details.currency);
    }
    this.setState({ currency: c });
  }

  showMore = (id) => {
    this.setState({ showMore: id })
  }

  handleCurrency = (e) => {
    const { details, handleCurrency } = this.props;
    const c = details.currency.find(f => f.code === e.target.value);
    this.setState({ [e.target.name]: c });
    handleCurrency(c);
  }

  render() {
    const { items, currency } = this.state;
    const { handleDonate, donate, handleChange, donationType, details, location } = this.props;

    return (
      <div className='itemize flex-column'>
        <GeneralDonation handleCurrency={this.handleCurrency} details={details} currency={currency} selectedItems={donate} donate={handleDonate} donationType={donationType} handleChange={handleChange} />
        {items.length > 0 && <Items currency={currency} items={items} showMore={this.showMore} selectedItems={donate} donate={handleDonate} location={location} />}
      </div>
    )
  }
}

export default CampaignItems;