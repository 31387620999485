import React from 'react';
import { AppBar, IconButton, Button, Select, MenuItem } from '@material-ui/core';
import TvIcon from '@material-ui/icons/TvRounded';
import './custom.scss';
import SettingsIcon from '@material-ui/icons/Settings';
import GroupIcon from '@material-ui/icons/Group';
import PeopleIcon from '@material-ui/icons/People';
import HistoryIcon from '@material-ui/icons/History';
import PaymentIcon from '@material-ui/icons/Payment';
import EventNoteIcon from '@material-ui/icons/EventNote';
import SurroundSoundIcon from '@material-ui/icons/SurroundSound';
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
import ThumbsUpDownIcon from '@material-ui/icons/ThumbsUpDown';

const iconSelect=(title,baseColor) =>
{
  if(title == "CAPTUREDONATIONS")
  {
    return <PaymentIcon style={{ color: baseColor }} />
  }
  else if(title == "USERMANAGEMENT")
  {
    return <AssignmentIndIcon style={{ color: '006400' }} />
  }
  else if(title == "PROSPECTMANAGER")
  {
    return <ThumbsUpDownIcon style={{ color: baseColor }} />
  }
  else if(title == "CAMPAIGNREPORT")
  {
    return <EventNoteIcon style={{ color: baseColor }} />
  }
  else if(title == "CAMPAIGNVAULT")
  {
    return <HistoryIcon style={{ color: 'silver' }} />
  }
  else if(title == "TEAMMANAGEMENT")
  {
    return <GroupIcon style={{ color: '#7CFC00' }} />
  }
  else if(title == "LEADERSBOARD")
  {
    return <PeopleIcon style={{ color: '#fcbe11' }} />
  }
  else
  {
    return <SurroundSoundIcon style={{ color: baseColor }} />
  }
  
}

export const HeaderComponent = ({ title1, view, title2,capture, toggleCapture, baseColor, select, clearCache, campaignList, onCampaignChange, campaign, onClearCache }) =>(
  <AppBar id='header-admin' position="static" style={{ background: baseColor }}>
    <div className='title'>
      <IconButton color="inherit" aria-label="tv" rounded="true">
        {iconSelect(title1+title2,baseColor)}
        {/* <TvIcon style={{ color: baseColor }} /> */}
      </IconButton>
      <span className='title-text'>
        <b>{title1} </b><label>{title2}</label>
      </span>
    </div>
    { select ?
      <div className='campaign-select'>
        <div className='text'>Select Campaign</div>
        <Select disableUnderline
          className='campaign-selectbox'
          name="campaign"
          value = {campaign}
          onChange = {onCampaignChange}
        >
          { campaignList && campaignList.map((campaign,index) =>
            <MenuItem value={campaign}  key ={index}>{campaign.name}</MenuItem>
          )}
        </Select>
        {capture &&
        <Button className='add-team-btn' variant="contained" onClick={()=>toggleCapture(view)}> View Captured Donations </Button>
        }
      </div>
    : null }
  </AppBar>
)

export default HeaderComponent;