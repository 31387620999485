import React, { Component } from 'react';
import { Dialog, Button, DialogContent } from '@material-ui/core';
import { Header } from './Header';
import { SlideUp } from '../../../components/Common/Slide_up';
import { Prospect } from "./Components/Prospect";
import { Donation } from "./Components/Donation";
import { Notes } from './Components/Notes';
import './AccessProspect.scss';

class AccessProspect extends Component {
  constructor() {
    super();

    this.state = {
      details: {
        suggestedDonation: '',
        status: 'Clear',
        firstName: '',
        lastName: '',
        mobilePhone: '',
        workPhone: '',
        homePhone: '',
        email: '',
        billingAddress: '',
        billingCity: '',
        billingState: '',
        billingZip: '',
        billingCountry: '',
        donationAmount: '',
        paymentType: 'cash',
        routing_number: '',
        account_number: '',
        teamId: '',
        displayName: false,
        alias: '',
        dedicationMessage: '',
        specialNotes: '',
      },
      viewMore: false,
      list: [],
      editable: false
    }
  }

  componentDidUpdate(oldProps) {
    const { currentProspect, prospectsList, campaignReports } = this.props;
    if(currentProspect !== oldProps.currentProspect) {
      let donation = campaignReports && (campaignReports.find(rep => rep.prospectId === currentProspect.id) || {});
      if(donation && donation.id) {
        donation.donationId = donation.id
      }
      this.setState({ details: {...this.state.details, ...donation, ...currentProspect}, list: prospectsList }, () => {
        this.setState(prevState => ({
          details: {
            ...prevState.details,
            displayName: prevState.details.displayName && prevState.details.alias === 'Anonymous' ? true : false
          }
        }))
      });
    }
  }

  onChange = (e) => {
    e.persist();
    this.setState(prevState => ({
      details: {
        ...prevState.details,
        [e.target.name]: e.target.value
      },
      valueChanged: true
    }))
  }

  displayName = (e) => {
    e.persist();
    this.setState(prevState => ({
      details: {
        ...prevState.details,
        [e.target.name]: e.target.checked
      },
      valueChanged: true
    }))
  }

  viewMore = () => {
    this.setState({ viewMore: !this.state.viewMore })
  }

  next = () => {
    const { details, list } = this.state;
    for(const i in list) {
      if(list[i].id === details.id && i < list.length-1) {
        this.setState({ details: list[parseInt(i)+1] });
        break;
      }
    }
  }

  previous = () => {
    const { details, list } = this.state;
    for(const i in list) {
      if(list[i].id === details.id && i > 0) {
        this.setState({ details: list[parseInt(i)-1] });
        break;
      }
    }
  }

  setProspect = () => {
    const { details: { suggestedDonation, status, firstName, lastName, mobilePhone, workPhone, homePhone, email, billingAddress, billingCity, billingCountry, id, campaignId, organizationId } } = this.state;
    const payload = { suggestedDonation, status, firstName, lastName, mobilePhone, workPhone, homePhone, email, billingAddress, billingCity, billingCountry };
    if(id) {
      payload.id = id;
      payload.campaignId = campaignId;
      payload.organizationId = organizationId;
    }
    return payload
  }

  submit = () => {
    const { submit } = this.props;
    const payload = this.setProspect();
    submit(payload);
  }

  manualPayment = () => {
    const { handleManual } = this.props;
    const { details } = this.state;
    const prospect = this.setProspect();
    this.setState(prevState => ({
      details: {
        ...prevState.details,
        displayName: !prevState.details.displayName
      }
    }), () => {
      handleManual(prospect, details);
    })
  }

  makeEditable = () => {
    this.setState(prevState => ({
      editable: !prevState.editable
    }))
  }

  render() {
    const { handleClose, openModal } = this.props;
    const { details, viewMore, editable } = this.state;

    return (
      <Dialog className='mp-access' fullScreen={true} open={openModal} TransitionComponent={SlideUp} scroll='paper'>
        <DialogContent className='content'>
          <div className='sticky'>
            <Header {...this.props} />
            <div className='navigate'>
              <Button onClick={this.previous}>Previous</Button>
              <Button onClick={this.next}>Next</Button>
            </div>
          </div>
          <div className='prospect'>
            <Prospect details={details} viewMore={viewMore} handleViewMore={this.viewMore} editable={editable} makeEditable={this.makeEditable} onChange={this.onChange} submit={this.submit} />
          </div>
          <Donation details={details} handleDisplayName={this.displayName} onChange={this.onChange} />
          <div className='actions m20'>
            <Button className='blue' onClick={this.manualPayment}>Save</Button>
            <Button className='green' onClick={handleClose}>Cancel</Button>
          </div>
          <Notes />
        </DialogContent>
      </Dialog>
    );
  }
}

export default AccessProspect;
