import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as campaignActions from '../../../redux/modules/campaign';
import { Button, Dialog, AppBar, Toolbar, IconButton, withMobileDialog, withStyles } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { Currency } from './Currency';
import { Monthly } from './Monthly';
import { Custom } from './Custom';
import { MonthlyTotal } from './MonthlyTotal';
import { Multiplier } from './Multiplier';
import { SlideUp } from '../../Common/Slide_up';
import { Total } from './Total';
import { currencies as currencyList } from "../../../Common/currencies";
import { convertCurrency } from "../../../Common/CurrencyCalculator";
import './MobileCalculator.scss';

const paperStyle = {
  dialogPaper: {
    maxHeight: '100vh',
    height: '100vh'
  },
};

class MobileCalculator extends React.Component {
  constructor() {
    super();

    this.state = {
      focus: false,
      amount: [],
      monthly: '',
      custom: 'oneTime',
      donate_amount: '',
      donate_type: '',
      duration: '',
      other: false,
      total_amount:'',
      toggleRadio: false,
      disabled: true,
      matchFactor: 0,
      open_currency: false,
      currencies: [],
      currency: {
        name: 'United States Dollar (USD)',
        code: 'usd',
        sign: '$'
      },
    }
  }

  componentDidMount() {
    const { details: { calculatorSetup, matchFactor, mode } } = this.props;
    let { details } = this.props;

    if (Object.keys(calculatorSetup).length > 0) {
      if (details.campaignCurrency) {
        const cu = currencyList.find(e => e.code === details.campaignCurrency);
        this.setState({ currency: cu });
      } else {
        const cu = currencyList.find(e => e.code === details.currency);
        this.setState({ currency: cu });
      }
      if (details.currency && typeof details.currency === 'string') {
        let c = {};
        if (details.currency !== 'usd') {
          c = currencyList.find(e => e.code === details.currency)
        }

        details.currency = [{
          name: 'United States Dollar (USD)',
          code: 'usd',
          sign: '$'
        }, c]
      }
      const values = calculatorSetup.find(calc => calc.mode === mode);
      if (values) {
        this.setState({
          donation_type: mode,
          amount: values.setup,
          temp_duration: calculatorSetup[0].setup[0].noOfMonths,
          matchFactor,
          currencies: details.currency
        });
      }
    }
  }

  handleChange = (e) => {

    const obj = JSON.parse(e.target.value);
    this.setState({
      custom: '',
      monthly: obj,
      donate_amount: obj.amount,
      other: false,
      toggleRadio: false,
      donate_type: this.state.donation_type,
      duration: obj.noOfMonths,
      customDonation: ''
    });
  };

  handleType = (e) => {
    const { temp_duration, donate_type, custom } = this.state;
    if (donate_type === 'monthly' && custom !== 'other') {
      this.setState({ donate_amount: '' });
    }

    if (e.target.value === 'oneTime') {
      this.setState({ monthly: '', custom: e.target.value, other: false, duration: '', donate_type: 'oneTime' })
    } else {
      this.setState({ monthly: '', custom: e.target.value, other: true, duration: temp_duration, donate_type: 'monthly' })
    }
  }

  handleValue = (e) => {
    this.setState({ monthly: '', donate_type: this.state.custom ? this.state.custom : 'oneTime', custom: this.state.custom ? this.state.custom : 'oneTime', donate_amount: e.target.value, customDonation: e.target.value });
  }

  handleFocus = () => {
    const { donate_type, custom } = this.state;
    if (donate_type === 'monthly' && custom !== 'other') {
      this.setState({ donate_amount: '' })
    }
    this.setState({ focus: true, toggleRadio: true, monthly: '', donate_type: custom, custom: this.state.custom ? this.state.custom : 'oneTime' });
  }

  handleBlur = () => {
    this.setState({ focus: false });
  }

  handleDonate = () => {
    let data;
    const { onCampaignActions, history, handleClose } = this.props;
    const { donate_amount, donate_type,custom, duration, matchFactor, currency } = this.state;
    if (donate_type == "oneTime") {
      data = {
        amount: donate_amount,
        donation_type: donate_type,
        duration,
        matchFactor,
        currency
      };
    }
    else if(donate_type == "other") {
      data = {
        amount: donate_amount,
        donation_type: "monthly",
        duration:custom ? 12 : duration,
        matchFactor,
        currency
      };
    }
    else {
      data = {
        amount: donate_amount,
        donation_type: donate_type,
        duration:custom ? 12 : duration,
        matchFactor,
        currency
      };
    }

    onCampaignActions.setDonation(data);
    handleClose();
    history.push('/checkout');
  }

  openCurrency = () => {
    this.setState({ open_currency: true });
  }

  handleSelect = (item) => {
    const { details: { calculatorSetup, mode } } = this.props;
    const values = calculatorSetup.find(calc => calc.mode === mode);
    const setup = values.setup.map(s => {
      return {
        ...s,
        amount: convertCurrency(s.amount, item.code)
      }
    })
    this.setState({ currency: item, open_currency: false, amount: setup });
  }

  closeCurrency = () => {
    this.setState({ open_currency: false });
  }

  handleKeyDown = (e) => {
    if (e.key === 'e' || e.key === '-' || e.key === '+' || e.key === '.')
      e.preventDefault()
  }

  render() {
    const { amount, monthly, custom, donate_amount,total_amount, duration, donate_type, matchFactor, currencies, open_currency, currency, focus, customDonation, toggleRadio, donation_type } = this.state;
    const { handleClose, open, fullScreen, details, classes } = this.props;
    return (
      <Dialog
        classes={{ paper: classes.dialogPaper }}
        maxWidth='xs'
        className='dialog'
        fullScreen={fullScreen}
        open={open || false}
        TransitionComponent={SlideUp}
        autoDetectWindowHeight={true}
      >
        <AppBar position={fullScreen ? 'fixed' : 'static'} className='app-bar'>
          <Toolbar className='toolbar'>
            <IconButton color="inherit" onClick={() => handleClose()} aria-label="Close">
              <CloseIcon />
              <label>Close</label>
            </IconButton>
          </Toolbar>
        </AppBar>
        <div className='content-area' style={{ margin: fullScreen ? '60px 0' : 0 }}>
          <div className='currency-container' onClick={this.openCurrency}>
            <span>Select Currency</span>
            <div className='currency'>
              <label>{currency.code} </label>
              <i className="fa fa-plus-circle" aria-hidden="true"></i>
            </div>
          </div>
          {details.enableCurrency && currencies.length > 1 && <Currency open={open_currency} currencies={currencies} closeCurrency={this.closeCurrency} handleSelect={this.handleSelect} />}
          <Monthly custom={custom} currency={currency} amount={amount} monthly={monthly} handleChange={this.handleChange} />
          {!custom && donate_type === 'monthly' && <MonthlyTotal currency={currency} donate_amount={donate_amount} duration={duration} />}
          {donate_type === 'oneTime' && !custom && <Total currency={currency} donate_amount={donate_amount} />}
          {!custom && <Multiplier donate_type={donate_type} currency={currency} total_amount={total_amount} matchFactor={matchFactor} donate_amount={donate_amount} duration={duration} />}
          <Custom donation_type={donation_type} toggle={toggleRadio} handleKeyDown={this.handleKeyDown} customDonation={customDonation} focus={focus} handleBlur={this.handleBlur} currency={currency} custom={custom} handleType={this.handleType} handleValue={this.handleValue} handleFocus={this.handleFocus} />

          {donate_type === 'oneTime' && custom && <Total currency={currency} donate_amount={donate_amount} />}
          {donate_type === 'monthly' && custom && <MonthlyTotal currency={currency} donate_amount={donate_amount} duration={12} />}
          {custom && <Multiplier donate_type={donate_type} currency={currency} total_amount={total_amount} matchFactor={matchFactor} donate_amount={donate_amount} duration={12} />}
        </div>
        <AppBar position={fullScreen ? 'fixed' : 'static'} className='footer'>
          <Button disabled={donate_amount === ''} onClick={this.handleDonate}>DONATE NOW</Button>
        </AppBar>
      </Dialog>
    );
  }
}

MobileCalculator.propTypes = {
  fullScreen: PropTypes.bool.isRequired,
};

const mapDispatchToProps = dispatch => {
  return {
    onCampaignActions: bindActionCreators(campaignActions, dispatch)
  };
};

export default connect(null, mapDispatchToProps)(withMobileDialog()(withStyles(paperStyle)(MobileCalculator)));