import axios from 'axios';
import sessionStore from "../lib/sessionStorage";

export const getExchangeRate = base => {
  const exchangeRates = sessionStore.get("exchange");
  let symbols = ['USD', 'EUR', 'GBP', 'ILS', 'MXN', 'CAD', 'AUD', 'BRL', 'ZAR', 'RUB'];
  if(base)
  {
    const i = symbols.indexOf(base.toUpperCase());
    symbols.splice(i, 1);
    let checkBase = ''
    if (exchangeRates) {
      checkBase = exchangeRates[base.toUpperCase()];
    }
  
    if (checkBase || !exchangeRates) {
      return axios.get(`https://api.exchangeratesapi.io/latest?base=${base.toUpperCase()}&symbols=${symbols}`)
        .then(res => {
          sessionStore.set("exchange", JSON.stringify(res.data.rates));
        });
    }
  }
}

export function getRates(base) {
  let symbols = ['USD', 'EUR', 'GBP', 'ILS', 'MXN', 'CAD', 'AUD', 'BRL', 'ZAR', 'RUB'];
  if(base)
  {
    return axios.get(`https://api.exchangeratesapi.io/latest?base=${base.toUpperCase()}&symbols=${symbols}`)
    .then(res => {
      console.log('-------------------', res.data.rates);
      return res.data.rates;
      //sessionStore.set("exchange", JSON.stringify(res.data.rates));
    });
  }

}
