var base = '#008eb5',prospectColor='#30748c',scriptColor='#3b7862',scriptBG ='#d3e8e3',prospectBG='#aae7f8';
const styles = {
    container:{
        height:'100vh',background:'white',
      
    },
    theme:{
        color:base
    },
    themeText:{
        color :base, margin:0,fontWeight:'bold'
    },
    root: {
        flexGrow: 1,
      },
      grow: {
        flexGrow: 1,
        margin:'0.5%'
      },
      menuButton: {
        background : 'white'
      },
      appBar:{
        background: base,
        padding:'1%'
      },
      inputStyle:{
          background :'white',
          borderRadius :10
      },
      boldText:{
        fontWeight:'bold'
      },
      rowStyle:{
        display:'flex',flexDirection:'row',justifyContent:'space-between'
      },
      divSections:{
        width:'33%'
      },
      innerDivSections:{
        width:'80%',
        margin:'auto',
        display:'flex',
        flexDirection:'column',
        alignItems:'center',
        marginTop : 10
      },
      radioLables:{
        margin:0,fontWeight:'bold'
      },
      buttonStyle:{
          width:'90%',
          margin:'auto',
          justifyContent:'space-around',
          borderRadius:0,
          padding:'4%',
          margin:'4%'
      },
      prospectTitle:{
        padding: 5,height: 35, display: 'flex', alignItems: 'center', fontSize: 20,
        border : '1px solid'
      },
      prospectIcon:{
          color :prospectColor
      },
      scriptBg:{
        background: scriptBG
      },
      prospectBg:{
        background:prospectBG
      },
      scriptIcon:{
          color: scriptColor
      },
      prospectText:{
        color :prospectColor, margin:0
      },
      scriptText:{
        color :scriptColor, margin:0
      },
      assignProspect:{
        background:scriptBG,
        display:'flex',
        flexDirection:'row',
        borderRadius:5,
        alignItems:'center',
        lineHeight: '20px',
        minHeight: 50,
        padding: '0 20px 0 20px',
        marginTop: 4
      },
      archivedStyle:{
        display:'flex',flexDirection:'row',padding:'1%',margin:'1%',
        background:'#3e3e3e',height:40,alignSelf:'center',alignItems:'center',borderRadius:5
      },
      searchDiv:{
        alignItems:'center',height:'100%',flexWrap:'wrap',
        display:'flex',flexDirection:'row',justifyContent:'space-between'
      },
      archiveDiv:{
        width:'90%',height:'100%',alignItems:'center',margin:'auto',
        display:'flex',flexDirection:'row',justifyContent:'space-between'
      },
      accessProsBtn:{
        background:'#aaabaa',color:'white',borderRadius:0,fontSize:10,whiteSpace:'noWrap'
      },
      tableRoot:{
        width: '100%',
        overflowX: 'auto',
      },
      headerContainer :{ 
        width  :'calc(100% - 30px)' , 
        height : '100%' , 
        display  : 'flex' , 
        alignItems : 'center' , 
        justifyContent : 'space-between'
      }
      ,
      h50 : {
        minHeight: 50,
        padding: '0 10px 0 10px',
        margin : 4
      },
      textBlack:{
        color:'black'
      },
      activeTab:{
        background:'#53c1e8',
        color:'white'
    },
    inactiveTab:{
        background:'#f6f6f6',
        color:'#404040'
    },
    uploadFile: {
      "display": "none"
    },
    uploadFileLabel: {
      "width": "100%"
    },
    uploadFileButtonStyle: {
      "justify-content": "inherit"
    },
    fileIcon: {
      "margin-left": "22px"
    },
    fileText: {
      "margin-left": "33px"
    }
  };
export default styles;