import * as action from './action';
import {BASE_URL} from '../../../apis/backEnd.apis';
import { config } from "../../../config";
import { getAllDonations } from "../../../lib/campaign";

var request = require('request');
const token = config.apiAccessKey;

export const searchDonation = (values) => {return{type:action.SEARCH_DONATION,payload:values}}
export const fetching = () => ({type:action.FETCHING})
export const success = () => ({type:action.SUCCESS})
export const _error = (msg) => ({type:action.ERROR , payload  : msg})

export const fetchDonations = (organizationId, id) => dispatch => {
  dispatch(fetching());
  getAllDonations(organizationId, id, true)
    .then(res => {
      const donorsData = res.data.data.donations;

      dispatch(success());
      return dispatch({
        type : 'DONATIONS_SUCCESS',
        payload: donorsData
      })
  })
}

const getUpdateDisplayStatus = (campaignId, donationId, status, callback) =>{
    
    console.log(BASE_URL+'donors/updateDisplayStatus/'+campaignId)
    request({
        method : 'GET',
        url: BASE_URL+'donors/updateDisplayStatus/'+campaignId+"/"+donationId+"/"+status,
        headers: { "access-token": `${token}` }
    },
    callback)

}

export const updateDisplayStatus = (organizationId, campaignId, donationId, status) => dispatch => {
  if(campaignId === 'none') {
    return dispatch(_error()); 
  }
  dispatch(fetching());

  getUpdateDisplayStatus(campaignId, donationId, status, (error, response) => {
    if(!error){
        let res = JSON.parse(response.body)
        if(res.success){
          dispatch(fetchDonations(organizationId, campaignId));
        }
    }
    else{
        dispatch(_error());
    }
  })
}