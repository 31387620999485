import React, { Component } from "react";
import "./addTeam.scss";
import TeamForm from "./TeamForm";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { getCampaignById } from "../../redux/modules/campaign";
import { addTeam, saveEditedTeam } from "../../redux/modules/TeamManagement/action-creator";
import Snackbar from "../Snackbar/Snackbar";
import ErrorIcon from "@material-ui/icons/Error";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import firebase from "firebase";

class AddTeam extends Component {
  state = {
    isUploading: false,
    progress: 0,
    campaignPicture: "",
    showMessage: false,
    message: "",
    error: false,
    pageName: ""
  };

  componentDidMount() {
    window.scrollTo(0, 0);
    const { dialog, history, getCampaignById } = this.props;
    let campaign;
    let organization;
    if (!dialog) {
      campaign = this.props.match.params.campaign;
      organization = this.props.match.params.organization;
    } else {
      campaign = this.props.campaignId;
      organization = this.props.organizationId;
    }

    if (!history.location.state) {
      getCampaignById(organization, campaign);
    } else {
      this.setState({ pageName: history.location.state.name })
    }
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.isFetching !== this.props.isFetching &&
      this.props.isFetching === false &&
      this.props.isError === false
    ) {
      this.handleBack();
    } else if (this.props.isError === true) {
    }

    const { campaign } = this.props;

    if (prevProps.campaign !== campaign) {
      this.setState({ pageName: `/${campaign.data.campaignPage}` });
    }
  }

  showSnackBar = (message, isError) => {
    this.setState({ showMessage: true, message: message, error: isError });
    setTimeout(() => {
      this.setState({ showMessage: false, message: "", error: false });
    }, 3000);
  };

  addTeam = values => {
    let campaignId = "";
    let organizationId = "";
    if (this.props.dialog) {
      campaignId = this.props.campaignId;
      organizationId = this.props.organizationId;
    } else {
      campaignId = this.props.match.params.campaign;
      organizationId = this.props.match.params.organization;
    }

    const {
      phone,
      title,
      url,
      goal,
      textarea,
      imagePreviewUrl,
      email,
      firstName,
      lastName,
      teamPageURL
    } = values;

    if (!teamPageURL) {
      return this.showSnackBar("Please enter Team Page Path", true);
    }

    let team = {
      organizationId,
      campaignId,
      email,
      firstName,
      lastName,
      teamName: title,
      pageName: teamPageURL.toLowerCase(),
      teamCampaign: campaignId,
      imageURL1: "",
      videoURL1: url,
      avatar: imagePreviewUrl,
      goalAmount: goal,
      text1: textarea,
      phone,
      isActive: false
    };
    this.props.addTeam(team);
  };

  editTeam = values => {
    let campaignId = "";
    let organizationId = "";
    if (this.props.dialog) {
      campaignId = this.props.campaignId;
      organizationId = this.props.organizationId;
    } else {
      campaignId = this.props.match.params.campaign;
      organizationId = this.props.match.params.organization;
    }

    const {
      phone,
      title,
      url,
      goal,
      textarea,
      imagePreviewUrl,
      email,
      firstName,
      lastName,
      teamPageURL,
      id,
      isActive
    } = values;

    if (!teamPageURL) {
      return this.showSnackBar("Please enter Team Page Path", true);
    }

    let data = {
      organizationId,
      campaignId,
      team: {
        id,
        email,
        firstName,
        lastName,
        teamName: title,
        pageName: teamPageURL,
        teamCampaign: campaignId,
        imageURL1: "",
        videoURL1: url,
        avatar: imagePreviewUrl,
        goalAmount: goal,
        text1: textarea,
        phone,
        isActive
      }
    };
    this.props.updateTeam(data);
  }

  handleBack = () => {
    const { pageName } = this.state;

    if (this.props.dialog) {
      this.props.onCancel();
    } else {
      const { history } = this.props;
      let campaignName = this.props.history.location.state
        ? this.props.history.location.state.name
        : pageName;
      history.push(`${campaignName}`);
    }
  };

  handleUploadStart = () => {
    this.setState({ isUploading: true, progress: 0 });
  };

  handleProgress = progress => this.setState({ progress });

  handleUploadError = error => {
    this.setState({ isUploading: false });
    alert("error uploading file");
  };

  handleUploadSuccess = (source, filename, keyName) => {
    this.setState({ progress: 100, isUploading: false });
    firebase
      .storage()
      .ref(source)
      .child(filename)
      .getDownloadURL()
      .then(url =>
        this.setState({ [keyName]: url }, () => alert("file uploaded"))
      );
  };

  render() {
    const { pageName } = this.state;
    const { team } = this.props;

    return (
      <div className="docker">
        <Snackbar
          message={this.props.message || this.state.message}
          color={this.props.isError || this.state.error ? "danger" : "success"}
          icon={
            this.props.error || this.state.error ? ErrorIcon : CheckCircleIcon
          }
          place="tr"
          open={this.props.showMessage || this.state.showMessage}
        />
        <div className={this.props.dialog ? "redhead-dialog" : "redhead"}>
          <img src="/images/addteam/addteam.png" alt="team-icon" />
          <h2>{team && team.id ? 'UPDATE' : 'CREATE'} TEAM PAGE</h2>
        </div>
        <div className="team-form">
          <TeamForm
            dialog={this.props.dialog || false}
            addTeam={this.addTeam}
            isFetching={this.props.isFetching}
            isUploading={this.state.isUploading}
            progress={this.state.progress}
            handleUploadStart={this.handleUploadStart}
            handleProgress={this.handleProgress}
            handleUploadError={this.handleUploadError}
            handleUploadSuccess={this.handleUploadSuccess}
            handleBack={this.handleBack}
            campaignName={
              this.props.history.location.state
                ? this.props.history.location.state.name
                : pageName
            }
            team={team}
            editTeam={this.editTeam}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    isFetching: state.teamManagementReducer.isFetching,
    isError: state.teamManagementReducer.isError,
    errors: state.teamManagementReducer.errors,
    showMessage: state.teamManagementReducer.showMessage,
    message: state.teamManagementReducer.message,
    campaign: state.campaign.get("campaignData")
  };
};

const mapDispatchToProps = dispatch => {
  return {
    addTeam: bindActionCreators(addTeam, dispatch),
    updateTeam: bindActionCreators(saveEditedTeam, dispatch),
    getCampaignById: bindActionCreators(getCampaignById, dispatch)
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AddTeam);
