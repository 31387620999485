import React from "react";
import InputBase from "@material-ui/core/InputBase";
import Button from "@material-ui/core/Button";
import "./Calculate.scss";

export const Monthly = ({
  donation,
  donation: { currency },
  name,
  increment,
  decrement,
  handleAmount,
  handleMonths
}) => {
  return (
    <div className="calc_donation">
      <div className="donation_values">
        <div className="number_month">
          <p>Number of Months</p>
          <div className="calc_wrapper">
            <Button
              disabled={donation.duration === 1}
              className="minus left"
              onClick={decrement}
            >
              -
            </Button>
            <InputBase
              onChange={handleMonths}
              value={donation.duration}
              className="left"
            />
            <Button
              disabled={donation.duration === 12}
              className="plus left"
              onClick={increment}
            >
              +
            </Button>
          </div>
        </div>
        <div className="symbol cross">x</div>
        <div className="month_per_donation">
          <p>Monthly Donation</p>
          <div className="monthly-donation">
            <span>{currency && currency.sign}</span>
            <InputBase
              type="number"
              value={donation.amount}
              onChange={handleAmount}
            />
          </div>
        </div>
        <div className="total_amount">
          <p>Total Amount</p>
          <InputBase
            readOnly
            value={`${currency && currency.sign} ${donation.amount * donation.duration}`}
          />
        </div>
        <div className="doubling">
          <span className="cr">x</span>
          {donation.matchFactor}
          <span className="eq">=</span>
        </div>
        <div className="total_receive">
          <label>{name} Receives</label>
          <div className="calculate">
            <h1>{`${currency && currency.sign}${donation.duration *
              donation.amount *
              donation.matchFactor}`}</h1>
          </div>
        </div>
      </div>
    </div>
  );
};
