import React from 'react';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';

const RadioLabel = ({ e, currency }) => {
  return (
    <div>
      <label>{currency.sign}{e.amount}</label>
      {e.noOfMonths && <p>X {e.noOfMonths} Months</p>}
    </div>
  )
}

export const Monthly = ({ amount, monthly, handleChange, custom, currency }) => {
  return (
    <FormControl className='monthly-donation' component="fieldset">
      <RadioGroup className='donation-values' name="donation" value={monthly} onChange={handleChange}>
        {amount.map(e => {
          return (
            <div key={e.amount} className={custom === 'oneTime' || custom === 'other' ? 'item i-normal' : 'item i-normal'} style={{ background: parseInt(e.amount) === parseInt(monthly.amount) && '#3C849F' }}>
              <FormControlLabel checked={parseInt(e.amount) === parseInt(monthly.amount)} value={JSON.stringify(e)} control={
                <Radio />
              } label={<RadioLabel currency={currency} e={e} />} />
            </div>
          )
        })}
      </RadioGroup>
    </FormControl>
  );
}