import React, { Component } from "react";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import { TextValidator } from "react-material-ui-form-validator";

class CardInfo extends Component {
  handleBlur = e => {
    this.refs[e.target.name].validate(e.target.value, true);
  };

  acceptedCreditCards = {
    visa: /^4[0-9]{12}(?:[0-9]{3})?$/,
    mastercard: /^5[1-5][0-9]{14}$|^2(?:2(?:2[1-9]|[3-9][0-9])|[3-6][0-9][0-9]|7(?:[01][0-9]|20))[0-9]{12}$/,
    amex: /^3[47][0-9]{13}$/,
    discover: /^65[4-9][0-9]{13}|64[4-9][0-9]{13}|6011[0-9]{12}|(622(?:12[6-9]|1[3-9][0-9]|[2-8][0-9][0-9]|9[01][0-9]|92[0-5])[0-9]{10})$/,
    diners_club: /^3(?:0[0-5]|[68][0-9])[0-9]{11}$/,
    jcb: /^(?:2131|1800|35[0-9]{3})[0-9]{11}$/
  };

  callTwo = (e) => {
    if ((this.acceptedCreditCards.amex).test(this.refs.creditCardNumber.state.value)) {
      if (e.target.value.length > 4) {
        return;
      }
      else {
        this.props.cvvChange('cvc', e.target.value);
      }
    }
    else {
      this.props.inputChange(e);
    }
  }

  render() {
    console.log(this.props);
    const { inputChange, inputs } = this.props;
    const {
      creditCardNumber,
      cvc,
      expirationMonth,
      expirationYear
    } = inputs.toJS();
    let cards = ['American Express', "Others"];
    return (
      <Paper className="checkout card-info">
        <Grid container spacing={8}>
          <Grid item xs={12}>
            <label className="form-name">
              Enter Your Credit Card Number <span>*</span>
            </label>
            <TextValidator
              autoComplete="nope"
              type="number"
              validators={["required", "minNumber: 1"]}
              errorMessages={["This field is required", "Invalid Value"]}
              ref="creditCardNumber"
              onBlur={this.handleBlur}
              placeholder="Enter Your Credit Card Number"
              className="checkout_input"
              value={creditCardNumber}
              name="creditCardNumber"
              onChange={inputChange}
            />
          </Grid>
          {/* <Grid item xs={12} sm={6}>
            <label className="form-name">
              Card Type <span>*</span>
            </label>
            <NativeSelect
              disableUnderline={true}
              className="select"
              name="team"
              onChange={handleTeam}
              value={team.id}
            >
              <option value="">-- --</option>
              {cards.map(e =>
                <option value={e.id} key={e.id}>{e.teamName}</option>
              )}
            </NativeSelect>
          </Grid> */}
          <Grid item xs={12} sm={6}>
            <label className="form-name">
              CVC <span>*</span>
            </label>
            <TextValidator
              inputProps={{
                autocomplete: "new-password",
                form: {
                  autocomplete: "off"
                }
              }}
              // autoComplete="nope"
              type="number"
              validators={["required", "minNumber: 1"]}
              errorMessages={["This field is required", "Invalid Value"]}
              ref="cvc"
              onBlur={this.handleBlur}
              placeholder="CVC"
              className="checkout_input"
              value={cvc}
              name="cvc"
              onChange={this.callTwo}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <label className="form-name">
              Expiration (MM/YY) <span>*</span>
            </label>
            <Grid container spacing={8}>
              <Grid item xs={6}>
                <TextValidator
                  autoComplete="nope"
                  type="number"
                  validators={["required", "minNumber: 1", "maxNumber: 12"]}
                  errorMessages={[
                    "This field is required",
                    "Invalid Year",
                    "Invalid Year"
                  ]}
                  ref="expirationMonth"
                  onBlur={this.handleBlur}
                  placeholder="MM"
                  className="checkout_input"
                  value={expirationMonth}
                  name="expirationMonth"
                  onChange={inputChange}
                />
              </Grid>
              <Grid item xs={6}>
                <TextValidator
                  type="number"
                  validators={["required", "minNumber: 19", "maxNumber: 99"]}
                  errorMessages={[
                    "This field is required",
                    "Invalid Year",
                    "Invalid Year"
                  ]}
                  ref="expirationYear"
                  onBlur={this.handleBlur}
                  placeholder="YY"
                  className="checkout_input"
                  value={expirationYear}
                  name="expirationYear"
                  onChange={inputChange}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    );
  }
}

export { CardInfo };
