import React, { Component } from "react";
import { Dialog, DialogTitle, DialogActions, Grid, withMobileDialog } from "@material-ui/core";
import FileUploader from "react-firebase-file-uploader";
import firebase from "../../apis/firebase.apis";
import { sortable } from 'react-sortable';

class Item extends React.Component {
  render() {
    return (
      <li {...this.props}>
        {this.props.children}
      </li>
    )
  }
}


var SortableItem = sortable(Item);

class SortableList extends React.Component {

  state = {
    items: this.props.items
  };

  onSortItems = (items) => {
    this.setState({
      items: items
    });
    this.props.sortedItems(items);
  }

  render() {
    const { items } = this.state;
    var listItems = items.map((item, i) => {
      return (
        <SortableItem
          key={i}
          onSortItems={this.onSortItems}
          items={items}
          sortId={i}>              <div className="item-flex">
            <div classNam="item-con">
              {item.title}
            </div>
            <div className="item-con">
              {item.price}
            </div>
          </div></SortableItem>
      );
    });
    return (
      <ul className='sortable-list'>
        {listItems}
      </ul>
    )
  }
};


class CampaignItems extends Component {
  constructor() {
    super();

    this.state = {
      items: [],
      openSortDialog: false
    }
  }

  componentDidMount() {
    const { items } = this.props;

    this.setState({
      items: items || []
    })
  }

  onUploadError = error => {
    const { showSnackBar } = this.props;
    showSnackBar("Error uploading file", true);
  };

  onUploadSuccess = (source, filename, i) => {
    let itemsArr = this.state.items;
    const { showSnackBar } = this.props;
    firebase
      .storage()
      .ref(source)
      .child(filename)
      .getDownloadURL()
      .then(url => {
        itemsArr[i].image = url;
        this.setState({ items: itemsArr }, () =>
          showSnackBar("File uploaded", false)
        )
      })
  };

  handleChange = (e, i) => {
    const { value, name } = e.target;

    let items = [...this.state.items];
    items[i][name] = value;

    this.setState({ items });
  }

  handleSave = () => {
    const { items } = this.state;
    const { saveItems } = this.props;

    saveItems(items);
  }

  handleAdd = () => {
    const items = [...this.state.items];
    const info = {
      desc: '',
      donated: 0,
      id: items.length,
      image: '',
      price: 0,
      title: '',
      total: 0
    }

    items.push(info);
    this.setState({ items });
  }

  openSortDialog = () => {
    this.setState({
      openSortDialog: true
    })
  }

  closeSortDialog = () => {
    this.setState({
      openSortDialog: false
    })
  }

  handleRemove = (e, i) => {
    const items = [...this.state.items];
    items.splice(i, 1);
    this.setState({ items });
  }

  updateItems = (items) => {
    this.setState({
      items
    })
  }


  render() {
    const { fullScreen, handleItems } = this.props;
    const { items } = this.state;
    // let sortableItems = items.map(val =>
    //   val.title
    // )
    return (
      <>
        <Dialog
          id='additional-info-dialog'
          fullScreen={fullScreen}
          maxWidth={'md'}
          open={true}
          className="additional-info-dialog-position"
        >
          <DialogTitle className="title">
            Items
        </DialogTitle>
          <Grid className="container" container spacing={0}>
            <Grid xs={12} sm={6}>
              <button onClick={this.openSortDialog} className="add-btn">Change Order</button>
            </Grid>
            {items.map((e, i) => {
              return (
                <Grid className="items closable" container key={i}>
                  <div onClick={e => this.handleRemove(e, i)} className="close">x</div>
                  <Grid className="item" item xs={12}>
                    <h4>Item {i + 1}</h4>
                  </Grid>
                  <Grid className="item" item xs={12} sm={6}>
                    <label>Item Title</label>
                    <input name="title" className="input" onChange={(e) => this.handleChange(e, i)} value={e.title} />
                  </Grid>
                  <Grid className="item" item xs={12} sm={6}>
                    <label>Item Price</label>
                    <input type="tel" name="price" className="input" onChange={(e) => this.handleChange(e, i)} value={e.price} />
                  </Grid>
                  <Grid className="item" item xs={12} sm={6}>
                    <label>Total Available</label>
                    <input type="tel" name="total" className="input" onChange={(e) => this.handleChange(e, i)} value={e.total} />
                  </Grid>
                  <Grid className="item" item xs={12} sm={6}>
                    <label>Total Donated</label>
                    <input type="tel" name="donated" className="input" onChange={(e) => this.handleChange(e, i)} value={e.donated} />
                  </Grid>
                  {!e.image && <Grid className="item" item xs={12} sm={4}>
                    <label>Item Icon</label>
                    <FileUploader
                      multiple={false}
                      accept="image/*"
                      name="image"
                      randomizeFilename
                      storageRef={firebase.storage().ref("Items")}
                      onUploadError={this.onUploadError}
                      onUploadSuccess={filename => this.onUploadSuccess("Items", filename, i)}
                    />
                  </Grid>}
                  {e.image &&
                    <Grid className="item icon" item xs={12} sm={4}>
                      <label>Item Icon</label>
                      <div className="img-container">
                        <img src={e.image} alt="Item" />
                      </div>
                      <button onClick={e => this.removeIcon(e, i)}>Remove</button>
                    </Grid>
                  }
                  <Grid className="item" item xs={12}>
                    <label>Item Description</label>
                    <input name="desc" className="input" onChange={(e) => this.handleChange(e, i)} value={e.desc} />
                  </Grid>
                </Grid>
              )
            })}
            <Grid xs={12} sm={6}>
              <button onClick={this.handleAdd} className="add-btn">Add Item</button>
            </Grid>
          </Grid>
          <DialogActions className="actions action-fixed">
            <button onClick={handleItems}>Cancel</button>
            <button onClick={this.handleSave}>Save</button>
          </DialogActions>
        </Dialog>
        <Dialog
          id='additional-info-dialog'
          fullScreen={fullScreen}
          maxWidth={'md'}
          open={this.state.openSortDialog}
        >
          <DialogTitle className="title">
            Order Items By Drag And Drop
            </DialogTitle>
          <Grid className="p-10">
            {/* {items.map(key =>
              <div className="item-flex">
                <div classNam="item-con">
                  {key.title}
                </div>
                <div className="item-con">
                  {key.price}
                </div>
              </div>
            )} */}
            <SortableList sortedItems={this.updateItems} items={items} />
          </Grid>
          <DialogActions className="actions">
            <button onClick={this.closeSortDialog}>Close</button>
          </DialogActions>
        </Dialog>
      </>
    );
  }
}

export default withMobileDialog()(CampaignItems);