import React from "react";
import classNames from "classnames";
import Button from "@material-ui/core/Button";
import ErrorIcon from "@material-ui/icons/Error";
import CloseIcon from "@material-ui/icons/Close";
import IconButton from "@material-ui/core/IconButton";
import Snackbar from "@material-ui/core/Snackbar";
import SnackbarContent from "@material-ui/core/SnackbarContent";
import { withStyles } from "@material-ui/core/styles";

import "./Error.scss";

const variantIcon = {
  error: ErrorIcon
};

const styles1 = theme => ({
  error: {
    backgroundColor: theme.palette.error.dark
  },
  icon: {
    fontSize: 20
  },
  iconVariant: {
    opacity: 0.9,
    marginRight: theme.spacing.unit
  },
  message: {
    display: "flex",
    alignItems: "center",
    fontSize: 16
  }
});

const SnackbarContentArea = props => {
  const { classes, className, message, onClose, variant, ...other } = props;
  const Icon = variantIcon[variant];

  return (
    <SnackbarContent
      className={classNames(classes[variant], className)}
      aria-describedby="client-snackbar"
      message={
        <span id="client-snackbar" className={classes.message}>
          <Icon className={classNames(classes.icon, classes.iconVariant)} />
          {message}
        </span>
      }
      action={[
        <IconButton
          key="close"
          aria-label="Close"
          color="inherit"
          className={classes.close}
          onClick={onClose}
        >
          <CloseIcon className={classes.icon} />
        </IconButton>
      ]}
      {...other}
    />
  );
};

const SnackbarContentWrapper = withStyles(styles1)(SnackbarContentArea);

class Error extends React.Component {
  render() {
    const { open, message, handleClose } = this.props;
    return (
      <Snackbar
        className="snack-wrapper snack-wrapper--custom"
        anchorOrigin={{
          vertical: "top",
          horizontal: "center"
        }}
        open={open}
        autoHideDuration={8000}
        onClose={handleClose}
      >
        <SnackbarContentWrapper
          className="snack"
          variant="error"
          onClose={handleClose}
          message={message}
        />
      </Snackbar>
    );
  }
}

export default Error;
