import { handleActions } from "redux-actions";
import * as action from "./action";
import _ from "lodash";

const initialState = {
  teams: [],
  filteredTeams: "",
  searchItem: "",
  editTeamName: "",
  editTeamPageName: "",
  editCampaignGoal: "",
  editText1: "",
  editText2: "",
  editText3: "",
  isFetching: false,
  selectedToEdit: "",
  verifyPageErr: false,
  errors: "",
  isError: false,
  message: "",
  showMessage: false
};

const enableDisableTeam = (state, action) => {
  let filteredTeams = Object.assign({}, state.teams);
  let index = action.payload;
  filteredTeams[index]["isActive"] = !filteredTeams[index]["isActive"];
  const fTeam = Object.keys(filteredTeams).map(i => filteredTeams[i]);
  return fTeam;
};

const searchTeam = (state, action) => {
  console.log(state, action);
  let event = action.payload.e;
  let searchItem = event.target.value;
  state.searchItem = event.target.value;
  let teams = state.teams;
  let filteredTeams;

  filteredTeams = teams.filter(
    team =>
      team.teamName.toUpperCase().indexOf(searchItem.toUpperCase()) > -1 ||
      team.pageName.toUpperCase().indexOf(searchItem.toUpperCase()) > -1 ||
      team.text1.toUpperCase().indexOf(searchItem.toUpperCase()) > -1
  );
  return filteredTeams;
};

const onEditIconClick = (state, action) => {
  let teams = state.teams;
  let t = teams.filter(team => team.edit);
  if (t.length) return teams;

  let filteredTeams = Object.assign({}, state.teams);
  var { index, team } = action.payload;
  state.editTeamName = team.teamName;
  state.editCampaignGoal = team.shareGoal;
  state.editText1 = team.text1;
  state.editText2 = team.text2;
  state.editText3 = team.text3;
  filteredTeams[index]["edit"] = !filteredTeams[index]["edit"];
  const fTeam = Object.keys(filteredTeams).map(i => filteredTeams[i]);
  return fTeam;
};

const sortTeams = payload => {
  return _.orderBy(payload, ["teamName"], ["asc"]);
};

const handlers = {
  [action.FETCHING]: (state, action) => ({
    ...state,
    isFetching: true,
    selectedToEdit: ""
  }),
  [action.SUCCESS]: state => ({
    ...state,
    isFetching: false,
    isError: false,
    message: "",
    showMessage: false
  }),
  [action.ERROR]: state => ({ ...state, isFetching: false }),
  [action.ENABLE_DISABLE_TEAM]: (state, action) => ({
    ...state,
    filteredTeams: enableDisableTeam(state, action)
  }),
  [action.SEARCH_TEAM]: (state, action) => ({
    ...state,
    filteredTeams: searchTeam(state, action)
  }),
  [action.ON_EDIT_TEAM]: (state, action) => ({
    ...state,
    filteredTeams: onEditIconClick(state, action),
    selectedToEdit: action.payload.team.id
  }),
  [action.HANDLE_EDIT_INPUT]: (state, action) => ({
    ...state,
    [action.payload.target.name]: action.payload.target.value
  }),
  TEAM_SUCCESS: (state, action) => ({
    ...state,
    isError: false,
    showMessage: true,
    message: "Successfully Created Team Page",
    teams: sortTeams(action.payload)
  }),
  INVALID_PAGE_ERROR: state => ({ ...state, verifyPageErr: true }),
  VALID_PAGE: state => ({ ...state, verifyPageErr: false }),
  TEAM_FAILED: (state, action) => ({
    ...state,
    isError: true,
    isFetching: false,
    message: action.payload,
    showMessage: true
  })
};

export default handleActions(handlers, initialState);
