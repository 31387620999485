import React, { Component } from 'react';
import { connect } from 'react-redux';
import './Contactus.scss';
import Snackbar from '../../components/Snackbar/Snackbar';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';
import {addContact} from './../../redux/modules/CampaignReports/action-creator';

class Contactus extends Component {
    constructor(props) {
        super(props);
        this.state = {
            openMenu: false,
            contact_name: '',
            contact_email: "",
            contact_number: '',
            contact_message: "",
            showMessage:false,
            error:false,
            message:''
        }
    }

    openNavMenu = () => {
        this.setState({
            openMenu: !this.state.openMenu
        })
    }

    handleChange = (e) => {
        e.preventDefault();
        const { name, value } = e.target;
        this.setState({
            [name]: value
        })
    }

    handleSubmit = (e) => {
        e.preventDefault();
        let contacts={
            contact_name:this.state.contact_name,
            contact_email:this.state.contact_email,
            contact_number:this.state.contact_number,
            contact_message:this.state.contact_message
        };
        addContact(contacts).then(()=>
    {
        this.setState({
            message: "Your contact form has been submitted successfully",
            error:false,
            showMessage:true
        })
        setTimeout(()=>
        this.setState({
            error:false,
            showMessage:false,
            message:''
        }),5000
        );
    })
    .catch(err=>
    {
        
    })

    }

    render() {
        const{
            contact_name,
            contact_email,
            contact_number,
            contact_message
        } = this.state;
        return (
            <section id="contact-wrapper">
                      <Snackbar
            message={this.state.message}
            color={this.state.error ? 'danger' : 'success'}
            icon={this.state.error ? ErrorIcon : CheckCircleIcon}
            place='tc'
            open={this.state.showMessage}
          />
                <div className="menu-opener">
                    <div className={this.state.openMenu ? "menu-opener-inner open_menu" : "menu-opener-inner"} onClick={this.openNavMenu}></div>
                </div>
                <div className={this.state.openMenu ? "collapse navbar-collapse open_menu" : "collapse navbar-collapse mBottom"} id="myNavbar">
                    <a href="#" className="btn btn-success btn-round">Schedule A FREE Meeting  <img src="/images/arrow09.png" /></a>
                </div>
                <div className="container">
                    <div className="row">
                        <div className="col-md-4 col-sm-12">
                            <h3 className="sub-header">CONTACT DETAILS</h3>
                            <p>(310)220-0202</p>
                            <p>5455 Wilshire Blvd  | Los Angeles</p>
                            <p>_____________</p>
                            <p>For support inquiries, please send an email to <a href="mailto:support@Pledje.com" target="_top">Support@Pledje.com</a></p>
                        </div>
                        <div className="col-md-8 col-sm-12">
                        <form onSubmit={this.handleSubmit}>
                            <h3 className="sub-header">CONTACT US</h3>
                            <label>Name *</label>
                            <input type="text" onChange={this.handleChange} name="contact_name" value={contact_name} className="input_box form-control" required />
                            <label>Email *</label>
                            <input type="email" onChange={this.handleChange} name="contact_email" value={contact_email} className="input_box form-control" required />
                            <label>Phone</label>
                            <input type="number" onChange={this.handleChange} name="contact_number" value={contact_number} className="input_box form-control" />
                            <label>Message</label>
                            <textarea className="input_box form-control" onChange={this.handleChange} name="contact_message" value={contact_message} rows="10" cols="40"></textarea>
                            <div className="text-center">
                                <button type="submit" className="btn btn-send px-4">Send</button>
                            </div>
                            </form>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}


export default connect(
    (state) => ({
    }),
    (dispatch) => ({
    })
)(Contactus);