import firebase from "../apis/firebase.apis";
import { getAllDonations } from "./campaign";
import { convertCurrency } from "../Common/CurrencyCalculator";

const FIRESTORE = firebase.firestore();

const getTeamFromFirebase = (oId, cId, tId) => {
  return FIRESTORE.collection("organizations")
    .doc(oId)
    .get()
    .then(org => {
      let camp = org.data().campaigns[cId];
      camp.socialShareAmount = camp.shareAmount;
      camp.teamInfo = camp.Teams[tId];
      camp.bonusGoalEndDate = new Date(camp.bonusGoalEndDate)
      camp.campaignEndTime = new Date(camp.campaignEndTime)
      camp.campaignStartTime = new Date(camp.campaignStartTime)
      camp.preCampaignEndDate = new Date(camp.preCampaignEndDate)
      camp.preCampaignStartDate = new Date(camp.preCampaignStartDate)
      camp.enableTransactionCost = camp.enableTransactionCost === false || camp.enableTransactionCost === true ? camp.enableTransactionCost : true;
      camp.teamInfo.id = tId;
      return {
        data: {
          data: camp,
          success: true
        }
      };
    });
}

export const getTeam = (campaign, team) => {
  return FIRESTORE.collection("routes")
    .where("route", "==", `/${campaign.toLowerCase()}/${team.toLowerCase()}/`)
    .limit(1)
    .get()
    .then(res => {
      let route = {};
      if (res.size) {
        res.forEach(doc => {
          route = doc.data();
        });
      }
      return getTeamFromFirebase(
        route.organizationId,
        route.campaignId,
        route.teamId
      );
    })
};

export const teamDonation = (organizationId, id, team) => {
  return getAllDonations(organizationId, id)
    .then(res => {
      const donorsData = res.data.data.donations;
      let total = 0;
      donorsData.forEach(donorData => {
        if(donorData.teamId === team) {
          total += convertCurrency(donorData.amount, donorData.currency || 'usd');
        }
      });

      return { 
        data: {
          total: total 
        }
      };
    });
}
