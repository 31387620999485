import React, { Component } from "react";
import { Grid, Button, Hidden } from "@material-ui/core";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import ArrowForwardIos from "@material-ui/icons/ArrowForwardIos";
import { DonorInfo } from "./ProspectForm/DonorInfo";
import { ManualDonation } from "./ProspectForm/ManualDonation";
import ConfirmationDialog from "../../components/Dialog/Confirmation";
import { ValidatorForm } from "react-material-ui-form-validator";
import { convertCurrency } from "../../Common/CurrencyCalculator";
import { currencies } from "../../Common/currencies";
import _ from "lodash";
import "./ProspectTabContent.scss";

class ProspectTabContent extends Component {
  constructor() {
    super();

    this.state = {
      details: {
        suggestedDonation: "",
        status: "Clear",
        firstName: "",
        lastName: "",
        mobilePhone: "",
        workPhone: "",
        homePhone: "",
        email: "",
        billingAddress: "",
        billingCity: "",
        billingState: "",
        billingZip: "",
        billingCountry: "",
        donationAmount: 0,
        paymentType: "cash",
        routing_number: "",
        account_number: "",
        teamId: "",
        displayName: false,
        alias: "",
        dedicationMessage: "",
        specialNotes: ""
      },
      viewMore: false,
      list: [],
      valueChanged: false,
      openConfirmation: false,
      dialogContent: "",
      currency: {},
      items: [],
      donatedItems: []
    };
  }

  componentDidMount() {
    const { currentProspect, prospectsList, campaignReports, campaignData } = this.props;
    this.setCurrency(campaignData.campaignCurrency);
    this.setState({ items: campaignData.items });
    if (currentProspect) {
      let donation =
        campaignReports &&
        (campaignReports.find(rep => rep.prospectId === currentProspect.id) ||
          {});
      if (donation && donation.id) {
        donation.donationId = donation.id;
      }
      this.setState(
        {
          details: { ...this.state.details, ...donation, ...currentProspect },
          list: prospectsList
        },
        () => {
          this.setState(prevState => ({
            details: {
              ...prevState.details,
              displayName:
                prevState.details.alias === "Anonymous"
                  ? true
                  : false,
              alias: prevState.details.alias === "Anonymous" ? "" : prevState.details.alias
            }
          }));
        }
      );
    }
  }

  onChange = e => {
    e.persist();
    this.setState(prevState => ({
      details: {
        ...prevState.details,
        [e.target.name]: e.target.value
      },
      valueChanged: true,
    }));
  };

  isNumber = (e) => {
    // eslint-disable-next-line no-control-regex
    const regex = new RegExp("^[0-9\b]+$");
    const key = String.fromCharCode(!e.charCode ? e.which : e.charCode);
    if (!regex.test(key)) {
      e.preventDefault();
      return;
    }
  }

  displayName = e => {
    e.persist();
    this.setState(prevState => ({
      details: {
        ...prevState.details,
        [e.target.name]: e.target.checked
      },
      valueChanged: true
    }));
  };

  viewMore = () => {
    this.setState({ viewMore: !this.state.viewMore });
  };

  next = () => {
    const { details, list } = this.state;
    for (const i in list) {
      if (list[i].id === details.id && i < list.length - 1) {
        this.setState({ details: list[parseInt(i) + 1] });
        break;
      }
    }
  };

  previous = () => {
    const { details, list } = this.state;
    for (const i in list) {
      if (list[i].id === details.id && i > 0) {
        this.setState({ details: list[parseInt(i) - 1] });
        break;
      }
    }
  };

  setProspect = () => {
    const {
      details: {
        suggestedDonation,
        status,
        firstName,
        lastName,
        mobilePhone,
        workPhone,
        homePhone,
        email,
        billingAddress,
        billingCity,
        billingCountry,
        id,
        campaignId,
        organizationId,
        paymentType,
        routing_number,
        account_number
      }
    } = this.state;
    const payload = {
      suggestedDonation,
      status,
      firstName,
      lastName,
      mobilePhone,
      workPhone,
      homePhone,
      email,
      billingAddress,
      billingCity,
      billingCountry,
      paymentType,
      routing_number,
      account_number
    };
    if (id) {
      payload.id = id;
      payload.campaignId = campaignId;
      payload.organizationId = organizationId;
    }
    return payload;
  };

  submit = creditCard => {
    const { onSubmitAndClose } = this.props;
    const payload = this.setProspect();
    if (creditCard) {
      onSubmitAndClose(payload, "creditcard");
    } else {
      this.manualPayment();
    }
  };

  manualPayment = () => {
    const { onManualPaymentClick } = this.props;
    const { details, currency, donatedItems } = this.state;
    const prospect = this.setProspect();
    let total = 0;
    donatedItems.forEach(e => {
      total = total + (parseInt(e.count) * parseInt(e.price))
    });
    total = total + parseInt(details.donationAmount)
    
    this.setState(
      prevState => ({
        details: {
          ...prevState.details,
          displayName: !prevState.details.displayName,
          currency: currency.code,
          items: donatedItems,
          donationAmount: total
        }
      }),
      () => {
        onManualPaymentClick(prospect, this.state.details);
      }
    );
  };

  editDonation = () => {
    const { onSubmitAndClose } = this.props;
    const { details } = this.state;
    onSubmitAndClose(details);
  };

  closeModal = () => {
    const { valueChanged } = this.state;

    this.setState({
      dialogContent: valueChanged ? "Would you like to save your changes?" : "Are you sure you want to close?",
      openConfirmation: true
    });
  };

  handleConfirmation = close => {
    const { valueChanged } = this.state;
    this.setState({ openConfirmation: false });
    if (close && valueChanged) {
      this.submit(false);
    } else if ((close && !valueChanged) || (!close && valueChanged)) {
      const { closeProspectModal } = this.props;
      closeProspectModal();
    }
  };

  setCurrency = (e) => {
    const { campaignData } = this.props;

    if(e) {
      this.setState({
        currency: campaignData.currency.find(c => c.code === e)
      })
    } else {
      this.setState({
        currency: currencies.find(c => c.code === campaignData.currency)
      })
    }
  }

  handleCurrency = (e) => {
    let i = [];
    if(this.props.campaignData.mode === 'e-commerce') {
      i = [...this.props.campaignData.items];
      const items = i.map(s => {
        return {
          ...s,
          price: convertCurrency(s.price, e.target.value)
        }
      });

      this.setState({ items });
    }

    this.setCurrency(e.target.value);
  }

  handleItems = (e, item) => {
    let donated = [...this.state.donatedItems];
    if (donated.length === 0) {
      donated = [{ ...item, count: e.target.value }];
    } else {
      if (!e.target.value) {
        _.remove(donated, d => d.title === item.title);
      } else {
        const find = donated.find(d => d.title === item.title);
        if (find) {
          donated.map(d => {
            if (d.title === item.title) {
              d.count = e.target.value;
              return d;
            }
            return d;
          });
        } else {
          item.count = e.target.value;
          donated.push(item);
        }
      }
    }

    this.setState({ donatedItems: donated });
  }

  render() {
    const { details, viewMore, dialogContent, currency, items } = this.state;
    const { campaignTeams, editDonation, campaignData } = this.props;

    return (
      <section className="prospect-form" style={{ padding: editDonation && 0 }}>
        {!editDonation && details.id && (
          <div className="prev-next-container">
            <button className="dialog-arrow-btn" onClick={this.previous}>
              <ArrowBackIosIcon />
              PREVIOUS{" "}
            </button>
            <button className="dialog-arrow-btn" onClick={this.next}>
              NEXT
              <ArrowForwardIos />
            </button>
          </div>
        )}

        <Grid container>
          <Grid item xs={12} sm={!editDonation ? 7 : 12}>
            <ValidatorForm
              ref="form"
              onSubmit={() => this.submit(false)}
              instantValidate={true}
            >
              <DonorInfo
                viewMore={viewMore}
                details={details}
                editDonation={editDonation}
                handleViewMore={this.viewMore}
                campaignTeams={campaignTeams}
                onChange={this.onChange}
                handleDisplayName={this.displayName}
              />
              {!editDonation && (
                <Hidden smDown>
                  <div id="bottom-button-bar">
                    <Button
                      variant="contained"
                      className="red-btn"
                      type="submit"
                    >
                      Save & Process Manual Donation
                    </Button>
                    <Button
                      variant="contained"
                      className="blue-btn"
                      onClick={() => this.submit(true)}
                    >
                      Save Info & Process Credit Card Donation
                    </Button>
                    <Button
                      variant="contained"
                      className="green-btn"
                      onClick={this.closeModal}
                    >
                      Cancel
                    </Button>
                  </div>
                </Hidden>
              )}
            </ValidatorForm>
          </Grid>
          {!editDonation && (
            <Grid item xs={12} sm={5}>
              <ManualDonation
                handleItems={this.handleItems}
                items={items}
                handleCurrency={this.handleCurrency}
                currency={currency}
                campaignData={campaignData}
                isNumber={this.isNumber}
                details={details}
                campaignTeams={campaignTeams}
                onChange={this.onChange}
                handleDisplayName={this.displayName}
              />
            </Grid>
          )}
        </Grid>

        {editDonation && (
          <div className="update-btn">
            <Button
              variant="contained"
              className="bottom-long-blue"
              onClick={this.editDonation}
            >
              Update Donation
            </Button>
          </div>
        )}
        <ConfirmationDialog
          dialogtitle="Close"
          dialogContent={dialogContent}
          open={this.state.openConfirmation}
          handleConfirmation={this.handleConfirmation}
        />
      </section>
    );
  }
}

export default ProspectTabContent;
