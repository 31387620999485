
export const invalidEmail = (email) =>{
	if(!email) return false 
    var pattern = /[A-Z0-9._%+-]+@[A-Z0-9.-]+.[A-Z]{2,4}/igm; 
    return pattern.test(email)
}

export const isNumber = (num) =>{
	//var pattern = /^[0-9]*$/;
	var pattern = /[+-]?([0-9]*[.])?[0-9]+/;
	return pattern.test(num)
}

export const isOnlyNumber = (num) =>{
	//var pattern = /^[0-9]*$/;
	var pattern = /^\d+$/;
	return pattern.test(num)
}

export const isOnlyFloatOrInteger = (num) =>{
	var pattern = /^[0-9]\d{0,9}(\.\d{1,3})?%?$/;
	return pattern.test(num)
}

export const phoneNumberValidation = (num) => {
	var pattern = /^\d{10}$/;
	return pattern.test(num)
}

export const phoneValidation = (num) =>{
	if(!num) return false
	if(num.toString().length == 10) return true;
    else return false
}