import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Confetti from "react-confetti";
import sizeMe from "react-sizeme";
import { Helmet } from "react-helmet";
import Hidden from "@material-ui/core/Hidden";
import * as campaignActions from "../../redux/modules/campaign";
import { CampaignVideo } from "./CampaignVideo";
import { PowerDonars } from "./PowerDonars";
import { Calculator } from "../../components/Calculator/Calculator";
import CampaignDetails from "../../components/CampaignDetails";
import { CampaignGoals } from "../../components/CampaignDetails/Goals";
import { Progress } from "../../components/CampaignDetails/Progress";
import CampaignItems from "../../components/CampaignItems";
import { Summary } from "../../components/CampaignItems/Summary";
import PreCampaign from "../../components/PreCampaign";
import MobileCalculator from "../../components/Calculator/Responsive/MobileCalculator";
import Error from "../../components/Notification/Error";
import { ProgressLoader } from "../../components/Loader";
import { currencies } from "../../Common/currencies";
import { getExchangeRate } from "../../Common/Exchange";
import "./Campaign.scss";

const Campaign = sizeMe({
  monitorHeight: true,
  monitorWidth: true
})(
  class Campaign extends Component {
    static propTypes = {
      size: PropTypes.shape({
        width: PropTypes.number,
        height: PropTypes.number
      })
    };

    constructor(props) {
      super(props);

      this.state = {
        title: "",
        video: "",
        powerDonars: [],
        details: {},
        total_donation: "",
        donors: [],
        open: false,
        responsive_calculator: false,
        error: "",
        notify: false,
        imageURL: "",
        donate: [],
        donationType: "oneTime",
        loadingPercent: 0,
        shares: {},
        currency: {
          name: 'United States Dollar (USD)',
          code: 'usd',
          sign: '$'
        }
      };

    }

    componentWillMount() {
      const {
        onCampaignActions,
        match: { params },
        history,
        campaign
      } = this.props;
      if (campaign.success && campaign.data) {
        this.setState({ loadingPercent: 100 });
        const { data } = campaign;
        const currency = data.campaignCurrency ? currencies.find(e => e.code === data.campaignCurrency) : data.currency;
        this.setState({ currency, details: data })
      } else {
        onCampaignActions
          .getCampaign(params.campaign)
          .then(res => {
            const { data, data: { campaignId, organizationId } } = res.data;
            console.log('res.data',res.data);
            getExchangeRate(res.data.data.campaignCurrency || res.data.data.currency[0].code || 'usd');
            this.setState({ loadingPercent: 25 });
            if (
              data === false ||
              data === "No Records Found For That Route"
            ) {
              this.setState({
                error: `No Record Found for ${params.campaign}!`,
                notify: true
              });
              setTimeout(() => {
                history.push("/not-found-404", { isPushed: true });
              }, 2000);
            } else {
              onCampaignActions
                .totalDonation(organizationId, campaignId)
                .then(() => {
                  this.setState({ loadingPercent: 50 });
                  onCampaignActions
                    .getDonations(organizationId, campaignId)
                    .then(() => {
                      this.setState({ loadingPercent: 75 });
                      onCampaignActions
                        .getItems(
                          organizationId,
                          campaignId
                        )
                        .then(() => {
                          onCampaignActions.socialShare('456757','https://drafts.pledje.com/campaign3','imageUrl','Test Campaign 3');
                          this.setState({ loadingPercent: 100 });
                        });
                    });
                });

              setInterval(() => {
                onCampaignActions.totalDonation(organizationId, campaignId);
                onCampaignActions.getDonations(organizationId, campaignId);
              }, 10000);
            }
          })
          .catch(() => {
            this.setState({ loadingPercent: 100 });
            this.setState({ error: "No Record Found!", notify: true });
          });
      }
    }

    componentDidUpdate(oldProps) {
      const { campaign } = this.props;
      const {
        onCampaignActions
      } = this.props;
      
      if (oldProps.campaign !== campaign) {
        const { data } = campaign;
        const currency = data.campaignCurrency ? currencies.find(e => e.code === data.campaignCurrency) : data.currency;
        this.setState({ currency })
      }
    }

    componentWillReceiveProps(nextProps) {
      const {
        campaign: { data },
        total_donation,
        donations,
        share
      } = nextProps;


      if (typeof data === "object") {
        this.setState({
          title: data.name,
          video: data.videoURL,
          powerDonars: data.superDonors,
          details: data,
          imageURL: data.mainContent.imageURL
        });
        if (
          data.status === "pre-campaign" &&
          new Date() < new Date(data.preCampaignEndDate)
        ) {
          this.setState({ open: true });
        }
      }
      if (total_donation.data) {
        this.setState({ total_donation: total_donation.data.total });
      }
      if (donations.data) {
        this.setState({ donors: donations.data });
      }
      if (share.data) {
        this.setState({ shares: share.data });
      }
    }

    openCalculator = () => {
      this.setState({ responsive_calculator: true });
    };

    closeCalculator = () => {
      this.setState({ responsive_calculator: false });
    };

    handleNotification = () => {
      this.setState({ notify: false });
    };

    donate = donated => {
      this.setState({ donate: donated });
    };

    checkout = total => {
      const { onCampaignActions, history } = this.props;
      const {
        donate,
        details: { matchFactor },
        donationType,
        currency
      } = this.state;

      if (!total) {
        this.setState({
          error: `Your shopping cart is empty. Please choose items to donate or enter a valid general donation amount to proceed. Thanks You!`,
          notify: true
        });

        return;
      }

      const data = {
        amount: total,
        donation_type: donationType,
        matchFactor,
        selectedItems: donate,
        duration: donationType === "monthly" ? 10 : 10,
        currency,
        cart: true
      };

      onCampaignActions.setDonation(data);
      history.push("/checkout");
    };

    handleChange = e => {
      this.setState({ [e.target.name]: e.target.value });
    };

    handleCurrency = (c) => {
      this.setState({ currency: c });
    }

    render() {
      const {
        title,
        video,
        powerDonars,
        details,
        open,
        responsive_calculator,
        notify,
        error,
        total_donation,
        donors,
        imageURL,
        donate,
        donationType,
        loadingPercent,
        shares,
        currency
      } = this.state;
      const { onCampaignActions, history, location, items } = this.props;
      const bonus = total_donation > details.goalAmount;
      const sign = details.campaignCurrency ? (currencies.find(e => e.code === details.campaignCurrency.code || details.campaignCurrency)).sign : '$';

      return (
        <div
          className={"campaign_wrapper" + (bonus ? " green-bg" : " blue-bg")}
        >
          <ProgressLoader loadingPercent={loadingPercent}>
            <Helmet>
              <meta charSet="utf-8" />
              <title>{details.name}</title>
              <link rel="canonical" href={window.location.href} />
            </Helmet>
            <div className="wrapper">
              <div className="content">
                <div id='description' className={details.mode === 'e-commerce' ? 'description instruction' : 'description block'}>
                  {details.mode === 'e-commerce' && <img className='instruction-img' src='/images/instruction.png' alt='camp-img' />}
                  {video && (
                    <CampaignVideo displayImg={true} video={video} status={details.status} location={location} preCampaignEnd={details.preCampaignEndDate} />
                  )}
                </div>

                {powerDonars && powerDonars.length > 0 && (
                  <PowerDonars
                    powerDonars={powerDonars}
                    title={title}
                    details={details}
                    donation={total_donation}
                  />
                )}

                <div className="project-inner-content">
                  {title.length < 30 ? (
                    <h1 className="projects-custom-title">{title}</h1>
                  ) : title.length < 35 ? (
                    <h2 className="projects-custom-title">{title}</h2>
                  ) : (
                        <h3 className="projects-custom-title">{title}</h3>
                      )}
                </div>
                <div className="mobile-clock">
                  <CampaignGoals details={details} donation={total_donation} sign={sign} />
                  <Progress
                    shares={shares}
                    bonusGoal={details.bonusGoalAmount1}
                    goalAmount={details.goalAmount}
                    socialShareAmount={details.socialShareAmount}
                    donation={total_donation}
                    sign={sign}
                  />
                </div>
                {Object.keys(details).length > 0 &&
                  details.mode === "e-commerce" &&
                  items.length > 0 ? (
                    <>
                      <CampaignItems
                        handleChange={this.handleChange}
                        donationType={donationType}
                        handleDonate={this.donate}
                        donate={donate}
                        items={items}
                        details={details}
                        onCampaignActions={onCampaignActions}
                        history={history}
                        handleCurrency={this.handleCurrency}
                        location={location}
                      />
                      <Hidden mdUp>
                        <Summary
                          donate={donate}
                          details={details}
                          checkout={this.checkout}
                          currency={currency}
                        />
                      </Hidden>
                    </>
                  ) : null}

                <img className="camp-img" src={imageURL} alt="Campaign" />

              </div>
              {Object.keys(details).length > 0 && (
                <CampaignDetails
                  location={location}
                  details={details}
                  donation={total_donation}
                  donors={donors}
                  imageURL={imageURL}
                  history={history}
                />
              )}
              <div style={{ clear: "both" }} />
            </div>
            {details.status === "completed" && (
              <div
                style={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  width: "100%",
                  height: "100%"
                }}
              >
                <Confetti {...this.props.size} numberOfPieces={400} />
              </div>
            )}
            {Object.keys(details).length > 0 &&
              (details.mode === "e-commerce" ? (
                <Hidden smDown>
                  <Summary
                    donate={donate}
                    details={details}
                    checkout={this.checkout}
                    currency={currency}
                  />
                </Hidden>
              ) : (
                  details.hideCalculator ?
                    new Date() < new Date(details.campaignEndTime) ?
                      <Calculator
                        onCampaignActions={onCampaignActions}
                        history={history}
                        details={details}
                      />
                      :
                      null
                    :
                    <Calculator
                      onCampaignActions={onCampaignActions}
                      history={history}
                      details={details}
                    />
                ))
            }
            {details.mode !== "e-commerce" && (
              details.hideCalculator ?
                new Date() < new Date(details.campaignEndTime) ?
                  <div className="mobile_donate" onClick={this.openCalculator}>
                    <p style={{ textAlign: "center" }}>DONATE NOW</p>
                  </div>
                  :
                  null
                :
                <div className="mobile_donate" onClick={this.openCalculator}>
                  <p style={{ textAlign: "center" }}>DONATE NOW</p>
                </div>
            )}
            {open && (
              <PreCampaign
                onCampaignActions={onCampaignActions}
                history={history}
                details={details}
              />
            )}
            {responsive_calculator && details.mode !== "e-commerce" && (
              <MobileCalculator
                history={history}
                handleClose={this.closeCalculator}
                open={responsive_calculator}
                details={details}
              />
            )}

            <Error
              open={notify}
              message={error}
              handleClose={this.handleNotification}
            />
          </ProgressLoader>
        </div>
      );
    }
  }
);

const mapStateToProps = state => {
  return {
    share: state.share.get("share"),
    campaign: state.campaign.get("campaignData"),
    donations: state.campaign.get("donations"),
    total_donation: state.campaign.get("total_donation"),
    items: state.campaign.get("items")
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onCampaignActions: bindActionCreators(campaignActions, dispatch)
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Campaign);
