import React from 'react';
import PropTypes from 'prop-types';
import { Dialog } from '@material-ui/core';
import ProspectTabContent from '../ProspectManagement/ProspectTabContent';

function EditDonation ({ fullScreen, visible, handleClose, campaignTeams, onSubmitAndClose, currentProspect, details }) {
  return (
    <Dialog
      fullScreen={fullScreen}
      maxWidth={'md'}
      scroll='body'
      open={visible}
      onClose={handleClose}
      aria-labelledby="responsive-dialog-title"
    >
      <ProspectTabContent
        editDonation={true}
        currentProspect={currentProspect}
        campaignTeams={campaignTeams}
        onSubmitAndClose={onSubmitAndClose}
        campaignData={details}
      />
    </Dialog>
  )
}

EditDonation.propTypes = {
  fullScreen: PropTypes.bool.isRequired,
};

export default EditDonation;
