import React from 'react';
import Button from '@material-ui/core/Button';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Dialog from '@material-ui/core/Dialog';
import '../Dialog.scss';

const ConfirmationDialog = ({ classes, dialogtitle, dialogContent, value, handleConfirmation, ...other }) => {
  return (
    <Dialog
      open={true}
      className="confirmation-dialog"
      disableBackdropClick
      disableEscapeKeyDown
      maxWidth="xs"
      aria-labelledby="confirmation-dialog-title"
      {...other}
    >
      <DialogTitle id="confirmation-dialog-title" className="title">{dialogtitle}</DialogTitle>
      <DialogContent className="content">{dialogContent}</DialogContent>
      <DialogActions>
        <Button className="button" onClick={handleConfirmation.bind(null, true)} color="primary">
          Yes
        </Button>
        <Button className="button" onClick={handleConfirmation.bind(null, false)} color="primary">
          No
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default ConfirmationDialog;