import React, { Component } from 'react';
import { Header } from './Header';
import Filters from './Filters';
import Table from './Table';
import AccessProspect from './AccessProspect';
import './index.scss';

class MobileView extends Component {
  constructor() {
    super();

    this.state = {
      openModal: false,
      prospects: [],
      prospect: {},
      expanded: false
    }
  }

  handleOpen = (prospect, prospects) => {
    this.setState({ openModal: true, prospect, prospects  })
  }

  handleClose = () => {
    this.setState({ openModal: false });
  }

  handleManual = (prospect, details) => {
    const { onManualPaymentClick } = this.props;
    this.handleClose();
    onManualPaymentClick(prospect, details);
  }

  submit = (payload) => {
    const { onSubmitAndClose } = this.props;
    this.handleClose();
    onSubmitAndClose(payload);
  }

  expanded = (e, exp) => {
    this.setState({ expanded: exp });
  }

  searchNames = (e) => {
    setTimeout(() => {
      this.handleClose();
    }, 2000)

    const { searchProspect } = this.props;
    searchProspect(e);
  }

  render() {
    const { openModal, prospect, prospects, expanded } = this.state;

    return (
      <div className='mp-content-area'>
        <div className='sticky'>
          <Header {...this.props} showCampaignName={true} searchNames={this.searchNames} />
          <Filters {...this.props} expanded={this.expanded} />
          <div className='assignedProspects'>
            <i className="fa fa-pencil-square-o" aria-hidden="true"></i>
            <label>Your Assigned Prospects</label>
          </div>
        </div>
        <Table {...this.props} openProspectModal={this.handleOpen} expanded={expanded} />
        <AccessProspect searchNames={this.searchNames} submit={this.submit} handleManual={this.handleManual} handleClose={this.handleClose} openModal={openModal} prospectsList={prospects} currentProspect={prospect} {...this.props} />
      </div>
    );
  }
}

export default MobileView;
