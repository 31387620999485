const sessionStore = {
  set: (key, object) => {
    if(!sessionStorage) {
      return;
    }
    sessionStorage[key] = (typeof object) === 'string' ? object : JSON.stringify(object);
  },
  get: (key) => {
    if(!sessionStorage || !sessionStorage[key]) {
      return null;
    }

    try {
      const parsed = JSON.parse(sessionStorage[key]);
      return parsed;
    } catch(e) {
      return sessionStorage[key];
    }
  }
};

export default sessionStore;