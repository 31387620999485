import React from 'react';
import { AppBar, NativeSelect } from '@material-ui/core';
import './TeamTableHeader.scss';

function TeamTableHeader({ rowsPerPage, handleChangeRowsPerPage }) {
  return (
    <AppBar style={{backgroundColor:'#fcbe11'}} position="static" id='team-leader-table-header'>
      <div className='table-view'>
        View
        <NativeSelect
          className='select'
          disableUnderline
          value={rowsPerPage}
          onChange={handleChangeRowsPerPage}
          name="viewTableRows"
        >
          <option value="15">15</option>
          <option value="20">20</option>
          <option value="25">25</option>
        </NativeSelect>                          
      </div>
    </AppBar>
  );
}

export default TeamTableHeader;
