import React from 'react';
import { Button, Radio, RadioGroup, FormControlLabel, FormControl } from '@material-ui/core';
import AccessProspect from './AccessProspect';
import { AdminTimer } from '../../components/Timer';
import UserList from '../../components/Dialog/UserList';
import './AddProspectSection.scss';
import '../../Common/Clock.scss';

function AddProspectSection({ sortby, onSortChange, onFileChange, pospectModal, openProspectModal, closeProspectModal,
  onSubmitAndClose, onManualPaymentClick, details, donation, selectUser, openUserList, closeUserList, ...props }) {
  const { authUser, campaignReports, filteredUsers, teams , users, searchUser } = props;
  const userList = filteredUsers ? filteredUsers : users;

  return (
    <div className='prospect-main'>
      <div className='section1'>
        <div className='title'>Good Luck {authUser.firstName} {authUser.lastName}</div>
        <div className='filter-title'>Filter By</div>
        <FormControl className="sort-radio" component="fieldset">
          <RadioGroup
            className='filters'
            aria-label="sortBy"
            name="sortBy"
            value={sortby}
            onChange={onSortChange}
          >
            <FormControlLabel className="radio-item" value="allProspects" control={<Radio />} label='All Prospects' />
            <FormControlLabel className="radio-item" value="assignedProspects" control={<Radio />} label='Your Prospects' />
            <FormControlLabel className="radio-item" value="archivedProspects" control={<Radio />} label='Archived Prospects' />
            <FormControlLabel className="radio-item" value="volunteerProspects" control={<Radio />} label="Volunteer's Prospects" />
          </RadioGroup>
          <div className='user-list-btn' onClick={(e) => onSortChange(e, 'volunteerProspects')}>
            <i className="fa fa-chevron-down" aria-hidden="true"></i>
          </div>
        </FormControl>
      </div>

      <div className='section2'>
        <div className='title'>Total Number of Donors: <span>{campaignReports ? campaignReports.length : 0}</span></div>          
        <Button variant="contained" onClick={openProspectModal}>
          <i className="fa fa-users" aria-hidden="true"></i>
          ADD NEW PROSPECT
        </Button>
        <Button variant="contained" onClick={() => alert('Work in progress')}>
          <i className="fa fa-list-alt" aria-hidden="true"></i>
          SCRIPTS & INCENTIVES
        </Button>
        <input
          className={"ContainedButtons-input-2"}
          id="contained-button-file"
          type="file"
          accept=".csv"
          onChange={onFileChange}
          style={{ "display": "none" }}
        />
        <label htmlFor="contained-button-file" className='upload-btn' >
          <Button variant="contained" component="span" className='span' >
            <i className="fa fa-cloud-upload" aria-hidden="true"></i>
            UPLOAD CSV
          </Button>
        </label>
      </div>

      <AdminTimer details={details} donation={donation} />

      { openUserList && <UserList userList={userList} searchUser={searchUser} selectUser={selectUser} openUserList={openUserList} closeUserList={closeUserList} />}
      
      { pospectModal && <AccessProspect
        closeProspectModal={closeProspectModal}
        onSubmitAndClose={onSubmitAndClose}
        campaignTeams={teams}
        onManualPaymentClick={onManualPaymentClick}
        campaignData={details}
        {...props}
      />}
    </div>
  );
}

export default AddProspectSection;