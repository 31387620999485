import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import firebase from "firebase";
import ErrorIcon from "@material-ui/icons/Error";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import {
  addTeam,
  verifyPageName
} from "../../redux/modules/TeamManagement/action-creator";
import AddTeamForm from "../CampaignDetails/AddTeamForm";
import { isOnlyFloatOrInteger } from "../../validations";
import Snackbar from "../../components/Snackbar/Snackbar";
import { MSGS } from "../../messages";

class Thankyou extends Component {
  constructor() {
    super();

    this.state = {
      addTeamFormVisible: false,
      avatar: "",
      teamName: "",
      teamPageName: "",
      teamCampaign: "",
      campaignGoal: "",
      email: "",
      firstName: "",
      lastName: "",
      campaignPicture: "",
      campaignVideo: "",
      text1: "",
      text2: "",
      text3: "",
      shareGoal: "",
      selectCampaign: "",
      campaignGoalErr: false,
      isAddModal: true,
      showMessage: false,
      message: "",
      error: false
    };
  }

  componentDidMount() {
    const { volunteerInfo } = this.props;

    if (volunteerInfo) {
      this.setState({
        email: volunteerInfo.email,
        firstName: volunteerInfo.firstName,
        lastName: volunteerInfo.lastName
      });
    }
  }

  handleAddTeamFormOpen = () => {
    const {
      match: {
        params: { organization, campaign }
      }
    } = this.props;
    const { history } = this.props;
    history.push(`/add-team/${organization}/${campaign}`, {
      organizationId: organization,
      campaignId: campaign,
      name: this.props.history.location.state.name
    });
  };

  handleAddTeamFormClose = () => {
    this.setState({
      addTeamFormVisible: false,
      teamName: "",
      teamPageName: "",
      teamCampaign: "",
      shareGoal: "",
      avatar: "",
      campaignGoal: "",
      campaignPicture: "",
      campaignVideo: "",
      text1: "",
      text2: "",
      text3: "",
      campaignGoalErr: false,
      email: "",
      firstName: "",
      lastName: ""
    });
  };

  onFormInputValueChange = event => {
    let value = event.target.value;

    if (event.target.name === "campaignGoal") {
      if (!isOnlyFloatOrInteger(event.target.value)) {
        if (!event.target.value === "")
          this.setState({ campaignGoalErr: true });
        else this.setState({ campaignGoalErr: false });
      } else {
        this.setState({ campaignGoalErr: false });
      }
    }

    if (this.state.isAddModal) {
      if (event.target.name === "teamCampaign") {
        if (this.state.teamPageName) {
          const {
            match: {
              params: { organization, campaign }
            }
          } = this.props;
          this.props.verifyPageName({
            organizationId: organization,
            campaignId: campaign,
            teamPageName: event.target.value
          });
        }
      }
    }

    this.setState({ [event.target.name]: value }, () => {
      console.log(this.state);
    });
  };

  onAddTeamClick = () => {
    const {
      teamName,
      teamPageName,
      campaignPicture,
      campaignVideo,
      campaignGoal,
      text1,
      avatar,
      email,
      firstName,
      lastName
    } = this.state;
    if (teamName === "" || teamPageName === "" || campaignGoal === "")
      return this.showSnackBar(MSGS.REQUIRED_ERR, true);
    if (this.state.campaignGoalErr)
      return this.showSnackBar(MSGS.CAMPAIGN_GOAL_ERR, true);
    const {
      match: {
        params: { organization, campaign }
      }
    } = this.props;
    let team = {
      organizationId: organization,
      campaignId: campaign,
      teamName,
      email,
      firstName,
      lastName,
      teamPageName,
      teamCampaign: campaign,
      campaignPicture,
      campaignVideo,
      avatar,
      campaignGoal,
      text1,
      isActive: false
    };
    this.props.addTeam(team);
    this.handleAddTeamFormClose();
  };

  handleImageChange = event => {
    const supportedExtensions = ["jpeg", "jpg", "png", "gif"];
    this.checkFileExtension(event, supportedExtensions);
  };

  handleVideoChange = event => {
    const supportedExtensions = ["mp4"];
    this.checkFileExtension(event, supportedExtensions);
  };

  checkFileExtension(event, supportedExtensions) {
    const path = event.target.files[0].name.split(".");
    const extension = `${path[path.length - 1]}`;

    if (supportedExtensions.includes(extension)) {
      this.setState({ [event.target.name]: event.target.value });
    } else {
      this.showSnackBar("Invalid file type", true);
      this.setState({ [event.target.name]: "" });
    }
  }

  onPageNameBlur = event => {
    const {
      match: {
        params: { organization, campaign }
      }
    } = this.props;
    if (!event.target.value) return;

    this.props.verifyPageName({
      organizationId: organization,
      campaignId: campaign,
      teamPageName: event.target.value
    });
  };

  handleUploadStart = () => {
    this.setState({ isUploading: true, progress: 0 });
  };

  handleProgress = progress => this.setState({ progress });

  handleUploadError = error => {
    this.setState({ isUploading: false });
    console.log("UPLOAD ERROR---->", error);
    this.showSnackBar("Error uploading file", true);
  };

  handleUploadSuccess = (source, filename, keyName) => {
    this.setState({ progress: 100, isUploading: false });
    firebase
      .storage()
      .ref(source)
      .child(filename)
      .getDownloadURL()
      .then(url =>
        this.setState({ [keyName]: url }, () =>
          this.showSnackBar("File uploaded", false)
        )
      );
  };

  showSnackBar = (message, isError) => {
    this.setState({ showMessage: true, message, error: isError });
    setTimeout(() => {
      this.setState({ showMessage: false, message: "" });
    }, 4000);
  };

  render() {
    const { addTeamFormVisible } = this.state;
    return (
      <div className="thankyou-wrapper-newUI">
        <img src="/images/logo.png" alt="LOGO" />
        <div className="header">
          <img src="/images/thumbsup1.png" alt="THUMBSUP" />
          <h1>SUCCESS</h1>
          <h6 style={{fontSize:'15px'}}>A confirmation email with the credentials has been sent to you. please check your inbox.
           </h6>
           <h6 style={{fontSize:'15px'}}>
           In an event if you have not received the email, please check your spam folder.</h6>
        </div>
        <div className="background">
          <div className="message center">

            <div className="right">
              
              <div>
                <strong>THANK YOU</strong>
              </div>
              
            </div>
          </div>
        </div>
        <AddTeamForm
          addTeamFormVisible={addTeamFormVisible}
          handleAddTeamFormClose={this.handleAddTeamFormClose}
          state={this.state}
          onFormInputValueChange={this.onFormInputValueChange}
          onAddTeamClick={this.onAddTeamClick}
          handleImageChange={this.handleImageChange}
          handleVideoChange={this.handleVideoChange}
          onPageNameBlur={this.onPageNameBlur}
          verifyPageErr={this.props.verifyPageErr}
          handleUploadStart={this.handleUploadStart}
          handleUploadError={this.handleUploadError}
          handleUploadSuccess={this.handleUploadSuccess}
          handleProgress={this.handleProgress}
          isFetching={this.state.isFetching}
          isUploading={this.state.isUploading}
          progress={this.state.progress}
        />
        <Snackbar
          message={this.state.message}
          color={this.state.error ? "danger" : "success"}
          icon={this.state.error ? ErrorIcon : CheckCircleIcon}
          place="tr"
          open={this.state.showMessage}
        />
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    verifyPageErr: state.teamManagementReducer.verifyPageErr,
    volunteerInfo: state.userManagementReducer.volunteerInfo
  };
};

const mapDispatchToProps = dispatch => {
  return {
    addTeam: bindActionCreators(addTeam, dispatch),
    verifyPageName: bindActionCreators(verifyPageName, dispatch)
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Thankyou);
