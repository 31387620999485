import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';

export const Currency = ({ open, currencies, closeCurrency, handleSelect }) => {
  return (
    <Dialog className='currency-dialog' open={open} fullWidth={true} onClose={closeCurrency}>
      <List>
        {currencies.map(item => {
          return (
            <ListItem key={item.code} onClick={() => handleSelect(item)}>
              {item.name}
            </ListItem>
          )
        }
        )}
      </List>
    </Dialog>
  )
}