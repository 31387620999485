var MSGS = {
    LOGIN_SUCCESS: "Login Successful",
    INVALID_EMAIL: "Invalid email..!",
    REQUIRED_ERR: "Kindly fill all the fields",
    REQUIRED_ATTR: "Kindly add attribute details before proceeding",
    REQUIRED_PRDT: "Kindly add product details before submitting",
    MSG_PRODUCT_ADDED: "Product added successfully",
    ADD_EMAIL: "Kindly enter your email address to reset password",
    PWD_SENT: "Please check your email for resetting the password",
    TEAM_ADDED: "You have suceessfully added a new team",
    CAMPAIGN_GOAL_ERR: "Campaign goal amount value is invalid",
    SELECT_CAMPAIGN: "Kindly select a Campaign before proceeding to add a new Prospect",
    PAGE_NAME_ERR: 'This Team Page name is already taken..!',
    INVALID_PHN: 'Invalid Phone Number..!',
    REQ_NAME: 'Kindly add first name and last name before proceeding',
    PASSWORD_UNEQUAL: "Passords don't match. Please check again"
}
export { MSGS };