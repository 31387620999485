import { createAction } from "redux-actions";
import axios from "axios";
import * as action from "./action";
import { BASE_URL } from "../../../apis/backEnd.apis";
import firebase from "../../../apis/firebase.apis";
import {getOrganizations} from './../NewCampaign/action-creator';
import { config } from "../../../config";
var request = require("request");
const token = config.apiAccessKey;


const FIRESTORE = firebase.firestore();

export const fetchCampaigns = organizationId => (dispatch, getState) => {

    return FIRESTORE.collection("organizations")
    .doc(organizationId)
   .get()
   .then(res => {
     let org = res.data();
     if (org.firebase) {
     
       let campaigns = [];
       for (let i in org.campaigns) {
         campaigns.push(org.campaigns[i]);
       }
       org.campaigns = campaigns;
       console.log('myorg',org);
       dispatch({
         type: "SUCCESS",
         payload: org
       });
     } else {
       
       request(
         {
           method: "GET",
           url: BASE_URL + "campaigns/campaigns/" + organizationId,
           headers: { "access-token": `${token}` }
         },
         function (error, response, body) {
           let data = JSON.parse(response.body).data;
           dispatch({
             type: "SUCCESS",
             payload: data
           });
         }
       );
     }
   });

};

export const sendBNotification=(oid,cid) =>
{
  return request(
    {
      method: "POST",
      url: BASE_URL + "campaigns/campaignNotification/" + oid + '/' + cid,
      headers: { "access-token": `${token}` }
    },
    function (error, response, body) {
      let data = JSON.parse(response.body).data;
    }
  ).then(res=>
  {

  })
}

export const fetchCampaignsForCapture = organizationId => (dispatch, getState) => {
  if(JSON.parse(localStorage.getItem('userInfo')).role !== "super-admin")
  {
    return FIRESTORE.collection("organizations")
    .doc(organizationId)
   .get()
   .then(res => {
     let org = res.data();
     if (org.firebase) {
     
       let campaigns = [];
       for (let i in org.campaigns) {
         campaigns.push(org.campaigns[i]);
       }
       org.campaigns = campaigns;
       console.log('myorg',org);
       dispatch({
         type: "SUCCESS",
         payload: org
       });
     } else {
       
       request(
         {
           method: "GET",
           url: BASE_URL + "campaigns/campaigns/" + organizationId,
           headers: { "access-token": `${token}` }
         },
         function (error, response, body) {
           let data = JSON.parse(response.body).data;
           dispatch({
             type: "SUCCESS",
             payload: data
           });
         }
       );
     }
   });
  }
  else{
    let campaigns =[];
    let org={};
    return getOrganizations().then(res=>
    {
      console.log('item',res);
      res.map(item=>
      {
        for (let i in item.campaigns) {
          campaigns.push(item.campaigns[i]);
        }
      })
      org.campaigns = campaigns;
      org.res=res;
      dispatch({
        type: "SUCCESS",
        payload: org
      })
     })
  }
};

export const getCampaigns = () =>
{
  let campaigns =[];
  let org={};
  return getOrganizations().then(res=>
  {
    return res;
   })
}

export const addContact=(contacts)=>
{
  return FIRESTORE.collection("contacts").add(contacts)
  .then(res=>
  {
    return   res;
  })
}

export const fetchErrors = (id)=>
{
  return FIRESTORE.collection("capturingErrors")
  .where("organizationId","==",id)
  .get()
  .then(res=>
  {
    let errors=[];
    res.docs.map(e=>
    {
      let data = e.data();
      errors.push(data);
    })
    return errors;
  })
}

export const getReport = (organizationId, campaignId) => dispatch => {
  request(
    {
      method: "GET",
      url: `${BASE_URL}donors/getCampaignReport/${organizationId}/${campaignId}`,
      headers: { "access-token": `${token}` }
    },
    function (error, response, body) {
      let data = JSON.parse(response.body).data;
      dispatch({
        type: action.GET_REPORT,
        payload: data
      });
    }
  );
};

const updateDonate = (id, data) =>
  axios.put(`${BASE_URL}donors/updateDonation/${id}`, data, {
    headers: { "access-token": `${token}`, "Content-Type": "application/json" }
  });

export const updateDonation = createAction(
  action.UPDATE_DONATION,
  updateDonate
);

const updateManualDonate = (id, data) =>
  axios.put(`${BASE_URL}donors/updateManualDonation/${id}`, data, {
    headers: { "access-token": `${token}`, "Content-Type": "application/json" }
  });

export const updateManualDonation = createAction(
  action.UPDATE_DONATION,
  updateManualDonate
);

export const setInitial = campaign => {
  return {
    type: action.SET_INITIAL,
    payload: campaign
  };
};

export const searchCampaign = (e, fields) => {
  return {
    type: action.SEARCH_CAMPAIGN,
    payload: { e, fields }
  };
};

export const clearCache = () => dispatch => {
  request(
    {
      method: "GET",
      url: BASE_URL + `campaigns/clearCache?accessToken=${token}`,
      headers: { "access-token": token }
    },
    function (error, response, body) {
      let data = JSON.parse(response.body).data;
      
      dispatch({
        type: "CLEARED",
        payload: data
      });
      console.log("error:", error);
    }
  );
};

const captureDonations = (oId, cId) => {
  return axios.post(`${BASE_URL}campaigns/captureDonations/${oId}/${cId}`, {
    headers: { "access-token": `${token}`, "Content-Type": "application/json" }
  });
}

export const handleCapture = createAction(
  action.CAPTURE_DONATION,
  captureDonations
);

export const filterReports = value => {
  return {
    type: action.FILTER_CAMPAIGN,
    payload: value
  };
};
