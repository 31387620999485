import { handleActions } from "redux-actions";
import * as action from "./actions";

const initialState = {
  users: [],
  isFetching: false,
  searchItem: "",
  filteredUsers: "",
  volunteerInfo: null
};

const searchUser = (state, action) => {
  let e = action.payload;
  let searchItem = e.target.value;
  state.searchItem = e.target.value;
  let users = state.users;
  let filteredUsers;

  filteredUsers = users.filter(
    user =>
      user.firstName.toUpperCase().indexOf(searchItem.toUpperCase()) > -1 ||
      user.lastName.toUpperCase().indexOf(searchItem.toUpperCase()) > -1 ||
      user.email.toUpperCase().indexOf(searchItem.toUpperCase()) > -1 ||
      user.role.toUpperCase().indexOf(searchItem.toUpperCase()) > -1 ||
      user.phone.toUpperCase().indexOf(searchItem.toUpperCase()) > -1
  );

  console.log(filteredUsers);
  return filteredUsers;
};

const filterUsers = payload => {
  let filtered = payload.filter(
    user =>
      user.status &&
      (user.status.toLowerCase() === "active" ||
        user.status.toLowerCase() === "inactive")
  );
  filtered = filtered.map(user => {
    const info = user.volunteerInfo;
    delete user["volunteerInfo"];
    return {
      ...user,
      ...info
    };
  });
  filtered = filtered.map(user => {
    return {
      userId: user.id,
      status: user.status,
      firstName: user.firstName,
      lastName: user.lastName,
      email: user.email,
      organizationId: user.organizationId,
      phone: user.phone,
      role: user.role,
      teamId: user.teamId ? user.teamId : "",
      day1Morning: user.day1Morning ? user.day1Morning : user.d1m,
      day1Afternoon: user.day1Afternoon ? user.day1Afternoon : user.d1a,
      day1Evening: user.day1Evening ? user.day1Evening : user.d1e,
      day2Morning: user.day2Morning ? user.day2Morning : user.d2m,
      day2Afternoon: user.day2Afternoon ? user.day2Afternoon : user.d2a,
      day2Evening: user.day2Evening ? user.day2Evening : user.d2e,
      createPage: user.createPage,
      laptop: user.laptop,
      goal: user.goal ? user.goal : "",
      socialMedia: user.socialMedia
    };
  });
  return filtered;
};

const handlers = {
  [action.FETCHING]: (state, action) => ({ ...state, isFetching: true }),
  [action.SUCCESS]: (state, action) => ({ ...state, isFetching: false }),
  [action.ERROR]: (state, action) => ({ ...state, isFetching: false }),
  VOLUNTEER_INFO: (state, action) => ({
    ...state,
    volunteerInfo: action.payload
  }),
  [action.GET_USERS]: (state, action) => ({
    ...state,
    users: filterUsers(action.payload)
  }),
  [action.SEARCH_USER]: (state, action) => ({
    ...state,
    filteredUsers: searchUser(state, action)
  })
};

export default handleActions(handlers, initialState);
