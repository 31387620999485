import React, { Component } from 'react';
import {
    FormControl,
    RadioGroup,
    FormControlLabel,
    Radio,
    Typography,
    Button,
    withStyles, Table, TableBody, TableCell, TableHead, TablePagination, TableRow, TableSortLabel, Paper
} from "@material-ui/core";
import axios from 'axios';
import moment from 'moment';
import {
    fetchErrors
  } from "../../redux/modules/CampaignReports/action-creator";


class Errors extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selected: [],
            page: 0,
            errors:false
        }
    }

    handleChangePage = (e, page) => {
        const { donations } = this.props;
        {
            this.setState({
                page: page,
                errors:[]
            })
        }

    }

    componentWillReceiveProps(props)
    {
        const {id} = this.props;
        let res = fetchErrors(id).then(res=>
            {
                this.setState({
                    errors:res
                })
            })
    }

    componentDidMount()
    {
        const {id} = this.props;
        let res = fetchErrors(id).then(res=>
        {
            this.setState({
                errors:res
            })
        })
    }


    render() {
        const {back} = this.props;
        const {errors} = this.state;
        return (
            <div className="main-wrap">
            <div className="div-donation">
             <button className="capture-donation" onClick={back}>BACK TO DONATIONS PAGE</button> 
            </div>
            <div className="title-donation">Errors</div>
            {errors && errors.length > 0 ?
                    <div>
                        <Paper>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell className="nowrap">
                                            Serial Number
                                        </TableCell>
                                        <TableCell className="nowrap">
                                            Donor Name
                                        </TableCell>
                                        <TableCell className="nowrap">
                                            Donation Date
                                        </TableCell>
                                        <TableCell className="nowrap">
                                            Donation Amount
                                        </TableCell>
                                        <TableCell className="nowrap">
                                            Email
                                        </TableCell>
                                        <TableCell className="nowrap">
                                            Error
                                        </TableCell>
                                    </TableRow>
                                </TableHead>

                                <TableBody>
                                    {errors.map((item, index) => 
                                        <TableRow key={index} style={{ backgroundColor: index % 2 === 0 ? '#fafafa' : 'white' }}>
                                            <TableCell>{index + 1}</TableCell>
                                            <TableCell>{item.name}</TableCell>
                                            <TableCell>{moment(item.donationDate).format("YYYY-MM-DD HH:mm")}</TableCell>
                                            <TableCell>{item.amount}</TableCell>
                                            <TableCell>{item.email}</TableCell>
                                            <TableCell>{item.error}</TableCell>
                                        </TableRow>
                                    )}
                                </TableBody>
                                </Table>
                                </Paper>
                                </div>:<div>No errors for this campaign</div>}


            </div>
        )
    }
}

export default Errors;