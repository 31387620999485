var base = '#008eb5',teambtnColor='#30748c',teamBtnBG='#aae7f8';
const styles = {
  colorSwitchBase: {
    '&$colorChecked': {
      color: '#008eb5',
      '& + $colorBar': {
        backgroundColor: '#008eb5',
      },
    },
  },
  colorBar: {},
  colorChecked: {},
    container:{
        height:'100vh',background:'white',
      
    },
    theme:{
        color:base
    },
    themeText:{
        color :base, margin:0,fontWeight:'bold'
    },  
    innerDivSections:{
        width:'60%',
        margin:'auto',
        display:'flex',
        flexDirection:'column',
        alignItems:'center',
        marginTop : 10,
    },
    buttonStyle:{
        width:'90%',
        margin:'auto',
        justifyContent:'space-around',
        borderRadius:0,
        margin:'4%',
        background :teamBtnBG,
        color:teambtnColor
    },
    tableRoot:{
      width: '100%',
      overflowX: 'auto',
    },
    appBar:{
      background: base,
      padding:'1%'
    },
    inputStyle:{
        background :'white',
        borderRadius :10,
    },
    h50 : {
        minHeight: 50,
        padding: '0 10px 0 10px',
        margin : 4
      },
    rowStyle:{
        display:'flex',
        flexDirection:'row',
        justifyContent:'space-between'
    }
  };
export default styles;