import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  Grid,
  Button,
  FormControl,
  FormControlLabel,
  NativeSelect,
  RadioGroup,
  Radio
} from "@material-ui/core";
import Snackbar from "../../components/Snackbar/Snackbar";
import ErrorIcon from "@material-ui/icons/Error";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import {
  add,
  setVolunteer,
  fetch
} from "../../redux/modules/Users/action-creator";
import LoadingOverlay from "react-loading-overlay";
import "./AddVolunteer.scss";
const hours = ["0", "1", "2", "3", "4"];
const initialState = {
  firstName: "",
  lastName: "",
  email: "",
  password: "",
  phone: ""
};
const initialInfo = {
  goal: "",
  socialMedia: "facebook",
  day1Morning: "0",
  day1Afternoon: "0",
  day1Evening: "0",
  day2Morning: "0",
  day2Afternoon: "0",
  day2Evening: "0",
  laptop: "false",
  createPage: "false"
};
class AddVolunteer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      details: initialState,
      volunteer_info: initialInfo,
      repeatPassword: "",
      passMatch: true,
      disableBtn: false,
      message: "",
      error: false,
      showMessage: false,
      pageName: "",
      isLoading: false
    };
  }
  componentDidMount() {
    console.log(this.props);
    window.scrollTo(0, 0);
    const { user, edit } = this.props;
    if (edit) {
      this.setState({
        details: {
          firstName: user.firstName,
          lastName: user.lastName,
          email: user.email,
          phone: user.phone,
          userId: user.userId
        },
        volunteer_info: {
          goal: user.goal,
          socialMedia: user.socialMedia,
          day1Morning: user.day1Morning,
          day1Afternoon: user.day1Afternoon,
          day1Evening: user.day1Evening,
          day2Morning: user.day2Morning,
          day2Afternoon: user.day2Afternoon,
          day2Evening: user.day2Evening,
          laptop: user.laptop,
          createPage: user.createPage
        }
      });
    }
  }
  showSnackBar = (message, isError) => {
    this.setState({ showMessage: true, message, error: isError });
    setTimeout(() => {
      this.setState({ showMessage: false, message: "" });
    }, 4000);
  };
  handlePasswordChange = e => {
    const {
      details: { password }
    } = this.state;
    this.setState({
      [e.target.name]: e.target.value,
      passMatch: password === e.target.value ? true : false
    });
  };
  handleChange = e => {
    e.persist();
    this.setState(prevState => ({
      details: {
        ...prevState.details,
        [e.target.name]: e.target.value
      }
    }));
  };
  changeVolunteerInfo = e => {
    e.persist();
    this.setState(prevState => ({
      volunteer_info: {
        ...prevState.volunteer_info,
        [e.target.name]: e.target.value
      }
    }));
  };
  handleCancel = () => {
    const { handleAddUserFormClose } = this.props;
    handleAddUserFormClose();
  };
  handleSubmit = () => {
    const {
      addUser,
      updateUser,
      fetchVolunteers,
      handleAddUserFormClose,
      organizationId,
      edit
    } = this.props;
    const { details, volunteer_info } = this.state;
    const data = {
      user: {
        ...details,
        volunteer_info,
        role: "volunteer",
        organizationId: organizationId,
        status: "Inactive"
      }
    };

    setVolunteer(details);
    this.setState({ disableBtn: true, isLoading: true });
    if (!edit) {
      addUser(data)
        .then(() => {
          fetchVolunteers(organizationId);
          this.setState({ isLoading: false });
          this.showSnackBar("Volunteer Created Successfully", false);
          setTimeout(() => {
            handleAddUserFormClose();
          }, 4000);

          this.setState({ details: initialState, disableBtn: false });
        })
        .catch(err => {
          console.log("err");
          console.log(err);
          this.setState({ disableBtn: false, isLoading: false });
          this.showSnackBar(
            err.response.data.error.message
              ? err.response.data.error.message
              : err.response.data.error,
            true
          );
        });
    } else {
      updateUser(data);
    }
  };
  render() {
    const {
      details: { firstName, lastName, email, password, phone },
      volunteer_info: {
        goal,
        socialMedia,
        day1Morning,
        day1Afternoon,
        day1Evening,
        day2Morning,
        day2Afternoon,
        day2Evening,
        laptop,
        createPage
      },
      repeatPassword,
      passMatch,
      disableBtn
    } = this.state;
    const { edit } = this.props;
    return (
      <div className="volunteer-form">
        <div className="header">
          <h3>ADD A NEW VOLUNTEER</h3>
        </div>
        <Grid container>
          <Snackbar
            message={this.state.message}
            color={this.state.error ? "danger" : "success"}
            icon={this.state.error ? ErrorIcon : CheckCircleIcon}
            place="tr"
            open={this.state.showMessage}
          />
          <LoadingOverlay
            active={this.state.isLoading}
            spinner
            text="Adding Volunteer"
          >
            <ValidatorForm
              className="add-volunteer"
              ref="form"
              onSubmit={this.handleSubmit}
              instantValidate={true}
            >
              <Grid item container xs={12}>
                <Grid item sm={6} xs={12}>
                  <div className="user-info">
                    <label className="input-label">First Name</label>
                    <TextValidator
                      validators={["required"]}
                      errorMessages={["This field is required"]}
                      ref="firstName"
                      name="firstName"
                      value={firstName}
                      className="input"
                      onChange={this.handleChange}
                      onBlur={this.handleBlur}
                    />
                  </div>
                </Grid>
                <Grid item sm={6} xs={12}>
                  <div className="user-info">
                    <label className="input-label">Last Name</label>
                    <TextValidator
                      validators={["required"]}
                      errorMessages={["This field is required"]}
                      ref="lastName"
                      name="lastName"
                      value={lastName}
                      className="input"
                      onChange={this.handleChange}
                      onBlur={this.handleBlur}
                    />
                  </div>
                </Grid>
              </Grid>
              <Grid item container xs={12}>
                <Grid item sm={6} xs={12}>
                  <div className="user-info">
                    <label className="input-label">Email</label>
                    <TextValidator
                      validators={["required", "isEmail"]}
                      errorMessages={[
                        "This field is required",
                        "Invalid email address"
                      ]}
                      ref="email"
                      name="email"
                      value={email}
                      className="input"
                      onChange={this.handleChange}
                      onBlur={this.handleBlur}
                    />
                  </div>
                </Grid>
                <Grid item sm={6} xs={12}>
                  <div className="user-info">
                    <label className="input-label">Mobile Number</label>
                    <TextValidator
                      validators={["required"]}
                      errorMessages={["This field is required"]}
                      ref="phone"
                      name="phone"
                      value={phone}
                      className="input"
                      onChange={this.handleChange}
                      onBlur={this.handleBlur}
                    />
                  </div>
                </Grid>
              </Grid>
              {!edit && (
                <Grid item container xs={12}>
                  <Grid item sm={6} xs={12}>
                    <div className="user-info">
                      <label className="input-label">Choose a Password</label>
                      <TextValidator
                        validators={["required"]}
                        errorMessages={["This field is required"]}
                        ref="password"
                        name="password"
                        value={password}
                        type="password"
                        className="input"
                        onChange={this.handleChange}
                        onBlur={this.handleBlur}
                      />
                    </div>
                  </Grid>
                  <Grid item sm={6} xs={12}>
                    <div className="user-info">
                      <label className="input-label">
                        Your Chosen Password
                      </label>
                      <div className="c-pass">
                        <TextValidator
                          validators={["required"]}
                          errorMessages={["This field is required"]}
                          name="repeatPassword"
                          value={repeatPassword}
                          type="password"
                          className="input"
                          onChange={this.handlePasswordChange}
                        />
                        {!passMatch && (
                          <p style={{ color: "red", fontSize: 11 }}>
                            Password does not match
                          </p>
                        )}
                      </div>
                    </div>
                  </Grid>
                </Grid>
              )}

              <div className="user-info">
                <FormControl>
                  <RadioGroup
                    row
                    name="socialMedia"
                    value={socialMedia}
                    onChange={this.changeVolunteerInfo}
                  >
                    <FormControlLabel
                      // classes={{ label: classes.label }}
                      value="facebook"
                      control={<Radio color="default" />}
                      label="Facebook"
                    />
                    <FormControlLabel
                      // classes={{ label: classes.label }}
                      value="instagram"
                      control={<Radio color="default" />}
                      label="Instagram"
                    />
                    <FormControlLabel
                      // classes={{ label: classes.label }}
                      value="whatsapp"
                      control={<Radio color="default" />}
                      label="WhatsApp"
                    />
                  </RadioGroup>
                </FormControl>
              </div>
              <p>Hours Available at Campaign Room</p>
              <Grid item container xs={12}>
                <Grid item sm={4} xs={12}>
                  <div className="user-info hour-info">
                    <label>[Day 1] Morning</label>
                    <NativeSelect
                      className="hoursDropDown"
                      onChange={this.changeVolunteerInfo}
                      name="day1Morning"
                      value={day1Morning}
                      disableUnderline={true}
                    >
                      {hours.map(h => (
                        <option key={h} value={h}>
                          {h}
                        </option>
                      ))}
                    </NativeSelect>
                  </div>
                </Grid>
                <Grid item sm={4} xs={12}>
                  <div className="user-info hour-info">
                    <label>[Day 1] Afternoon</label>
                    <NativeSelect
                      className="hoursDropDown"
                      onChange={this.changeVolunteerInfo}
                      name="day1Afternoon"
                      value={day1Afternoon}
                      disableUnderline={true}
                    >
                      {hours.map(h => (
                        <option key={h} value={h}>
                          {h}
                        </option>
                      ))}
                    </NativeSelect>
                  </div>
                </Grid>
                <Grid item sm={4} xs={12}>
                  <div className="user-info hour-info">
                    <label>[Day 1] Evening</label>
                    <NativeSelect
                      className="hoursDropDown"
                      onChange={this.changeVolunteerInfo}
                      name="day1Evening"
                      value={day1Evening}
                      disableUnderline={true}
                    >
                      {hours.map(h => (
                        <option key={h} value={h}>
                          {h}
                        </option>
                      ))}
                    </NativeSelect>
                  </div>
                </Grid>
              </Grid>
              <Grid item container xs={12}>
                <Grid item sm={4} xs={12}>
                  <div className="user-info hour-info">
                    <label>[Day 2] Morning</label>
                    <NativeSelect
                      className="hoursDropDown"
                      onChange={this.changeVolunteerInfo}
                      name="day2Morning"
                      value={day2Morning}
                      disableUnderline={true}
                    >
                      {hours.map(h => (
                        <option key={h} value={h}>
                          {h}
                        </option>
                      ))}
                    </NativeSelect>
                  </div>
                </Grid>
                <Grid item sm={4} xs={12}>
                  <div className="user-info hour-info">
                    <label>[Day 2] Afternoon</label>
                    <NativeSelect
                      className="hoursDropDown"
                      onChange={this.changeVolunteerInfo}
                      name="day2Afternoon"
                      value={day2Afternoon}
                      disableUnderline={true}
                    >
                      {hours.map(h => (
                        <option key={h} value={h}>
                          {h}
                        </option>
                      ))}
                    </NativeSelect>
                  </div>
                </Grid>
                <Grid item sm={4} xs={12}>
                  <div className="user-info hour-info">
                    <label>[Day 2] Evening</label>
                    <NativeSelect
                      className="hoursDropDown"
                      onChange={this.changeVolunteerInfo}
                      name="day2Evening"
                      value={day2Evening}
                      disableUnderline={true}
                    >
                      {hours.map(h => (
                        <option key={h} value={h}>
                          {h}
                        </option>
                      ))}
                    </NativeSelect>
                  </div>
                </Grid>
              </Grid>
              <p>
                If you are computer savvy, you may also assist us from the
                comfort of your home/office through a cloud platform provided by
                Pledje.com. This is a simple App and training can be provided.
              </p>
              <Grid item sm={4} xs={12}>
                <div className="user-info">
                  <label>Do you own a tablet/laptop?</label>
                  <NativeSelect
                    className="select"
                    onChange={this.changeVolunteerInfo}
                    name="laptop"
                    defaultValue={laptop}
                    disableUnderline={true}
                  >
                    <option value={true}>Yes</option>
                    <option value={false}>No</option>
                  </NativeSelect>
                </div>
              </Grid>
              <Grid item container xs={12}>
                <Grid item sm={4} xs={12}>
                  <div className="user-info">
                    <label>Do you want to create your own page?</label>
                    <NativeSelect
                      className="select"
                      onChange={this.changeVolunteerInfo}
                      name="createPage"
                      defaultValue={createPage}
                      disableUnderline={true}
                    >
                      <option value={true}>Yes</option>
                      <option value={false}>No</option>
                    </NativeSelect>
                  </div>
                </Grid>
                {createPage === "true" && (
                  <p>
                    You will be provided a special link to your very own
                    fundraising page which can track all the donations collected
                    by you. Please indicated your fundraising goal. You do not
                    have to have to reach this specific goal and if you surpass
                    it, the platform will adjust itself to a higher goal.
                  </p>
                )}
                {createPage === "true" && (
                  <Grid item sm={4} xs={12}>
                    <div className="user-info">
                      <label>Goal</label>
                      <TextValidator
                        validators={["required"]}
                        errorMessages={["This field is required"]}
                        ref="goal"
                        name="goal"
                        value={goal}
                        className="input"
                        onChange={this.changeVolunteerInfo}
                        onBlur={this.handleBlur}
                      />
                    </div>
                  </Grid>
                )}
              </Grid>
              <div className="btn-container">
                <Button className="cancel-btn" onClick={this.handleCancel}>
                  Cancel
                </Button>
                {edit ? (
                  <Button
                    disabled={disableBtn}
                    type="submit"
                    className="submit-btn"
                  >
                    Update
                  </Button>
                ) : (
                  <Button
                    disabled={disableBtn}
                    type="submit"
                    className="submit-btn"
                  >
                    Submit
                  </Button>
                )}
              </div>
            </ValidatorForm>
          </LoadingOverlay>
        </Grid>
      </div>
    );
  }
}
const mapStateToProps = state => {
  return {};
};
const mapDispatchToProps = dispatch => {
  return {
    addUser: bindActionCreators(add, dispatch),
    setVolunteer: bindActionCreators(setVolunteer, dispatch),
    fetchVolunteers: bindActionCreators(fetch, dispatch)
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AddVolunteer);
