import * as action from './action';
import { getAllDonations } from "../../../lib/campaign";
import { convertCurrency } from "../../../Common/CurrencyCalculator";
import _ from 'lodash';

export const fetching = () => ({type:action.FETCHING})
export const success = () => ({type:action.SUCCESS})
export const _error = (msg) => ({type:action.ERROR , payload  : msg})

const groupByTeamId = (details) => {
  return details.reduce(
    (r, o) => (
      r[o.teamId]
        ? (r[o.teamId].totalChargedAmount += o.totalChargedAmount)
        : (r[o.teamId] = { ...o }), r
      ),
    {}
  );
}

const getTeamName = (teamDetails, teamId) => {
  let teamName = "";
  for(let id in teamDetails) {
    if (id === teamId) {
      teamName = teamDetails[id].teamName;
      return teamName;
    }
  }
}

export const fetchDonations = (organizationId, campaignId, teams) => dispatch => {
  dispatch(fetching());
  getAllDonations(organizationId, campaignId)
    .then(res => {
      const donationDetails = res.data.data.donations
      let donorDetails = [];
      let allDonationDetails = [];
      donationDetails.forEach(donationDetail => {
        if (donationDetail.teamId) {
          donorDetails.push({
            teamId: donationDetail.teamId,
            totalChargedAmount: parseInt(
                convertCurrency(donationDetail.amount, donationDetail.currency || 'usd')
            )
          });
        }
      });
      
      let teamDetailsObject = groupByTeamId(donorDetails);
      let teamDetails = teams;

      for (let details in teamDetailsObject) {
        teamDetailsObject[details].teamName = getTeamName(
          teamDetails,
          teamDetailsObject[details].teamId
        );

        if (
          teamDetailsObject[details].teamName &&
          teamDetailsObject[details].teamId
        ) {
          allDonationDetails.push(teamDetailsObject[details]);
        }
      }
      dispatch(success());

      return dispatch({
        type: 'DONATION_DETAILS_SUCCESS',
        payload: _.orderBy(allDonationDetails, ['totalChargedAmount'], ['desc'])
      })
    });
}