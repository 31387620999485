import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import LoadingOverlay from "react-loading-overlay";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import ErrorIcon from "@material-ui/icons/Error";
import {
  fetchProspects,
  deleteProspect,
  uploadBulkProspects,
  updateProspect,
  addNew,
  searchProspect,
  applyProspectFilter,
  onClearFilter,
  filterByAssignment,
  assignProspect
} from "../../redux/modules/ProspectManagement/action-creator";
import {
  getReport,
  fetchCampaigns,
  updateDonation,
  updateManualDonation,
  setInitial
} from "../../redux/modules/CampaignReports/action-creator";
import { fetchTeams } from "../../redux/modules/TeamManagement/action-creator";
import { fetch, searchUser } from "../../redux/modules/Users/action-creator";
import { getCampaign, totalDonation } from "../../redux/modules/campaign";
import { postCheckoutInfo } from "../../redux/modules/checkout";
import HeaderComponent from "../HeaderComponent";
import AddProspectSection from "./AddProspectSection";
import ProspectTableHeader from "./ProspectTableHeader";
import ProspectFilters from "./ProspectFilters";
import ProspectTable from "./ProspectTable";
import MobileView from "./mobile";
import { MSGS } from "../../messages";
import Snackbar from "../../components/Snackbar/Snackbar";
import MobileCalculator from "../../components/Calculator/Responsive/MobileCalculator";
import ConfirmationDialog from "../../components/Dialog/Confirmation";
import { getExchangeRate } from "../../Common/Exchange";
import "./index.scss";
import "./custom.scss";

class ProspectManagement extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sortby: "allProspects",
      editProspect: false,
      rowsPerPage: 15,
      page: 0,
      showMessage: false,
      message: "",
      error: false,
      showArchivedProspects: false,
      campaign: "",
      openCalculator: false,
      details: {},
      total_donation: "",
      selectedProspects: [],
      prospectsList: [],
      currentProspect: null,
      pospectModal: false,
      loading: false,
      openUserList: false,
      assignProspectTo: "",
      showFilters: false,
      tableWidth: "",
      openCredit: false,
      payload: '',
      clicked: ''
    };
  }

  componentDidMount() {
    const {
      fetch,
      fetchCampaigns,
      authUser: { organizationId }
    } = this.props;
    this.setState({ loading: true });
    fetch(organizationId);
    fetchCampaigns(organizationId);
  }

  componentDidUpdate(oldProps) {
    const newProps = this.props;
    if (oldProps.initialCampaign !== newProps.initialCampaign) {
      getExchangeRate(newProps.initialCampaign.campaignCurrency || newProps.initialCampaign.currency || 'usd');
      this.setState({ campaign: newProps.initialCampaign }, () => {
        this.getProspects();
        newProps.getCampaign(this.state.campaign.campaignPage).then(res => {
          newProps.getReport(
            newProps.authUser.organizationId,
            res.data.data.campaignId
          );
          newProps.totalDonation(res.data.data.organizationId, res.data.data.campaignId);
        });
      });
    }
    const { prospects, refreshList, authUser: { organizationId } } = newProps;
    const { campaign } = this.state;
    if (oldProps.prospects !== prospects) {
      const test = document.getElementById("prospect-table");
      const tableWidth = test.clientWidth;
      this.setState({ tableWidth });
    }

    if(refreshList === true) {
      this.getProspects();
      this.props.getReport(organizationId, campaign.Id || campaign.campaignId);
    }
  }

  componentWillReceiveProps(nextProps) {
    const {
      campaign: { data },
      total_donation
    } = nextProps;

    if (typeof data === "object") {
      this.setState({ details: data });
    }
    if (total_donation.data) {
      this.setState({ total_donation: total_donation.data.total });
    }
  }

  getProspects = (clicked, prospectId) => {
    const {
      campaign: { Id, campaignId }
    } = this.state;
    const {
      fetchProspects,
      authUser: { organizationId }
    } = this.props;
    const cId = campaignId ? campaignId : Id;
    fetchProspects(organizationId, clicked, prospectId, cId).then(() => {
      this.setState({ loading: false });
    });
  };

  addProspect = (payload, clicked, donation) => {
    const {
      addNew,
      updateProspect,
      getReport,
      postCheckoutInfo,
      updateManualDonation,
      authUser: { organizationId },
      totalDonation,
      history
    } = this.props;
    const {
      details: { campaignId, mode },
      campaign: { campaignPage }
    } = this.state;

    if (!payload.campaignId) {
      payload.campaignId = campaignId;
      payload.organizationId = organizationId;
    }
    this.setState({ pospectModal: false, loading: true });
    if (payload.id) {
      updateProspect(payload, payload.id).then(() => {
        this.getProspects(clicked, payload.id);
        if (clicked === "manual") {
          if (!donation.hasOwnProperty("prospectId")) {
            donation.prospectId = payload.id;
            delete donation["id"];
            donation.processingFee = 0;
            donation.processingFeeCovered = false;
            donation.totalChargedAmount = parseFloat(donation.donationAmount);
            postCheckoutInfo(donation).then(() => {
              totalDonation(payload.organizationId, payload.campaignId);
              getReport(payload.organizationId, payload.campaignId);
            });
          } else {
            donation.id = donation.prospectId;
            updateManualDonation(donation.id, donation).then(() => {
              totalDonation(payload.organizationId, payload.campaignId);
              getReport(payload.organizationId, payload.campaignId);
            });
          }
        } else {
          getReport(payload.organizationId, payload.campaignId);
        }
      });
    } else {
      addNew(payload).then(res => {
        if(clicked === 'manual') {
          donation.prospectId = res.data.docId;
          postCheckoutInfo(donation)
          .then(() => {
            totalDonation(payload.organizationId, payload.campaignId);
            getReport(payload.organizationId, payload.campaignId)
            this.getProspects(clicked, res.data.docId);
          })
        } else {
          this.getProspects(clicked, res.data.docId);
        }
      });
    }

    if (clicked === "creditcard") {
      if(mode === 'e-commerce') {
        history.push(`/${campaignPage}`);
      } else {
        this.setState({ openCalculator: true });
      }
    }
  };

  onManualPaymentClick = (prospect, donation) => {
    const {
      authUser: { organizationId }
    } = this.props;
    const {
      details: { campaignId }
    } = this.state;
    donation.alias = donation.displayName ? donation.alias : "Anonymous";
    donation.gateway = "manual";
    donation.isManual = true;
    if (!donation.campaignId) {
      donation.campaignId = campaignId;
      donation.organizationId = organizationId;
    }
    this.addProspect(prospect, "manual", donation);
  };

  payByCredit = (payload, clicked) => {
    this.setState({ payload, clicked, openCredit: true });
  }

  confirmCredit = confirm => {
    const { payload, clicked } = this.state;
    this.setState({ openCredit: false });
    if(confirm) {
      this.addProspect(payload, clicked)
    }

    this.setState({ payload: '', clicked: '' });
  }

  editProspectModal = (prospect, prospects) => {
    const { campaign } = this.state;
    const {
      fetchTeams,
      authUser: { organizationId }
    } = this.props;
    if (campaign === "" || campaign === "none") {
      this.showSnackBar(MSGS.SELECT_CAMPAIGN, true);
      return;
    }

    fetchTeams(organizationId, campaign.Id || campaign.campaignId);
    this.setState({
      prospectsList: prospects,
      currentProspect: prospect,
      pospectModal: true,
      editProspect: true
    });
  };

  openProspectModal = () => {
    const { campaign } = this.state;
    if (campaign === "" || campaign === "none")
      return this.showSnackBar(MSGS.SELECT_CAMPAIGN, true);
    else this.props.fetchTeams(this.props.authUser.organizationId, campaign.Id || campaign.campaignId);
    this.setState({
      pospectModal: true,
      currentProspect: null,
      editProspect: false
    });
  };

  onCampaignChange = e => {
    const { setInitial } = this.props;
    setInitial(e.target.value);
    this.setState({ [e.target.name]: e.target.value });
  };

  onFileChange = e => {
    const { authUser } = this.props;
    const { campaign } = this.state;
    this.props.uploadBulkProspects({
      file: e.target.files[0],
      organizationId: authUser.organizationId,
      campaignId: campaign.Id || campaign.campaignId,
      userId: authUser.uid
    });
  };

  handleChangePage = (e, page) => {
    this.setState({ page });
  };

  handleChangeRowsPerPage = e => {
    this.setState({ rowsPerPage: parseInt(e.target.value) });
  };

  onCheckedboxClick = (e, prospect) => {
    if (e.target.checked) {
      this.setState(state => ({
        selectedProspects: [...state.selectedProspects, prospect.id]
      }));
    } else {
      const prospects = [...this.state.selectedProspects];
      const index = this.state.selectedProspects.indexOf(prospect.id);
      prospects.splice(index, 1);
      this.setState({ selectedProspects: prospects });
    }
  };

  onAllProspects = (e, prospects) => {
    if (e.target.checked) {
      this.setState({ selectedProspects: [] }, () => {
        prospects.forEach(item => {
          this.setState(state => ({
            selectedProspects: [...state.selectedProspects, item.id]
          }));
        });
      });
    } else {
      this.setState({ selectedProspects: [] });
    }
  };

  onAssignProspect = e => {
    const {
      assignProspect,
      campaign,
      authUser: { organizationId }
    } = this.props;
    const { selectedProspects } = this.state;

    this.setState({ loading: true, assignProspectTo: e });
    if (selectedProspects.length) {
      const data = {
        prospectsIds: selectedProspects,
        userId: e
      };
      assignProspect(data).then(res => {
        this.getProspects();
        this.props.getReport(organizationId, campaign.data.campaignId);
      });
      this.setState({ selectedProspects: [] });
    }
  };

  showSnackBar = (message, isError) => {
    this.setState({ showMessage: true, message, error: isError });
    setTimeout(() => {
      this.setState({ showMessage: false, message: "" });
    }, 1500);
  };

  closeCalculator = () => {
    this.setState({ openCalculator: false });
  };

  filterByAssignment = (e, value) => {
    const { showArchivedProspects } = this.state;
    if (showArchivedProspects) {
      this.setState({ showArchivedProspects: false });
    }
    this.setState({ sortby: value });
    if (value === "volunteerProspects") {
      this.setState({ openUserList: true });
    } else if (value === "archivedProspects") {
      this.setState({ showArchivedProspects: true });
    } else {
      const { filterByAssignment } = this.props;
      filterByAssignment(e);
    }
  };

  selectUser = key => {
    const { filterByAssignment } = this.props;
    filterByAssignment({ target: { value: key } });
    this.setState({ openUserList: false });
  };

  closeUserList = () => {
    this.setState({ openUserList: false });
  };

  onDeleteProspectClick = (e, id) => {
    this.setState({ openConfirmation: true, archiveId: id });
  };

  handleConfirmation = isArchive => {
    const { deleteProspect, authUser: { organizationId } } = this.props;
    const { campaign } = this.state;
    this.setState({ openConfirmation: false });
    if (isArchive) {
      this.setState({ loading: true });
      deleteProspect(this.state.archiveId).then(() => {
        this.setState({ archiveId: null });
        this.getProspects();
        this.props.getReport(organizationId, campaign.Id || campaign.campaignId);
      });
    }
  };

  onFilterClick = () => {
    this.setState({ showFilters: true });
  };

  hideFilter = () => {
    this.setState({ showFilters: false });
  };

  scrollEmpty = () => {
    const empty = document.getElementById("empty-wrapper");
    const table = document.getElementById("prospect-table-container");

    table.scrollLeft = empty.scrollLeft;
  };

  render() {
    const { history, applyProspectFilter, onClearFilter } = this.props;
    const {
      details,
      openCalculator,
      total_donation,
      selectedProspects,
      prospectsList,
      currentProspect,
      loading,
      openUserList,
      assignProspectTo,
      showFilters,
      showArchivedProspects,
      campaign,
      tableWidth
    } = this.state;

    return (
      <LoadingOverlay active={loading} spinner text="Loading">
        <Snackbar
          message={this.state.message}
          color={this.state.error ? "danger" : "success"}
          icon={this.state.error ? ErrorIcon : CheckCircleIcon}
          place="tr"
          open={this.state.showMessage}
        />
        <ConfirmationDialog
          dialogtitle="Archive"
          dialogContent="Are you sure you want to archive this person?"
          open={this.state.openConfirmation}
          value={this.state.value}
          handleConfirmation={this.handleConfirmation}
        />
        { this.state.openCredit && <ConfirmationDialog
          dialogtitle="Confirm"
          dialogContent="Are you sure, you want to make donation with credit card?"
          open={true}
          handleConfirmation={this.confirmCredit}
        />}
        <div className="main-prospect-area">
          <div className="prospect-sticky">
            <HeaderComponent
              title1="PROSPECT"
              title2="MANAGER"
              baseColor="#008eb5"
              select={true}
              campaignList={this.props.campaigns}
              campaign={campaign}
              onCampaignChange={this.onCampaignChange}
            />
            <AddProspectSection
              prospectsList={prospectsList}
              currentProspect={currentProspect}
              donation={total_donation}
              details={details}
              onSortChange={this.filterByAssignment}
              sortby={this.state.sortby}
              pospectModal={this.state.pospectModal}
              openProspectModal={this.openProspectModal}
              closeProspectModal={() => this.setState({ pospectModal: false })}
              onFileChange={this.onFileChange}
              onSubmitAndClose={this.payByCredit}
              onManualPaymentClick={this.onManualPaymentClick}
              openUserList={openUserList}
              closeUserList={this.closeUserList}
              selectUser={this.selectUser}
              {...this.props}
            />
            <ProspectTableHeader
              prospects={
                this.props.filteredProspects || this.props.prospects || []
              }
              assignProspectTo={assignProspectTo}
              onAssignProspect={this.onAssignProspect}
              searchItem={this.props.searchItem}
              searchProspect={this.props.searchProspect}
              onFilterClick={this.onFilterClick}
              hideFilter={this.hideFilter}
              rowsPerPage={this.state.rowsPerPage}
              handleChangeRowsPerPage={this.handleChangeRowsPerPage}
              showFilters={showFilters}
              selectedProspects={selectedProspects}
              {...this.props}
            />
            {showFilters && (
              <ProspectFilters
                applyFilter={applyProspectFilter}
                clearFilter={onClearFilter}
              />
            )}
            <div
              id="empty-wrapper"
              className="empty"
              onScroll={this.scrollEmpty}>
              <div className="scroll" style={{ width: tableWidth }} />
            </div>
          </div>
          <div
            className="non-sticky"
            style={{ paddingTop: showFilters ? 410 : 350 }}>
            <ProspectTable
              prospects={
                this.props.filteredProspects || this.props.prospects || []
              }
              rowsPerPage={this.state.rowsPerPage}
              page={this.state.page}
              handleChangePage={(e, page) => this.handleChangePage(e, page)}
              onCheckedboxClick={this.onCheckedboxClick}
              onAllProspects={this.onAllProspects}
              onDeleteProspectClick={this.onDeleteProspectClick}
              showArchivedProspects={showArchivedProspects}
              editProspectModal={this.editProspectModal}
              selectedProspects={selectedProspects}
            />
          </div>
        </div>
        <div className="mobile-prospect-area">
          <MobileView
            {...this.props}
            donation={total_donation}
            details={details}
            applyFilter={applyProspectFilter}
            clearFilter={onClearFilter}
            rowsPerPage={this.state.rowsPerPage}
            page={this.state.page}
            handleChangePage={(e, page) => this.handleChangePage(e, page)}
            prospects={
              this.props.filteredProspects || this.props.prospects || []
            }
            onManualPaymentClick={this.onManualPaymentClick}
            onSubmitAndClose={this.addProspect}
            campaign={campaign}
            onCampaignChange={this.onCampaignChange}
          />
        </div>
        { Object.keys(details).length > 0 && details.mode !== 'e-commerce' &&
          <MobileCalculator
            history={history}
            handleClose={this.closeCalculator}
            open={openCalculator}
            details={details}
          />
        }
      </LoadingOverlay>
    );
  }
}

const mapStateToProps = state => {
  return {
    prospects: state.prospectManagementReducer.prospects,
    prospect: state.prospectManagementReducer.prospect,
    filteredProspects: state.prospectManagementReducer.filteredProspects,
    searchItem: state.prospectManagementReducer.searchItem,
    filteredCampaigns: state.campaignReportReducer.filteredCampaigns,
    campaigns: state.campaignReportReducer.campaigns,
    initialCampaign: state.campaignReportReducer.initialCampaign,
    teams: state.teamManagementReducer.teams,
    authUser: state.userAuthReducer.authUser,
    prospectId: state.prospectManagementReducer.prospectId,
    campaign: state.campaign.get("campaignData"),
    total_donation: state.campaign.get("total_donation"),
    users: state.prospectManagementReducer.users,
    campaignReports: state.campaignReportReducer.campaignReports,
    filteredUsers: state.userManagementReducer.filteredUsers,
    refreshList: state.prospectManagementReducer.refresh
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchProspects,
      searchProspect,
      applyProspectFilter,
      onClearFilter,
      deleteProspect,
      uploadBulkProspects,
      updateProspect,
      addNew,
      fetchCampaigns,
      fetchTeams,
      getCampaign,
      totalDonation,
      filterByAssignment,
      fetch,
      assignProspect,
      getReport,
      postCheckoutInfo,
      updateDonation,
      updateManualDonation,
      setInitial,
      searchUser
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProspectManagement);
