import React, {Component} from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
 
class CampaignSlider extends Component {
  constructor() {
    super();

    this.state = {
      data: [{
        img: '/images/case-study-1.jpg',
        goal: '125,000',
        raised: '157,000',
        featured: ['Raised $32,000 Above Original Goal',
                   'Remodeling Campaign',
                   'New Organization with a Small List of Donors',
                   '3D Model of the New Center Was Designed by Pledje Studio']
      }, {
        img: '/images/case-study-2.jpg',
        goal: '450,000',
        raised: '609,000',
        featured:['3-part Capital Campaign',
                  '$159,000 Raised Above Original Goal',
                  '36 hours']
      }, {
        img: '/images/case-study-3.jpg',
        goal: '125,000',
        raised: '153,644',
        featured: ['Staff Expansion Campaign',
                   '$28,644 Raised Above Original Goal',
                   '33 hours']
      }, {
        img: '/images/case-study-4.jpg',
        goal: '225,000',
        raised: '251,576',
        featured: ['2 BONUS ROUNDS',
                   '$26,576 Raised Above Original Goal',
                   'Annual Budget Campaign']
        
      }, {
        img: '/images/case-study-5.jpg',
        goal: '1000,000',
        raised: '1,105,958',
        featured:['Capital Campaign',
                  'Raised $105,987 Above Original Goal',
                  '2.5 Times Growth from Previous Campaigns with Other Platforms',
                  '3d Virtual of New Building By Pledje Studio',
                  'Over 600 Social Shares']
      }, {
        img: '/images/case-study-6.jpg',
        goal: '76,000',
        raised: '88,581',
        featured:['$18,581 Above Original Goal',
                  'Annual Budget Campaign',
                  '2x Match']
      },{
        img: '/images/case-study-7.jpg',
        goal: '325,000',
        raised: '410,000',
        featured:['Annual Budget Campaign',
                  '3x match',
                  '$80,000 Raised Above Original Goal',
                  'A Unique Method Was Created to Secure Power Donors (Matching Donors)']
      }]
    }
  }

  settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    arrow: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };

  render() {
    const { data } = this.state;
    
    return (
      <Slider className='sliderCaseStudy' {...this.settings}>
        { data.map((item, i) =>
          <div key={i} className="featured_grid">
            <div className="featured_img">
              <img id="cs-m-img" src={item.img} alt='Main Img' />
              <div className="case-study-badge">
                <div className="left" style={{ "background": "url(/images/case_left_img.png) no-repeat center left" }}>Bonus</div>
                <div className="center"><img src="/images/rocket.png" alt="rocket" /></div>
                <div className="right" style={{ "background": "url(/images/case_right_img.png) no-repeat center right" }}>Round</div>
              </div>
            </div>
            <div className="featured_item">
              <ul className="f_item_uline">
                <li></li>
                <li><img src="/images/goal_icon.png" alt='Goal' /> Goal ${item.goal} </li>
                <li><img src="/images/icon-raised.png" alt='Raised' /> Raised ${item.raised}</li>
              </ul>
            </div>
            <div className="featured_desc">
              <ul className="">
                {item.featured.map((featured,i)=>
                  <li><img src="/images/check_icon.png" alt='Check' />{featured}</li>
                )}
              </ul>
            </div>
          </div>
        )}
      </Slider>
    );
  }
}

export default CampaignSlider;