import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as teamActions from '../../redux/modules/team';
import * as campaignActions from '../../redux/modules/campaign';
import { fetchDonations } from '../../redux/modules/TeamLeadership/action-creator';
import { bindActionCreators } from 'redux';
import { Helmet } from "react-helmet";
import Hidden from "@material-ui/core/Hidden";
import { Title } from './Title';
import { Member } from './Member';
import { Donor } from './Donor';
import { Calculator } from '../../components/Calculator/Calculator';
import PreCampaign from '../../components/PreCampaign';
import MobileCalculator from '../../components/Calculator/Responsive/MobileCalculator';
import TeamDetails from '../../components/TeamDetail';
import CampaignItems from "../../components/CampaignItems";
import { Summary } from "../../components/CampaignItems/Summary";
import Error from '../../components/Notification/Error';
import { ProgressLoader } from "../../components/Loader";
import { currencies } from "../../Common/currencies";
import { getExchangeRate } from "../../Common/Exchange";
import './Team.scss';

class Team extends Component {
  constructor() {
    super();

    this.state = {
      error: '',
      notify: false,
      team: {},
      details: {},
      total_donation: 0,
      imageURL: '',
      responsive_calculator: false,
      donors: [],
      team_donation: 0,
      donate: [],
      donationType: "oneTime",
      loadingPercent: 0,
      currency: {
        name: 'United States Dollar (USD)',
        code: 'usd',
        sign: '$'
      }
    }
  
  }

  componentWillMount = () => {
    const { match: { params: { campaign, team } }, onTeamActions, history, onCampaignActions, fetchTopTeams } = this.props;

    if (campaign.success && campaign.data) {
      this.setState({ loadingPercent: 100 });
      const { data } = campaign;
      const currency = data.campaignCurrency ? currencies.find(e => e.code === data.campaignCurrency) : data.currency;
      this.setState({ currency })
    } else {
      onTeamActions.getTeam(campaign, team)
        .then(res => {
          const { data, data: { campaignId, organizationId, teamInfo } } = res.data;
          getExchangeRate(res.data.data.campaignCurrency || res.data.data.currency || 'usd');
          this.setState({ loadingPercent: 100 })
          if (data === 'No Records Found For That Route') {
            this.setState({ error: `No Record Found for ${campaign}!`, notify: true });
            setTimeout(() => {
              history.push('/');
            }, 2000)
          } else {
            onTeamActions.teamDonation(organizationId, campaignId, teamInfo.id);
            onCampaignActions.totalDonation(organizationId, campaignId);
            onCampaignActions.getDonations(organizationId, campaignId);
            onCampaignActions.getItems(organizationId, campaignId);
            fetchTopTeams(organizationId, campaignId, res.data.data.Teams, res.data.data.matchFactor)
            setInterval(() => {
              onTeamActions.teamDonation(organizationId, campaignId, teamInfo.id);
              onCampaignActions.totalDonation(organizationId, campaignId);
              onCampaignActions.getDonations(organizationId, campaignId);
              fetchTopTeams(organizationId, campaignId, res.data.data.Teams, res.data.data.matchFactor)
            }, 10000);
          }
        })
        .catch(err => {
          this.setState({ loadingPercent: 100 })
          this.setState({ error: 'No Record Found!', notify: true });
        });
    }
  }

  componentDidUpdate(oldProps) {
    const { campaign } = this.props;

    if (oldProps.campaign !== campaign) {
      const { data } = campaign;
      const currency = data.campaignCurrency ? currencies.find(e => e.code === data.campaignCurrency) : data.currency;
      this.setState({ currency })
    }
  }

  componentWillReceiveProps(nextProps) {
    const { team: { data }, total_donation, donations, team_donation } = nextProps;

    if (typeof data === 'object') {
      const { teamInfo } = data;
      this.setState({
        team: teamInfo,
        title: data.name,
        video: data.videoURL,
        powerDonars: data.superDonors,
        details: data,
        imageURL: data.mainContent.imageURL
      });
      if (data.status === 'pre-campaign' && new Date() < new Date(data.preCampaignEndDate)) {
        this.setState({ open: true });
      }
    }
    if (team_donation.data) {
      this.setState({ team_donation: team_donation.data.total });
    }
    if (total_donation.data) {
      this.setState({ total_donation: total_donation.data.total });
    }
    if (donations.data) {
      this.setState({ donors: donations.data });
    }
  }

  openCalculator = () => {
    this.setState({ responsive_calculator: true });
  }

  closeCalculator = () => {
    this.setState({ responsive_calculator: false });
  }

  donate = donated => {
    this.setState({ donate: donated });
  };

  checkout = total => {
    const { onCampaignActions, history } = this.props;
    const {
      donate,
      details: { matchFactor },
      donationType,
      currency
    } = this.state;

    if (!total) {
      this.setState({
        error: `Your shopping cart is empty. Please choose items to donate or enter a valid general donation amount to proceed. Thanks You!`,
        notify: true
      });

      return;
    }

    const data = {
      amount: total,
      donation_type: donationType,
      matchFactor,
      selectedItems: donate,
      duration: donationType === "monthly" ? 10 : 10,
      currency,
      cart: true
    };

    onCampaignActions.setDonation(data);
    history.push("/checkout");
  };

  handleChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleCurrency = (c) => {
    this.setState({ currency: c });
  }

  render() {
    const {
      details,
      notify,
      error,
      team,
      open,
      responsive_calculator,
      total_donation,
      team_donation,
      donors,
      loadingPercent,
      donationType,
      imageURL,
      currency,
      donate
    } = this.state;
    const { onCampaignActions, history, donationDetails, items } = this.props;
    return (
      <div className="team_wrapper">
        <ProgressLoader loadingPercent={loadingPercent}>
          <Helmet>
            <meta charSet="utf-8" />
            <title>{team.teamName ? `${details.name} - ${team.teamName}` : ''}</title>
            <link rel="canonical" href={window.location.href} />
          </Helmet>
          <div className="wrapper">
            <div className="content">
              <Title title={details.name} logo={details.organizationLogo} />
              <Member team={team} status={details.status} />
              <Donor details={details} />
              <img className='team-img' src={imageURL} alt='Team' />
              {Object.keys(details).length > 0 &&
                details.mode === "e-commerce" &&
                items.length > 0 ? (
                  <>
                    <CampaignItems
                      handleChange={this.handleChange}
                      donationType={donationType}
                      handleDonate={this.donate}
                      donate={donate}
                      items={items}
                      details={details}
                      onCampaignActions={onCampaignActions}
                      history={history}
                      handleCurrency={this.handleCurrency}
                    />
                    <Hidden mdUp>
                      <Summary
                        donate={donate}
                        details={details}
                        checkout={this.checkout}
                        currency={currency}
                      />
                    </Hidden>
                  </>
                ) : (
                  <img className="camp-img" src={imageURL} alt="Campaign" />
                )}
            </div>
            {Object.keys(details).length > 0 && <TeamDetails donationDetails={donationDetails} details={details} team={team} donation={total_donation} team_donation={team_donation} donors={donors} history={history} />}
          </div>
          <div style={{ clear: 'both' }}></div>
          {Object.keys(details).length > 0 &&
            (details.mode === "e-commerce" ? (
              <Hidden smDown>
                <Summary
                  donate={donate}
                  details={details}
                  checkout={this.checkout}
                  currency={currency}
                />
              </Hidden>
            ) : (
                details.hideCalculator ?
                  new Date() < new Date(details.campaignEndTime) ?
                    <Calculator
                      onCampaignActions={onCampaignActions}
                      history={history}
                      details={details}
                    />
                    :
                    null
                  :
                  <Calculator
                    onCampaignActions={onCampaignActions}
                    history={history}
                    details={details}
                  />
              ))
          }
          {details.mode !== "e-commerce" && (
            details.hideCalculator ?
              new Date() < new Date(details.campaignEndTime) ?
                <div className="mobile_donate" onClick={this.openCalculator}>
                  <p style={{ textAlign: "center" }}>DONATE NOW</p>
                </div>
                :
                null
              :
              <div className="mobile_donate" onClick={this.openCalculator}>
                <p style={{ textAlign: "center" }}>DONATE NOW</p>
              </div>
          )}
          {open && <PreCampaign onCampaignActions={onCampaignActions} teamPage={true} history={history} details={details} />}
          {responsive_calculator && details.mode !== "e-commerce" &&
            <MobileCalculator history={history} handleClose={this.closeCalculator} open={responsive_calculator} details={details} />
          }
          <Error open={notify} message={error} handleClose={this.handleNotification} />
        </ProgressLoader>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    team: state.team.get('team_data'),
    team_donation: state.team.get('team_donation'),
    total_donation: state.campaign.get('total_donation'),
    donations: state.campaign.get('donations'),
    donationDetails: state.teamLeadershipReducer.donationDetails,
    items: state.campaign.get("items")
  }
};

const mapDispatchToProps = dispatch => {
  return {
    onTeamActions: bindActionCreators(teamActions, dispatch),
    onCampaignActions: bindActionCreators(campaignActions, dispatch),
    fetchTopTeams: bindActionCreators(fetchDonations, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Team);
