import React from "react";

export const Comment = (props) => {
  const loc = window.location.protocol + '//'+ window.location.host + '/' + props.details.campaignPage ;
  return (
    <div id="site-fb-comments">
      <h4 className="widget-title">TEll US WHY YOU GAVE!</h4>
      <div className="textwidget">
        <p>Will not post to your wall unless selected</p>
        <span>
          <iframe
            name="f28bfeb2f25dc54"
            height="280px"
            style={{ border: 'none', overflow: 'hidden', width: '100%' }}
            frameBorder={0}
            allowtransparency="true"
            allowFullScreen={true}
            allow="encrypted-media"
            title="fb:comments Facebook Social Plugin"
            src={`https://www.facebook.com/plugins/comments.php?href=${window.location.href.slice(-1) === '/' ? window.location.href : loc +'/'}`}
          />
        </span>
      </div>
    </div>
  );
};
