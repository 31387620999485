import React from 'react';
import Paper from '@material-ui/core/Paper';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';

export const Custom = ({ custom, toggle, handleType, handleValue, currency, handleFocus, handleBlur, focus, customDonation, handleKeyDown, donation_type }) => {
  return (
    <Paper className={custom === 'oneTime' || custom === 'other' ? 'other c-normal' : 'other c-grey'} elevation={custom === 'oneTime' || custom === 'other' ? 15 : 0}>
      <div className="custom-amount">
        <input placeholder={!focus ? '0' : ''} onBlur={handleBlur} onFocus={handleFocus} value={customDonation} onKeyDown={handleKeyDown} onChange={handleValue} />
        <div className='input-label'>
          <label htmlFor="other-amount" className="input_label"><span className='upper'>OTHER</span><br></br><span className='lower'>AMOUNT</span></label>
          <p>{currency.sign}  |</p>
        </div>
        {/* {focus && <label className='placeholder-label'>.00</label>} */}
      </div>
      <div className='type'>
        {toggle && donation_type === "monthly" && <FormControl className='donation-type' component="fieldset">
          <RadioGroup name="donation" value={custom} onChange={(e) => handleType(e)}>
            <FormControlLabel checked={'oneTime' === custom} value='oneTime' control={
              <Radio />
            } label='Charge This Amount Once' />
            {donation_type === 'monthly' && <FormControlLabel checked={'other' === custom} value='other' control={
              <Radio />
            } label='Charge This Amount Monthly' />}
          </RadioGroup>
        </FormControl>}
      </div>
    </Paper >
  );
}