import React from "react";
import PropTypes from "prop-types";
import LoadingOverlay from "react-loading-overlay";
import CampaignHeader from "./CampaignHeader";
import CampaignMiddleSection from "./CampaignMiddleSection";
import CampaignTableHeader from "./CampaignTableHeader";
import EditDonation from "./EditDonation";
import {
  fetchCampaigns,
  searchCampaign,
  getReport,
  filterReports,
  setInitial,
  updateDonation,
  handleCapture
} from "../../redux/modules/CampaignReports/action-creator";
import { getCampaign, totalDonation } from "../../redux/modules/campaign";
import { fetchTeams } from "../../redux/modules/TeamManagement/action-creator";
import CampaignTable from "./CampaignTable";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { getExchangeRate } from "../../Common/Exchange";
import './index.scss';

class CampaignReports extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentProspect: null,
      campaign: "",
      filterByDonor: "allDonors",
      filterByPayment: "allPayments",
      isAscending: true,
      rowsPerPage: 15,
      fields: {
        firstName: true,
        lastName: true,
        email: true,
        donationDate: true,
        alias: true,
        donationAmount: true,
        donationMatched: false,
        paymentType: true,
        teamId: false,
        transactionCostCovered: false,
        transactionCost: false,
        transactionId: true,
        billingPhone: false,
        billingAddress: false,
        dedicationMessage: false,
        status: false,
        raffle: false,
        specialNotes: false,
        items: false,
        state: false,
        city: false,
        zip: false,
        mode: true,
        action: true
      },
      loading: true,
      details: {},
      total_donation: "",
      openEdit: false,
      visibleFields: [],
      tableWidth: ""
    };
    this.handleChangeRowsPerPage = this.handleChangeRowsPerPage.bind(this);
    this.props.fetchCampaigns(this.props.authUser.organizationId);
  }

  componentDidMount() {
    const test = document.getElementById("report-table");
    const tableWidth = test.clientWidth;
    this.setState({ tableWidth });
  }

  componentDidUpdate(oldProps) {
    const newProps = this.props;
    if (oldProps.filteredReports !== newProps.filteredReports) {
      this.filterFields(newProps.campaignReports);
    }

    if (oldProps.initialCampaign !== newProps.initialCampaign) {
      this.setState({ loading: false });
      getExchangeRate(newProps.initialCampaign.campaignCurrency || newProps.initialCampaign.currency || 'usd');
      this.setState({ campaign: newProps.initialCampaign }, () => {
        newProps.getCampaign(this.state.campaign.campaignPage).then(res => {
          newProps.totalDonation(res.data.data.organizationId, res.data.data.campaignId || this.state.campaign.Id);
          this.props.fetchTeams(
            newProps.authUser.organizationId,
            this.state.campaign.Id || this.state.campaign.campaignId
          );
          this.props.getReport(
            newProps.authUser.organizationId,
            this.state.campaign.Id || this.state.campaign.campaignId
          );
        });
      });
    }
  }

  componentWillReceiveProps(nextProps) {
    const {
      campaign: { data },
      total_donation,
      campaignReports
    } = nextProps;

    if (campaignReports.length > 0) {
      this.filterFields(campaignReports);
    }
    if (typeof data === "object") {
      this.setState({ details: data });
    }
    if (total_donation.data) {
      this.setState({ total_donation: total_donation.data.total });
    }
  }

  handleChangeRowsPerPage(event) {
    this.setState({ rowsPerPage: parseInt(event.target.value) });
  }

  handleCheckbox = name => e => {
    const { campaignReports } = this.props;

    e.persist();
    this.setState(
      prevState => ({
        fields: {
          ...prevState.fields,
          [name]: e.target.checked
        }
      }),
      () => {
        this.filterFields(campaignReports);
      }
    );
  };

  filterReports = e => {
    const { filterByPayment } = this.state;
    e.persist();
    this.setState({ filterByDonor: e.target.value });
    this.props.filterReports({
      donor: e.target.value,
      payment: filterByPayment
    });
  };

  onFilterByPayment = e => {
    const { filterByDonor } = this.state;
    e.persist();
    this.setState({ filterByPayment: e.target.value });
    this.props.filterReports({ payment: e.target.value, donor: filterByDonor });
  };

  onFormInputValueChange = e => {
    const { setInitial } = this.props;
    const { value } = e.target;
    setInitial(value);
  };

  onClose = () => {
    this.setState({ openEdit: false });
  };

  onEditDonation = item => {
    this.setState({ openEdit: true, currentProspect: item });
  };

  onSubmitAndClose = data => {
    const { updateDonation, getReport, authUser } = this.props;
    const { campaign } = this.state;
    data.alias = !data.displayName ? data.alias : "Anonymous";
    data.displayName = !data.displayName;
    this.setState({ openEdit: false });
    updateDonation(data.id, data).then(res => {
      getReport(authUser.organizationId, campaign.Id || campaign.campaignId);
    });
  };

  filterFields = campaignReports => {
    const { fields } = this.state;
    const { filteredReports } = this.props;
    const reports = filteredReports ? filteredReports : campaignReports;

    let visibleArray = [];
    reports.forEach(items => {
      const visibleObj = {};
      for (const i in items) {
        if (fields[i] === true) {
          visibleObj[i] = items[i];
        }
      }
      if (Object.keys(visibleObj).length > 0) {
        visibleArray.push(visibleObj);
      } else {
        visibleArray = [];
      }
    });
    this.setState({ visibleFields: visibleArray }, () => {
      const test = document.getElementById("report-table");
      const tableWidth = test.clientWidth;
      this.setState({ tableWidth });
    });
  };

  scrollEmpty = () => {
    const empty = document.getElementById("empty-wrapper");
    const table = document.getElementById("campaign-report-table");

    table.scrollLeft = empty.scrollLeft;
  };

  captureDonation = () => {
    const { campaign: { organizationId, campaignId, Id } } = this.state;
    const { handleCapture } = this.props;

    handleCapture(organizationId, Id || campaignId);
  }

  render() {
    const {
      fields,
      total_donation,
      details,
      openEdit,
      currentProspect,
      visibleFields,
      loading,
      tableWidth
    } = this.state;
    const { teams, filteredReports, campaignReports, authUser } = this.props;

    return (
      authUser.role !== 'volunteer' ?
      <LoadingOverlay active={loading} spinner text="Loading">
        <CampaignHeader
          onFormInputValueChange={this.onFormInputValueChange}
          campaignList={this.props.campaigns}
          campaign={this.state.campaign}
        />
        <CampaignMiddleSection
          campaignReports={filteredReports || campaignReports || []}
          visibleFields={visibleFields}
          completeReport={campaignReports}
          fields={fields}
          handleCheckbox={this.handleCheckbox}
          filterByPayment={this.state.filterByPayment}
          filterByDonor={this.state.filterByDonor}
          onFilterByPayment={this.onFilterByPayment}
          onCampaignSortbyRadioChange={this.filterReports}
          donation={total_donation}
          details={details}
          captureDonation={this.captureDonation}
        />
        <CampaignTableHeader
          searchItem={this.props.searchItem}
          searchCampaign={e =>
            this.props.searchCampaign(e.target.value, fields)
          }
          rowsPerPage={this.state.rowsPerPage}
          handleChangeRowsPerPage={event => this.handleChangeRowsPerPage(event)}
        />
        <div
          id="empty-wrapper"
          className="empty"
          onScroll={this.scrollEmpty}>
          <div className="scroll" style={{ width: tableWidth }} />
        </div>
        <CampaignTable
          fields={fields}
          campaignReports={filteredReports || campaignReports || []}
          rowsPerPage={this.state.rowsPerPage}
          onEditDonation={this.onEditDonation}
        />
        {openEdit && (
          <EditDonation
            currentProspect={currentProspect}
            visible={openEdit}
            campaignTeams={teams}
            handleClose={this.onClose}
            details={details}
            onSubmitAndClose={this.onSubmitAndClose}
          />
        )}
      </LoadingOverlay> 
      :
      null
    );
  }
}

CampaignReports.propTypes = {
  classes: PropTypes.object.isRequired
};

const mapStateToProps = state => {
  return {
    campaignReports: state.campaignReportReducer.campaignReports || [],
    filteredReports: state.campaignReportReducer.filteredReports,
    searchItem: state.campaignReportReducer.searchItem,
    campaigns: state.campaignReportReducer.campaigns,
    authUser: state.userAuthReducer.authUser,
    initialCampaign: state.campaignReportReducer.initialCampaign,
    campaign: state.campaign.get("campaignData"),
    total_donation: state.campaign.get("total_donation"),
    teams: state.teamManagementReducer.teams
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchCampaigns,
      searchCampaign,
      getReport,
      filterReports,
      getCampaign,
      totalDonation,
      setInitial,
      fetchTeams,
      updateDonation,
      handleCapture
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CampaignReports);
