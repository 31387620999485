import React from 'react';

export const Total = ({ donate_amount, currency }) => {

  return (
    <div className='total-donation'>
      <div className='total-donate-text'>
        YOUR TOTAL DONATION
      </div>
      <div className='total-donate-amount'>
        {currency.sign}{donate_amount}
      </div>
    </div>
  );
}
