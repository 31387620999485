import React from "react";

export const PowerDonars = ({ title, powerDonars, details, donation }) => {
  const timeRemaining = new Date(details.campaignEndTime) - new Date();
  const bonus = donation > parseInt(details.goalAmount) && timeRemaining >= 0;

  return (
    <div className="project-content">
      <div className="project-inner-content">
        <div className="super_pop_div">
          {powerDonars &&
            powerDonars.map((donor, i) => (
              donor.name &&
              <div key={i} className="super_donr">
                <img width="150" height="150" src={`${donor.icon}`} alt="profile" />
                <p className="donar_name"> Power Donor </p>
                <p className="donar_descrip"> {donor.name}</p>
              </div>
            ))}
        </div>
        {bonus && (
          <div className="bouns_super_goal_new">
            BONUS <i />ROUND
          </div>
        )}
      </div>
    </div>
  );
};
