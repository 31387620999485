import React, { Component } from 'react';
import CircularProgressbar from 'react-circular-progressbar';
import './TeamDetails.scss';

let countdown = null;

class Goals extends Component {
  constructor() {
    super();

    this.state = {
      remaining: 0,
      currentTime: 0,
      rem: { hours: 0, minutes: 0, seconds: 0 }
    }
  }

  componentWillMount() {
    this.setTimer(this.props);
  }

  componentDidMount() {
    countdown = setInterval(() => {
      if(this.check_zero()) {
        this.setState(prevState => ({
          currentTime: prevState.currentTime - 1000,
          rem: this.getRemaining(prevState.currentTime - 1000)
        }));
      } else {
        clearInterval(countdown)
      }
    }, 1000);
  }

  componentWillReceiveProps(nextProps) {
    this.setTimer(nextProps);
  }

  componentWillUnmount() {
    clearInterval(countdown);
  }

  setTimer = (props) => {
    const { startTime, endTime, bonus, bonusEnd } = props;
    const endingDate = bonus ? bonusEnd : endTime;

    this.setState({
      remaining: (new Date(endingDate || 0)) - new Date(startTime || 0),
      currentTime: (new Date(endingDate || 0)) - new Date(),
      rem: this.getRemaining(new Date(endingDate || 0) - new Date())
    })
  }

  getRemaining = (millisec) => {
    if(millisec > 0) {
      let seconds = (millisec / 1000).toFixed(0);
      let minutes = Math.floor(seconds / 60);
      let hours = "";
      if (minutes > 59) {
          hours = Math.floor(minutes / 60);
          minutes = minutes - (hours * 60);
      }
  
      seconds = Math.floor(seconds % 60);
      if (hours !== "") {
        return {
          hours,
          minutes,
          seconds
        };
      }
      return {
        hours: 0,
        minutes,
        seconds
      }
    }

    return {
      hours: 0, minutes: 0, seconds: 0
    }
  }

  check_zero = () => {
    const { rem: { hours, minutes, seconds } } = this.state;
    if(!seconds && !minutes && !hours) {
      return false;
    }
    return true
  }

  render() {
    const { remaining, currentTime, rem: { hours, minutes, seconds } } = this.state;
    const { isMobile } = this.props;
    return(
      isMobile?
      <div className="responsive-timer">
        <i className="fa fa-clock-o" aria-hidden="true"></i>
        <div className='timer'>
          <div className='time'>
            <span>{hours < 10 ? `0${hours}` : hours}</span>
            <span>:</span>
            <span>{minutes < 10 ? `0${minutes}` : minutes}</span>
            <span>:</span>
            <span>{seconds < 10 ? `0${seconds}` : seconds}</span>
          </div>
          <div className='labels'>
            <span>Hrs</span>
            <span>Min</span>
            <span>Sec</span>
          </div>
        </div>
      </div>
      :
      <section className="team-goals">
        <div className="time-remaining">
          <img src='/images/clock.png' alt='Clock' />
          <CircularProgressbar
            percentage={currentTime/remaining * 100}
          />
          <div className="t-timer">
            <div className="hr">{hours < 10 ? `0${hours}` : hours}</div>
            <div className="min">{minutes < 10 ? `0${minutes}` : minutes}</div>
            <div className="sec">{seconds < 10 ? `0${seconds}` : seconds}</div>
          </div>
          <div className="t-labels">
            <div className="hr">H</div>
            <div className="min">M</div>
            <div className="sec">SECONDS</div>
          </div>
        </div>
      </section>
    )
  }
}

export { Goals };
