import {handleActions} from 'redux-actions';
import * as action from './action';
var request = require('request');
const loggedInfo = localStorage.getItem('userInfo') ? JSON.parse(localStorage.getItem('userInfo')) : null;

const initialState ={
    authUser: loggedInfo || {},
    isFetching : false,
}

const handlers ={
    [action.FETCHING] : (state,action) => ({...state , isFetching : true}),
    [action.SUCCESS] : (state,action) => ({...state , isFetching : false}),
    [action.ERROR] : (state,action) => ({...state , isFetching : false}),
    [action.USER_INFO] :  (state,action) => ({...state,authUser : action.payload})
}

export default handleActions(handlers,initialState)