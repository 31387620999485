import React from 'react';
import { AppBar, InputBase, NativeSelect } from '@material-ui/core';
import './UserTableHeader.scss';

function UserTableHeader({ searchItem, searchUser, rowsPerPage, handleChangeRowsPerPage }) {
  return (
    <AppBar style={{backgroundColor:'#006400'}} position="static" id='user-table-header'>
      <div className='search'>
        <InputBase className='input' placeholder="Search" value={searchItem} onChange={searchUser} /> 
        <i className="fa fa-search" aria-hidden="true"></i>
      </div>
      <div className='table-view'>
        Show
        <NativeSelect 
          className='select'
          disableUnderline
          value={rowsPerPage}
          onChange={handleChangeRowsPerPage}
          name="viewTableRows"
        >
          <option value="15" className = "view-option">15</option>
          <option value="20" className = "view-option">20</option>
          <option value="25" className = "view-option">25</option>
        </NativeSelect>
      </div>
    </AppBar>
  );
}

export default UserTableHeader;
