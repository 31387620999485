import React, { Component } from "react";
import { getSign } from "../../Common/getSign";
import "./Donors.scss";

class Donars extends Component {
  constructor() {
    super();

    this.state = {
      filteredDonors: [],
      show_btn: false,
      length: 0,
      expanded: false
    };
  }

  componentWillMount() {
    const { donors } = this.props;

    this.setState({
      filteredDonors: (donors && donors.filter((donor, i) => i < 4)) || [],
      show_btn: donors && donors.length > 4 ? true : false,
      length: donors && donors.length
    });
  }

  componentWillReceiveProps(nextProps) {
    const { expanded } = this.state;
    const { donors } = nextProps;

    this.setState({
      filteredDonors: !expanded
        ? (donors && donors.filter((donor, i) => i < 4)) || []
        : donors,
      show_btn: donors && (donors.length > 4 && !expanded) ? true : false,
      length: donors && donors.length
    });
  }

  expand = () => {
    const { donors } = this.props;

    this.setState({ filteredDonors: donors, show_btn: false, expanded: true });
  };

  render() {
    const { filteredDonors, show_btn, length } = this.state;
    const { team, campaignCurrency } = this.props;
    console.log('filter',filteredDonors);

    return (
      <section className="donars_section">
        <div className="donar_header">
          <h2 className="left_don">{team ? 'Teams' : 'Donors'}</h2>
          <h2 className="right_don">Donations Matched</h2>
        </div>
        <div className="donor-listing">
          {filteredDonors.map((donor, i) => (
            <div key={i} className="donar_block">
              <img
                src="/images/donar1.png"
                className="donar_heart"
                alt="Donor"
              />
              <div className="donor_info">
                <p className="alias">
                  {team ? donor.teamName : donor.displayName ? donor.alias || donor.name : donor.name}
                </p>
                <p className="message">{donor.message}</p>
              </div>
              <label className="amount">{getSign(donor.currency || campaignCurrency)}{team ? donor.totalChargedAmount : donor.amount}</label>
            </div>
          ))}
        </div>
        {/* <div className="donar_block">
          <img src="/images/donar2.png" className="donar_heart"/>
          <p>Howard & Judy Raykoff</p>
          <h5>$	720</h5>
          <img src="/images/trophy-small.png" className="trophy"/>
          <div style={{"clear": "both"}}></div>
        </div> */}
        {show_btn && (
          <button className="sidebar-blue-btn" onClick={this.expand}>
            See All {length} Donors
          </button>
        )}
      </section>
    );
  }
}

export { Donars };
