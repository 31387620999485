import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  Grid,
  Button,
  FormControl,
  FormControlLabel,
  NativeSelect,
  FormGroup,
  Checkbox
} from "@material-ui/core";
import ErrorIcon from "@material-ui/icons/Error";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import LoadingOverlay from "react-loading-overlay";
import { getCampaignById } from "../../redux/modules/campaign";
import { add, setVolunteer } from "../../redux/modules/Users/action-creator";
import Snackbar from "../../components/Snackbar/Snackbar";
import "./AddVolunteer.scss";

const hours = ["0", "1", "2", "3", "4"];
const initialState = {
  firstName: "",
  lastName: "",
  email: "",
  password: "",
  phone: ""
};
const initialInfo = {
  day1Morning: "0",
  day1Afternoon: "0",
  day1Evening: "0",
  day2Morning: "0",
  day2Afternoon: "0",
  day2Evening: "0",
  laptop: "false"
};

class AddVolunteer extends Component {
  constructor() {
    super();

    this.state = {
      details: initialState,
      volunteer_info: initialInfo,
      repeatPassword: "",
      passMatch: true,
      disableBtn: false,
      message: "",
      error: false,
      showMessage: false,
      pageName: "",
      loading: false,
      socialMedia: ["facebook"]
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    const {
      user,
      edit,
      history,
      getCampaignById,
      match: {
        params: { campaign, organization }
      }
    } = this.props;
    if (edit) {
      this.setState({
        details: {
          firstName: user.firstName,
          lastName: user.lastName,
          email: user.email,
          phone: user.phone,
          userId: user.userId
        },
        volunteer_info: {
          day1Morning: user.day1Morning,
          day1Afternoon: user.day1Afternoon,
          day1Evening: user.day1Evening,
          day2Morning: user.day2Morning,
          day2Afternoon: user.day2Afternoon,
          day2Evening: user.day2Evening,
          laptop: user.laptop
        },
        socialMedia: user.socialMedia.split(",")
      });
    }

    if (!history.location.state) {
      getCampaignById(organization, campaign);
    }
  }

  componentDidUpdate(oldProps) {
    const { campaign } = this.props;

    if (oldProps.campaign !== campaign) {
      this.setState({ pageName: `/${campaign.data.campaignPage}` });
    }
  }

  handleChange = e => {
    e.persist();
    this.setState(prevState => ({
      details: {
        ...prevState.details,
        [e.target.name]: e.target.value
      }
    }));
  };

  changeVolunteerInfo = e => {
    e.persist();
    this.setState(prevState => ({
      volunteer_info: {
        ...prevState.volunteer_info,
        [e.target.name]: e.target.value
      }
    }));
  };

  handleCheckbox = e => {
    let sm = [...this.state.socialMedia];
    if (e.target.checked) {
      sm = [...sm, e.target.value];
      this.setState({
        [e.target.name]: sm
      });
    } else {
      const index = sm.indexOf(e.target.value);
      sm.splice(index, 1);
      this.setState({
        [e.target.name]: sm
      });
    }
  };

  handlePasswordChange = e => {
    const {
      details: { password }
    } = this.state;
    this.setState({
      [e.target.name]: e.target.value,
      passMatch: password === e.target.value ? true : false
    });
  };

  handleSubmit = () => {
    const {
      match,
      addUser,
      setVolunteer,
      history,
      handleAddUserFormClose,
      organizationId,
      edit,
      updateUser
    } = this.props;
    const { details, volunteer_info, pageName, socialMedia } = this.state;
    volunteer_info.socialMedia = socialMedia;
    const data = {
      user: {
        ...details,
        volunteer_info,
        role: "volunteer",
        organizationId: history ? match.params.organization : organizationId,
        status: "Inactive"
      }
    };

    setVolunteer(details);
    this.setState({ disableBtn: true, loading: true });
    if (!edit) {
      addUser(data)
        .then(() => {
          this.showSnackBar(
            "You have been signed up as volunteer successfully!",
            false
          );
          if (history) {
            history.push(
              `/volunteer-confirmation/${match.params.organization}/${
              match.params.campaign
              }`,
              {
                name: history.location.state
                  ? history.location.state.name
                  : pageName,
                //   campaignName: this.props.campaign.data.name
              }
            );
          } else {
            handleAddUserFormClose();
          }
          this.setState({
            details: initialState,
            disableBtn: false,
            loading: false
          });
        })
        .catch(err => {
          this.setState({ disableBtn: false, loading: false });

          this.showSnackBar(
            err.response.data.error.message
              ? err.response.data.error.message
              : err.response.data.error,
            true
          );
        });
    } else {
      updateUser(data);
    }
  };

  showSnackBar = (message, isError) => {
    this.setState({ showMessage: true, message, error: isError });
    setTimeout(() => {
      this.setState({ showMessage: false, message: "" });
    }, 4000);
  };

  handleBlur = e => {
    this.refs[e.target.name].validate(e.target.value, true);
  };

  handleCancel = () => {
    const { history, handleAddUserFormClose } = this.props;
    if (history) {
      history.goBack();
    } else {
      handleAddUserFormClose();
    }
  };

  handleError = () => {
    this.showSnackBar(
      "Please Fill in the marked fields with correct data",
      true
    );
  };

  render() {
    const {
      details: { firstName, lastName, email, password, phone },
      volunteer_info: {
        day1Morning,
        day1Afternoon,
        day1Evening,
        day2Morning,
        day2Afternoon,
        day2Evening,
        laptop
      },
      repeatPassword,
      passMatch,
      disableBtn,
      loading,
      socialMedia
    } = this.state;
    const { edit } = this.props;

    return (
      <LoadingOverlay active={loading} spinner>
        <div className="volunteer-form">
          <div className="header">
            <h3>SIGN UP AS A VOLUNTEER</h3>
          </div>
          <Grid container>
            <Snackbar
              message={this.state.message}
              color={this.state.error ? "danger" : "success"}
              icon={this.state.error ? ErrorIcon : CheckCircleIcon}
              place="tr"
              open={this.state.showMessage}
            />
            <ValidatorForm
              className="add-volunteer"
              ref="form"
              onSubmit={this.handleSubmit}
              onError={this.handleError}
              instantValidate={true}
            >
              <div className="title">
                <h2>Please answer the following questions to get started</h2>
              </div>
              <Grid item container xs={12}>
                <Grid item sm={6} xs={12}>
                  <div className="user-info">
                    <label className="input-label">First Name</label>
                    <TextValidator
                      autoComplete={"nope"}
                      validators={["required"]}
                      errorMessages={["This field is required"]}
                      ref="firstName"
                      name="firstName"
                      value={firstName}
                      className="input"
                      onChange={this.handleChange}
                      onBlur={this.handleBlur}
                    />
                  </div>
                </Grid>
                <Grid item sm={6} xs={12}>
                  <div className="user-info">
                    <label className="input-label">Last Name</label>
                    <TextValidator
                      autoComplete={"nope"}
                      validators={["required"]}
                      errorMessages={["This field is required"]}
                      ref="lastName"
                      name="lastName"
                      value={lastName}
                      className="input"
                      onChange={this.handleChange}
                      onBlur={this.handleBlur}
                    />
                  </div>
                </Grid>
              </Grid>
              <Grid item container xs={12}>
                <Grid item sm={6} xs={12}>
                  <div className="user-info">
                    <label className="input-label">Email</label>
                    <TextValidator
                      autoComplete={"nope"}
                      validators={["required", "isEmail"]}
                      errorMessages={[
                        "This field is required",
                        "Invalid email address"
                      ]}
                      ref="email"
                      name="email"
                      value={email}
                      className="input"
                      onChange={this.handleChange}
                      onBlur={this.handleBlur}
                    />
                  </div>
                </Grid>
                <Grid item sm={6} xs={12}>
                  <div className="user-info">
                    <label className="input-label">Mobile Number</label>
                    <TextValidator
                      inputProps={{
                        autocomplete: "new-password",
                        form: {
                          autocomplete: "off"
                        }
                      }}
                      validators={["required"]}
                      errorMessages={["This field is required"]}
                      ref="phone"
                      name="phone"
                      value={phone}
                      className="input"
                      onChange={this.handleChange}
                      onBlur={this.handleBlur}
                    />
                  </div>
                </Grid>
              </Grid>
              {!edit && (
                <Grid item container xs={12}>
                  <Grid item sm={6} xs={12}>
                    <div className="user-info">
                      <label className="input-label">Choose a Password</label>
                      <TextValidator
                        validators={["required"]}
                        errorMessages={["This field is required"]}
                        ref="password"
                        name="password"
                        value={password}
                        type="password"
                        className="input"
                        onChange={this.handleChange}
                        onBlur={this.handleBlur}
                      />
                    </div>
                  </Grid>
                  <Grid item sm={6} xs={12}>
                    <div className="user-info">
                      <label className="input-label">
                        Your Chosen Password
                      </label>
                      <div className="c-pass">
                        <TextValidator
                          validators={["required"]}
                          errorMessages={["This field is required"]}
                          name="repeatPassword"
                          value={repeatPassword}
                          type="password"
                          className="input"
                          onChange={this.handlePasswordChange}
                        />
                        {!passMatch && (
                          <p style={{ color: "red", fontSize: 11 }}>
                            Password does not match
                          </p>
                        )}
                      </div>
                    </div>
                  </Grid>
                </Grid>
              )}
              <p>
                Which of the following social media hubs do you have access to
                and use (this will help us spread the news about the campaign
                faster and more efficiently)
              </p>
              <div className="user-info">
                <FormControl component="fieldset">
                  <FormGroup row>
                    <FormControlLabel
                      control={
                        <Checkbox
                          name="socialMedia"
                          color="default"
                          checked={socialMedia.indexOf("facebook") > -1}
                          onChange={this.handleCheckbox}
                          value="facebook"
                        />
                      }
                      label="Facebook"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          name="socialMedia"
                          color="default"
                          checked={socialMedia.indexOf("instagram") > -1}
                          onChange={this.handleCheckbox}
                          value="instagram"
                        />
                      }
                      label="Instagram"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          name="socialMedia"
                          color="default"
                          checked={socialMedia.indexOf("whatsapp") > -1}
                          onChange={this.handleCheckbox}
                          value="whatsapp"
                        />
                      }
                      label="Whatsapp"
                    />
                  </FormGroup>
                </FormControl>
              </div>
              <p>
                Approximate hours you may be able to spend at the campaign room?
                (Please note that childcare/refreshments/lunch/dinner will be
                available in the campaign headquarters)
              </p>
              <Grid item container xs={12}>
                <Grid item sm={4} xs={12}>
                  <div className="user-info hour-info">
                    <label>[Day 1] Morning</label>
                    <NativeSelect
                      className="select"
                      onChange={this.changeVolunteerInfo}
                      name="day1Morning"
                      value={day1Morning}
                      disableUnderline={true}
                    >
                      {hours.map(h => (
                        <option key={h} value={h}>
                          {h}
                        </option>
                      ))}
                    </NativeSelect>
                  </div>
                </Grid>
                <Grid item sm={4} xs={12}>
                  <div className="user-info hour-info">
                    <label>[Day 1] Afternoon</label>
                    <NativeSelect
                      className="select"
                      onChange={this.changeVolunteerInfo}
                      name="day1Afternoon"
                      value={day1Afternoon}
                      disableUnderline={true}
                    >
                      {hours.map(h => (
                        <option key={h} value={h}>
                          {h}
                        </option>
                      ))}
                    </NativeSelect>
                  </div>
                </Grid>
                <Grid item sm={4} xs={12}>
                  <div className="user-info hour-info">
                    <label>[Day 1] Evening</label>
                    <NativeSelect
                      className="select"
                      onChange={this.changeVolunteerInfo}
                      name="day1Evening"
                      value={day1Evening}
                      disableUnderline={true}
                    >
                      {hours.map(h => (
                        <option key={h} value={h}>
                          {h}
                        </option>
                      ))}
                    </NativeSelect>
                  </div>
                </Grid>
              </Grid>
              <Grid item container xs={12}>
                <Grid item sm={4} xs={12}>
                  <div className="user-info hour-info">
                    <label>[Day 2] Morning</label>
                    <NativeSelect
                      className="select"
                      onChange={this.changeVolunteerInfo}
                      name="day2Morning"
                      value={day2Morning}
                      disableUnderline={true}
                    >
                      {hours.map(h => (
                        <option key={h} value={h}>
                          {h}
                        </option>
                      ))}
                    </NativeSelect>
                  </div>
                </Grid>
                <Grid item sm={4} xs={12}>
                  <div className="user-info hour-info">
                    <label>[Day 2] Afternoon</label>
                    <NativeSelect
                      className="select"
                      onChange={this.changeVolunteerInfo}
                      name="day2Afternoon"
                      value={day2Afternoon}
                      disableUnderline={true}
                    >
                      {hours.map(h => (
                        <option key={h} value={h}>
                          {h}
                        </option>
                      ))}
                    </NativeSelect>
                  </div>
                </Grid>
                <Grid item sm={4} xs={12}>
                  <div className="user-info hour-info">
                    <label>[Day 2] Evening</label>
                    <NativeSelect
                      className="select"
                      onChange={this.changeVolunteerInfo}
                      name="day2Evening"
                      value={day2Evening}
                      disableUnderline={true}
                    >
                      {hours.map(h => (
                        <option key={h} value={h}>
                          {h}
                        </option>
                      ))}
                    </NativeSelect>
                  </div>
                </Grid>
              </Grid>
              <p>
                If you are computer savvy, you may also assist us from the
                comfort of your home/office through a cloud platform provided by
                Pledje.com. This is a simple App and training can be provided.
              </p>
              <Grid item sm={4} xs={12}>
                <div className="user-info">
                  <label>Do you own a tablet/laptop?</label>
                  <NativeSelect
                    className="select"
                    onChange={this.changeVolunteerInfo}
                    name="laptop"
                    defaultValue={laptop}
                    disableUnderline={true}
                  >
                    <option value={true}>Yes</option>
                    <option value={false}>No</option>
                  </NativeSelect>
                </div>
              </Grid>
              <div className="btn-container">
                <Button className="cancel-btn" onClick={this.handleCancel}>
                  Cancel
                </Button>
                {edit ? (
                  <Button
                    disabled={disableBtn}
                    type="submit"
                    className="submit-btn"
                  >
                    Update
                  </Button>
                ) : (
                    <Button
                      disabled={disableBtn}
                      type="submit"
                      className="submit-btn"
                    >
                      Submit
                  </Button>
                  )}
              </div>
            </ValidatorForm>
          </Grid>
        </div>
      </LoadingOverlay>
    );
  }
}

const mapStateToProps = state => {
  return {
    campaign: state.campaign.get("campaignData")
  };
};

const mapDispatchToProps = dispatch => {
  return {
    addUser: bindActionCreators(add, dispatch),
    setVolunteer: bindActionCreators(setVolunteer, dispatch),
    getCampaignById: bindActionCreators(getCampaignById, dispatch)
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AddVolunteer);
