import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import Casestudy from "./Casestudy";
import Videobox from "./Videobox";
import Whypledje from "./Whypledje";
import Specialoffer from "./Specialoffer";
import Heroimage from "./Heroimage";
import FreedomEvent from "./FreedomEvent";
import * as campaignActions from "../../redux/modules/campaign";

import "./Home.scss";

class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true
    };
  }

  componentWillMount() {
    this.props.onCampaignActions
      .fetchFeatureCampaigns();
  }

  render() {
    const { featureCampaigns, onCampaignActions } = this.props;

    return (
      <>
        { featureCampaigns.map(item =>
            <FreedomEvent activeCampaign={item} totalDonation={onCampaignActions.totalDonation} />
        )}

        <Heroimage />

        <Videobox />

        <Whypledje />

        {/* <Specialoffer /> */}

        <Casestudy />
      </>
    );
  }
}

const mapStateToProps = state => {
  return {
    featureCampaigns: state.campaign.get("featureCampaigns")
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onCampaignActions: bindActionCreators(campaignActions, dispatch)
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Home);
