var statuses = [
  {'status': 'Clear','color': '#fff'},
  {'status' : 'Not Available' ,'color' : '#208FFF'},
  {'status' : 'Call Later' , 'color' : '#5867dd' },
  {'status' : 'Not Interested' , 'color' : '#f7b727' },
  {'status' : 'Donation Received' , 'color' : '#61c0a3' },
  {'status' : 'See Notes' , 'color' : '#e9566b' },
  {'status' : 'Left Voicemail' , 'color' : '#e76ab4' },
  { 'status': 'Wrong Number' , 'color': '#513c82' }
]

export const getStatuses = () => {
    return statuses
}

export const getStatusColor = (status) =>{
  switch (status){
    case 'Clear' : return 'none' 
    case 'Not Available' : return '#208FFF' 
    case 'Call Later' : return '#5867dd' 
    case 'Not Interested' : return '#f7b727' 
    case 'Donation Received' : return '#61c0a3' 
    case 'See Notes' : return '#e9566b' 
    case 'Left Voicemail' : return '#e76ab4' 
    case 'Wrong Number' : return '#513c82' 
    default:return 'black'
  }
}

export const getDonationMethods = [
  { method: 'check', name: 'Check' },
  { method: 'credit card', name: 'Credit Card' },
  { method: 'cash', name: 'Cash' }
]
