import { BASE_URL } from "../src/apis/backEnd.apis";

// var config = {
//   apiKey: "AIzaSyAMhg5rn-H9w-hjafyE-pf5csLzf6d0bRY",
//   authDomain: "pledjeapi-prod.firebaseapp.com",
//   databaseURL: "https://pledjeapi-prod.firebaseio.com",
//   projectId: "pledjeapi-prod",
//   storageBucket: "pledjeapi-prod.appspot.com",
//   apiAccessKey:
//     "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJPbmxpbmUgSldUIEJ1aWxkZXIiLCJpYXQiOjE1NTIwOTYwNDAsImV4cCI6MTU4MzYzMjA0MCwiYXVkIjoid3d3LmV4YW1wbGUuY29tIiwic3ViIjoianJvY2tldEBleGFtcGxlLmNvbSIsIkdpdmVuTmFtZSI6IkpvaG5ueSIsIlN1cm5hbWUiOiJSb2NrZXQiLCJFbWFpbCI6Impyb2NrZXRAZXhhbXBsZS5jb20iLCJSb2xlIjpbIk1hbmFnZXIiLCJQcm9qZWN0IEFkbWluaXN0cmF0b3IiXX0.WA0k20-mDYAMAcoVlV-0g-vqmvNJWUjdbC1d9QigqzI",
//   messagingSenderId: "20201363512"
// };

var config = {
  apiKey: "AIzaSyAMhg5rn-H9w-hjafyE-pf5csLzf6d0bRY",
  //authDomain: "pledjeapi-prod.firebaseapp.com",
  authDomain: BASE_URL,
  //databaseURL: "https://pledjeapi-prod.firebaseio.com",
  databaseURL: "https://test-ef716.firebaseio.com",
  //projectId: "pledjeapi-prod",
  projectId: "test-ef716",
  //storageBucket: "pledjeapi-prod.appspot.com",
  storageBucket: "test-ef716.appspot.com",
  messagingSenderId: "20201363512",
  apiAccessKey:
    "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJPbmxpbmUgSldUIEJ1aWxkZXIiLCJpYXQiOjE1NTIwOTYwNDAsImV4cCI6MTU4MzYzMjA0MCwiYXVkIjoid3d3LmV4YW1wbGUuY29tIiwic3ViIjoianJvY2tldEBleGFtcGxlLmNvbSIsIkdpdmVuTmFtZSI6IkpvaG5ueSIsIlN1cm5hbWUiOiJSb2NrZXQiLCJFbWFpbCI6Impyb2NrZXRAZXhhbXBsZS5jb20iLCJSb2xlIjpbIk1hbmFnZXIiLCJQcm9qZWN0IEFkbWluaXN0cmF0b3IiXX0.WA0k20-mDYAMAcoVlV-0g-vqmvNJWUjdbC1d9QigqzI"
};

export { config };
