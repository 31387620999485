import React, { Component } from "react";
import Countdown from "react-countdown-now";
import { NumberFormat } from "../../Common/NumberFormat";
import { currencies } from "../../Common/currencies";

import "./Casestudy.scss";

export default class FreedomEvent extends Component {
  constructor() {
    super();
    this.state = {
      raised: localStorage.getItem('raised') || 0,
      sign: '$'
    };
  }

  componentDidMount() {
    const { totalDonation, activeCampaign, activeCampaign: { campaignCurrency } } = this.props;

    const s = currencies.find(e => e.code === campaignCurrency);
    this.setState({ sign: s ? s.sign : '$' });
    totalDonation(activeCampaign.organizationId, activeCampaign.Id || activeCampaign.campaignId)
      .then(res => {
        localStorage.setItem('raised', res.data.total);
        this.setState({
          raised: res.data.total || 0
        })
      });
  }
  
  render() {
    const { raised, sign } = this.state;

    return (
      this.props.activeCampaign && (
        <div>
          <section className="freedom_event_section">
            <div className="container">
              <div className="row">
                <div className="col-md-12">
                  <div className="mybar">
                    <div className="freedom_rise_text">
                      <h2>
                        {this.props.activeCampaign.name
                          ? this.props.activeCampaign.name.toUpperCase()
                          : ""}{" "}
                      </h2>
                    </div>
                    <div className="countdown_timer">
                      <div id="countdown" className="counter">
                        <Countdown
                          daysInHours={true}
                          date={
                            this.props.activeCampaign.campaignEndTime
                              ? new Date(
                                this.props.activeCampaign.campaignEndTime
                              ).valueOf()
                              : 0
                          }
                        />
                      </div>
                    </div>
                    <div className="raised_goal">
                      <p>
                        Goal | {sign}{this.props.activeCampaign.goalAmount
                          ? NumberFormat(this.props.activeCampaign.goalAmount)
                          : "0"}
                      </p>
                      <p>
                        Raised | {sign}{NumberFormat(raised) || 0}
                      </p>
                    </div>
                    <div className="view_campaigns">
                      <a
                        href={
                          this.props.activeCampaign.campaignPage
                            ? "/" + this.props.activeCampaign.campaignPage
                            : "#"
                        }
                        className="btn btn-round-white"
                      >
                        View Live Campaigns
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      )
    );
  }
}
