import React, { Component } from "react";
import axios from 'axios';
import {BASE_URL} from './../../apis/backEnd.apis';
import {
  FacebookShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  EmailShareButton,
  LinkedinShareButton,
  FacebookIcon,
  TwitterIcon,
  LinkedinIcon,
  WhatsappIcon,
  EmailIcon
} from "react-share";

class SocialIcons extends Component {
  constructor() {
    super();

    this.state = {
      shared: []
    }
  }



  handleClick = async (name) => {
    const { handleShare } = this.props;
    const {o_id,c_id} = this.props;

    if(this.state.shared.indexOf(name) === -1) {
      this.setState({
        shared: [...this.state.shared, name]
      });
      if(name == "fb")
      {
      window.open(
        'https://www.facebook.com/sharer/sharer.php?u='+ BASE_URL + 'campaigns/socialShare/' +c_id +'/'+o_id,
        '_blank' // <- This is what makes it open in a new window.
      );
      }
      if(name == "twitter")
      {
        window.open(
          'https://twitter.com/intent/tweet?text='+ BASE_URL +'campaigns/socialShare/'+c_id +'/'+o_id,
          '_blank' // <- This is what makes it open in a new window.
        );
      }
      handleShare();
    } else {
      alert('You have already shared this page')
    }
  }

  render() {
    const {title,imageUrl,desc} = this.props;
    const {o_id,c_id} = this.props;
    let description ="";
    if(desc)
    {
      description = desc;
    }
    return (
      <div className="socials-wrapper">
        <ul>
          <li style={{cursor:'pointer'}} onClick={() => this.handleClick('fb')}>
            {/* <FacebookShareButton disabled={this.state.shared.indexOf('fb') > -1} url={window.location.href} quote={title+"\n"+ description}> */}
              <FacebookIcon size={40} round />
            {/* </FacebookShareButton> */}
          </li>
          <li style={{cursor:'pointer'}} onClick={() => this.handleClick('twitter')}>
            {/* <TwitterShareButton disabled={this.state.shared.indexOf('twitter') > -1} title={title + "\n"+description + "\n"} url={window.location.href}> */}
              <TwitterIcon size={40} round />
            {/* </TwitterShareButton> */}
          </li>
          <li style={{cursor:'pointer'}} onClick={() => this.handleClick('whatsapp')}>
            <WhatsappShareButton disabled={this.state.shared.indexOf('whatsapp') > -1} url={BASE_URL +'campaigns/socialShare/'+c_id +'/'+o_id}>
              <WhatsappIcon size={40} round />
            </WhatsappShareButton>
          </li>
        
          <li style={{cursor:'pointer'}} onClick={() => this.handleClick('mail')}>
            <EmailShareButton disabled={this.state.shared.indexOf('mail') > -1} subject={title} body={"Here is the link to the campaign page" + "   " + window.location.href }>
              <EmailIcon size={40} round />
            </EmailShareButton>
          </li>
         
        </ul>
      </div>
    );
  }
};

export { SocialIcons };
