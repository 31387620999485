import React from 'react';
import { NumberFormat } from '../../Common/NumberFormat';
import { SocialIcons } from '../SocialPlugins/SocialPlugins';

import './SocialGoals.scss';

export const SocialGoals = ({ details, desc, shares,imageUrl, handleShare, sign }) => {
  console.log(details,'shared',shares);
  return (
    <section>
      {shares && parseInt(shares.shareCount) >= parseInt(shares.shareGoal) ? <div className="shr_trsur_box">
        <p>Social Share Goal of  </p>
        <h2><span>{shares && Object.keys(shares).length > 0 ? NumberFormat(details.shareGoal) : 0}</span><span> Shares </span></h2>
        <div className="max-share-reached">
          <div className="reached_hsr">
            <h1>{sign}{details.socialShareAmount}</h1>
            <p>Funded</p>
          </div>
          <span><img src="/images/trophy.png" alt="Trophy" /></span>
        </div>
      </div> :
        <div className='share-inprogress'>
          <div className='title'>HELP US RECEIVE AN <b>ADDITIONAL</b></div>
          <h1>{sign}{details.socialShareAmount}</h1>
          <div className='shares-needed'><b><span className='count'>{shares && Object.keys(shares).length > 0 ? shares.shareGoal : 0}</span> SOCIAL SHARES</b> ARE NEEDED</div>
          <div className='bottom-label'>TO RECEIVE THIS DONATION</div>
        </div>}
      <div id="social-widget">
        <SocialIcons o_id={details.organizationId} c_id={details.campaignId} title={details.name} desc={desc} imageUrl={imageUrl} handleShare={handleShare} />
        <div className="total_share">
          <div className="tot_shr_get">
            <span id="result_share_get">{details.shareCount}/{details.shareGoal}</span>
          </div>
          Total shares
        </div>
      </div>
    </section>
  );
}
