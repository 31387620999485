import React, { Component } from "react";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import { TextValidator } from "react-material-ui-form-validator";

class ChequeInfo extends Component {
  handleBlur = e => {
    this.refs[e.target.name].validate(e.target.value, true);
  };

  render() {
    const { inputChange, inputs } = this.props;
    const { routing_number, account_number } = inputs.toJS();
    return (
      <Paper className="checkout">
        <Grid container spacing={8}>
          <Grid item xs={12} sm={6}>
            <label className="form-name">
              Routing Number <span>*</span>
            </label>
            <TextValidator
              autoComplete="nope"
              validators={["required"]}
              errorMessages={["This field is required"]}
              ref="routing_number"
              onBlur={this.handleBlur}
              placeholder="Enter Your Routing Number"
              className="checkout_input"
              value={routing_number}
              name="routing_number"
              onChange={inputChange}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <label className="form-name">
              Account Number <span>*</span>
            </label>
            <TextValidator
              autoComplete="nope"
              validators={["required"]}
              errorMessages={["This field is required"]}
              ref="account_number"
              onBlur={this.handleBlur}
              placeholder="Enter You Account Number"
              className="checkout_input"
              value={account_number}
              name="account_number"
              onChange={inputChange}
            />
          </Grid>
        </Grid>
      </Paper>
    );
  }
}

export { ChequeInfo };
