export const getTime = (date) => {
  let hours = date.getHours();
  let minutes = date.getMinutes();
  let sec = date.getSeconds();
  let ampm = hours >= 12 ? 'PM' : 'AM';
  hours = hours % 12;
  hours = hours ? hours : 12;
  minutes = minutes < 10 ? '0' + minutes : minutes;
  sec = sec < 10 ? `0${sec}` : sec;
  return `${hours}:${minutes}:${sec} ${ampm}`;
}