import React from 'react';
import { Grid, Input, InputBase, Radio, FormControlLabel, RadioGroup, Select, Checkbox } from '@material-ui/core';


export const ManualDonation = ({ details, campaignTeams, onChange, handleDisplayName, isNumber, campaignData, currency, handleCurrency, items, handleItems }) => {
  return (
    <div className="manual-donation-form">
      <div className="header">
        <div className="heading">MANUAL DONATION</div>
      </div>

      <div className="manual-form-container">
        <div className="contents">
          { campaignData.enableCurrency && campaignData.currency.length > 1 &&
            <div className='row2'>
              <label className="prospect-label" >Change Your Currency</label>
              <Select className="donation-select" disableUnderline={true}
                name="currency"
                value={currency.code}
                onChange={handleCurrency}
              >
                {campaignData ?
                  campaignData.currency.map(c =>
                    <option value={c.code} key={c.code}>{c.name}</option>
                  )
                  : null
                }
              </Select>
            </div>
          }
          <Grid className='row1' container>
            <Grid item xs={6} sm={6}>
              <div className="prospect-label amount-lbl">
                Amount
                <div className='donation-input'>
                  <div className='currency'>{currency.sign}</div>
                  <Input
                    type='tel'
                    disableUnderline={true}
                    className="input"
                    name="donationAmount"
                    value={details.donationAmount}
                    onKeyPress={isNumber}
                    onChange={onChange}
                  />
                </div>
              </div>
            </Grid>
            <Grid item xs={6} sm={6} className="radio-grid">
              <RadioGroup
                aria-label="paymentType"
                name="paymentType"
                value={details.paymentType}
                onChange={onChange}
              >
                <FormControlLabel
                  className="form-control radio-btn"
                  value="cash"
                  control={<Radio style={{ color: 'grey' }} />}
                  label="Cash"
                  labelPlacement="start"
                />
                <FormControlLabel
                  className="form-control radio-btn"
                  value="check"
                  control={<Radio style={{ color: 'grey' }} />}
                  label="Check"
                  labelPlacement="start"
                />
              </RadioGroup>
            </Grid>
          </Grid>
          { details.paymentType === 'check' && <Grid className='row4' container>
            <Grid xs={12} sm={6} className='routing'>
              <label className='prospect-label'>Routing Number</label>
              <Input className='input'
                disableUnderline={true}
                value={details.routing_number}
                name="routing_number"
                onChange={onChange}
              />
            </Grid>
            <Grid xs={12} sm={6} className='account'>
              <label className='prospect-label'>Account Number</label>
              <Input className='input'
                disableUnderline={true}
                value={details.account_number}
                name="account_number"
                onChange={onChange}
              />
            </Grid>
          </Grid> }
          <div className='row2'>
            <label className="prospect-label" >Credit this Donation to a volunteer's campaing page:</label>
            <Select className="donation-select" disableUnderline={true}
              name="teamId"
              value={details.teamId || ''}
              onChange={onChange}
            >
              {campaignTeams ?
                campaignTeams.map((team, index) =>
                  <option value={team.id} key={index}>{team.teamName}</option>
                )
                : null
              }
            </Select>
          </div>
          <Grid className='row3' item xs={12}>
            <label className="prospect-label">Show Donation As Anonymous</label>
            <Checkbox color="default" name="displayName" checked={details.displayName} onChange={handleDisplayName} />
          </Grid>
          { !details.displayName && <div className='row4'>
            <label className="prospect-label">| OR | Display Name on Campaign Page as</label>
            <Input className='input'
              disableUnderline={true}
              value={details.alias}
              name="alias"
              onChange={onChange}
            />
          </div>}
          { campaignData.mode === 'e-commerce' &&
            <Grid className='items' container xs={12}>
              <h4>Enter No. of Items to Donate</h4>
              { items.map(e => {
                return (
                  <Grid className='item' item xs={12}>
                    <div className='info'>
                      <div className='head'>
                        <label className="prospect-label">{e.title}</label>
                        <label className="prospect-label">{currency.sign}{e.price}</label>
                      </div>
                      <div className='desc'>
                        <p>Total Donated: {e.donated}/{e.total}</p>
                      </div>
                    </div>
                    <div className='input-items'>
                      <label>Enter no. of items</label>
                      <Input
                        type='number'
                        className='input'
                        disableUnderline={true}
                        onChange={(eve) => handleItems(eve, e)}
                      />
                    </div>
                  </Grid>
                )
              })}
            </Grid>
          }
          <Grid className='row5' item xs={12}>
            <label className="prospect-label">Message/Dedication(optional)</label>
            <InputBase
              multiline={true}
              rows={3}
              className="input"
              value={details.dedicationMessage}
              name="dedicationMessage"
              onChange={onChange}
            />
          </Grid>
          <Grid className='row4' item xs={12}>
            <label className="prospect-label">Special Notes for Administrator</label>
            <Input className='input'
              disableUnderline={true}
              value={details.specialNotes}
              name="specialNotes"
              onChange={onChange}
            />
          </Grid>
        </div>
      </div>
    </div>
  );
}
