import React, { Component } from 'react';
import { Grid, Hidden, Input, InputLabel, InputBase, Checkbox, Select } from '@material-ui/core';
import { getStatuses } from '../helperFuntions';
import styles from '../style';
import { TextValidator } from "react-material-ui-form-validator";


const setColor = (status) => {
  const color = getStatuses().find(item => item.status === status);
  return color ? color.color : '#000';
}

class DonorInfo extends Component {
  handleBlur = e => {
    this.refs[e.target.name].validate(e.target.value, true);
  };

  render() {
    const { editDonation, viewMore, details, campaignTeams, handleViewMore, onChange, handleDisplayName } = this.props;
    return (
      <div className="donor-form">
        <div className="header">
          <div className="heading">DONOR INFORMATION</div>
        </div>

        <div className="contents">
          { !editDonation && <Hidden smDown>
            <div style={styles.rowStyle}>
              {details.id && (
                <div className="suggested-donation">
                <div className="prospect-label">Suggested Donation</div>
                <Input disableUnderline={true} className="input"
                  name="suggestedDonation"
                  value={details.suggestedDonation}
                  onChange={onChange} />
              </div>
              )}
              <div className="donor-form-content">
                <Select className='call-btn' style={{ background: setColor(details.status), color: details.status === 'Clear' && '#000'  }} defaultValue='Clear' disableUnderline={true} name="status" onChange={onChange} value={details.status}>
                  {getStatuses().map((status, index) =>
                    <option className='status-options' key={index} style={{ padding: 10, background: status.color }} value={status.status}>{status.status}</option>
                  )}
                </Select>
              </div>
            </div>
          </Hidden>}
          { !editDonation && <Hidden mdUp>
            <div>check test
              {details.id && (
                <div className="donor-form-content-mobile">
                <div className="suggested-donation-mobile">
                  <div className="prospect-label">Suggested Donation</div>
                  <Input disableUnderline={true} className="input"
                    name="suggestedDonation"
                    value={details.suggestedDonation}
                    onChange={onChange} />
                </div>
              </div>
              )}

              <div className="donor-form-content-mobile">
                <Select className="call-btn" name="status" defaultValue='Clear' disableUnderline={true} onChange={onChange} value={details.status}>
                  {getStatuses().map((status, index) =>
                    <option className='status-options' key={index} style={{ padding: 10, background: status.color }} value={status.status}>{status.status}</option>
                  )}
                </Select>
              </div>
            </div>
          </Hidden>}

          <div className="donation-form complete-form">
            <div className="field">
              <InputLabel className="prospect-label">First Name</InputLabel>
              <TextValidator
                validators={["required"]}
                errorMessages={["This field is required"]}
                ref="firstName"
                onBlur={this.handleBlur}
                className="input"
                name="firstName"
                value={details.firstName}
                onChange={onChange}
              />
            </div>
            <div className="field">
              <InputLabel className="prospect-label">Last Name</InputLabel>
              <TextValidator
                validators={["required"]}
                errorMessages={["This field is required"]}
                ref="lastName"
                onBlur={this.handleBlur}
                className="input"
                name="lastName"
                value={details.lastName}
                onChange={onChange}
              />
            </div>
            <div className="field">
              <InputLabel className="prospect-label">Mobile Phone Number</InputLabel>
              <TextValidator
                className="input"
                name="mobilePhone"
                value={details.mobilePhone}
                onChange={onChange}
                type="number"
              />
            </div>
            <div className="field">
              <InputLabel className="prospect-label">Work Phone Number</InputLabel>
              <TextValidator
                className="input"
                name="workPhone"
                value={details.workPhone}
                onChange={onChange}
              />
            </div>
            <div className="field">
              <InputLabel className="prospect-label">Home Phone Number</InputLabel>
              <TextValidator
                className="input"
                name="homePhone"
                value={details.homePhone}
                onChange={onChange}
              />
            </div>
            <div className="field">
              <InputLabel className="prospect-label">Email</InputLabel>
              <TextValidator
                className="input"
                name="email"
                value={details.email}
                onChange={onChange}
              />
            </div>
            { editDonation &&
              <Grid item xs={12} className="anonymous-section">
                <label className="prospect-label">Show Donation As Anonymous</label>
                <Checkbox color="default" name="displayName" checked={details.displayName} onChange={handleDisplayName} />
              
              { !details.displayName && <div className='field'>
                <label className="prospect-label">| OR | Display Name on Campaign Page as</label>
                <Input className='input'
                  autoComplete="off"
                  disableUnderline={true}
                  value={details.alias}
                  name="alias"
                  onChange={onChange}
                />
              </div>}
            </Grid> }

            { viewMore ?
              <div className="donation-form">
                { editDonation && <div className="field">
                  <InputLabel className="prospect-label" >Credit this Donation to</InputLabel>
                  <Select className="donation-select" disableUnderline={true}
                    name="teamId"
                    value={details.teamId}
                    onChange={onChange}
                  >
                    {campaignTeams ?
                      campaignTeams.map((team, index) =>
                        <option value={team.id} key={index}>{team.teamName}</option>
                      )
                      : null
                    }
                  </Select>
                </div> }
                <div className="field">
                  <InputLabel className="prospect-label">Address</InputLabel>
                  <Input disableUnderline={true}
                    autoComplete="off"
                    className="input"
                    name="billingAddress"
                    value={details.billingAddress}
                    onChange={onChange}
                  />
                </div>
                <div className="field">
                  <InputLabel className="prospect-label">City</InputLabel>
                  <Input disableUnderline={true}
                    autoComplete="off"
                    className="input"
                    name="billingCity"
                    value={details.billingCity}
                    onChange={onChange}
                  />
                </div>
                <div className="field">
                  <InputLabel className="prospect-label">State/Province</InputLabel>
                  <Input disableUnderline={true}
                    autoComplete="off"
                    className="input"
                    name="billingState"
                    value={details.billingState}
                    onChange={onChange}
                  />
                </div>
                <div className="field">
                  <InputLabel className="prospect-label">Zip/Postal Code</InputLabel>
                  <Input disableUnderline={true}
                    autoComplete="off"
                    className="input"
                    name="billingZip"
                    value={details.billingZip}
                    onChange={onChange}
                  />
                </div>
                <div className="field">
                  <InputLabel className="prospect-label">Country</InputLabel>
                  <Input disableUnderline={true}
                    autoComplete="off"
                    className="input"
                    name="billingCountry"
                    value={details.billingCountry}
                    onChange={onChange}
                  />
                </div>
                { editDonation && <Grid className="field" item xs={12}>
                  <label className="prospect-label">Message/Dedication(optional)</label>
                  <InputBase
                    multiline={true}
                    rows={3}
                    className="input"
                    name="dedicationMessage"
                    value={details.dedicationMessage}
                    onChange={onChange}
                  />
                </Grid> }
              </div>
            : null}
            <div className="show-more" onClick={handleViewMore}>{ !viewMore ? 'SHOW MORE' : 'SHOW LESS' }</div>
          </div>
        </div>
      </div>
    )
  }
}

export { DonorInfo };
