import React, { Component } from "react";
import { Dialog } from "@material-ui/core";
import withMobileDialog from "@material-ui/core/withMobileDialog";
import "react-image-crop/dist/ReactCrop.css";
import "./AddTeamForm.scss";

import AddTeam from "../../components/AddTeam/AddTeam";

class AddTeamForm extends Component {
  render() {
    const { addTeamFormVisible, handleAddTeamFormClose, team } = this.props;

    return (
      <Dialog
        open={addTeamFormVisible}
        onClose={handleAddTeamFormClose}
        aria-labelledby="form-dialog-title"
        scroll="body"
      >
        <AddTeam
          dialog={true}
          onCancel={handleAddTeamFormClose}
          campaignId={this.props.campaignId}
          organizationId={this.props.organizationId}
          history={{ location: { state: null } }}
          team={team}
        />
      </Dialog>
    );
  }
}

export default withMobileDialog()(AddTeamForm);
