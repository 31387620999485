import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import firebase from "./../../apis/firebase.apis";
import getCroppedImg from './cropImage';
import {
    Grid,
    Button,
    FormControl,
    FormControlLabel,
    NativeSelect,
    FormGroup,
    Checkbox,
    RadioGroup,
    Radio
} from "@material-ui/core";
import ErrorIcon from "@material-ui/icons/Error";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import LoadingOverlay from "react-loading-overlay";
import { getCampaignById } from "../../redux/modules/campaign";
import { add, setVolunteer } from "../../redux/modules/Users/action-creator";
import Snackbar from "../../components/Snackbar/Snackbar";
import "./VolunteerTeam.scss";
import AddTeam from './../AddTeam/AddTeam';
import Cropper from "react-easy-crop";

const hours = ["0", "1", "2", "3", "4"];
const initialState = {
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    phone: ""
};
const initialInfo = {
    day1Morning: "0",
    day1Afternoon: "0",
    day1Evening: "0",
    day2Morning: "0",
    day2Afternoon: "0",
    day2Evening: "0",
    laptop: "false"
};

class VolunteerTeam extends Component {
    constructor(props) {
        super(props);

        this.state = {
            details: initialState,
            imageSrc: null,
            crop: { x: 0, y: 0 },
            zoom: 1,
            aspect: 4 / 3,
            image: "",
            show: "",
            day1:'0',
            day2:'0',
            imagePreviewUrl: "",
            blobURL: "",
            croppedAreaPixels: null,
            croppedImage: null,
            showResultant: false,
            imageCropped: false,
            url: '',
            selfieUrl: '',
            volunteer_info: initialInfo,
            repeatPassword: "",
            passMatch: true,
            disableBtn: false,
            message: "",
            box1: true,
            pageTitle: '',
            contact_type:"personal",
            goal: '',
            file: '',
            pic: '',
            page:'yes',
            error: false,
            showMessage: false,
            pageName: "",
            calls:"yes",
            laptop: "yes",
            passValid:true,
            day2morning:"",
            day2afternoon:'',
            day2evening:'',
            loading: false,
            socialMedia: ["facebook"]
        };
        this.campaignUrl = '';
        this.organizationId = this.props.match.params.organization;
        this.campaignId = this.props.match.params.campaign;
        if (this.props.match.params.campaignPage) {
            this.baseurl = this.props.match.params.campaignPage;
        }
        else
        {
            window.location.href=window.location.protocol + "//" + window.location.host+ '/signup-volunteer-team/'+this.organizationId+'/'+this.campaignId + '/myhero';
        }
    
    }

    handleRadio = (e) => {
        console.log(e.target.value);
        this.setState({
            laptop: e.target.value
        })
    }

    handleRadioCalls = (e) => {
        
        this.setState({
            calls: e.target.value
        })
    }

    handleRadioPage = (e) => {
        
        this.setState({
            page: e.target.value
        })
    }

    handleRadioContactType = (e) => {
        console.log(e.target.value);
        this.setState({
            contact_type: e.target.value
        })
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        const {
            user,
            edit,
            history,
            getCampaignById,
            match: {
                params: { campaign, organization }
            }
        } = this.props;
        let url;
        if (this.props.history.location.state) {
            url = this.props.history.location.state.name;
        }
        if (edit) {
            this.setState({
                details: {
                    firstName: user.firstName,
                    lastName: user.lastName,
                    email: user.email,
                    phone: user.phone,
                    userId: user.userId
                },
                url: 'https://pledje.com/',
                volunteer_info: {
                    day1Morning: user.day1Morning,
                    day1Afternoon: user.day1Afternoon,
                    day1Evening: user.day1Evening,
                    day2Morning: user.day2Morning,
                    day2Afternoon: user.day2Afternoon,
                    day2Evening: user.day2Evening,
                    laptop: user.laptop
                },
                socialMedia: user.socialMedia.split(",")
            });
        }

        if (!history.location.state) {
            getCampaignById(organization, campaign);
        }
    }

    componentDidUpdate(oldProps) {
        const { campaign } = this.props;

        if (oldProps.campaign !== campaign) {
            this.setState({ pageName: `/${campaign.data.campaignPage}` });
        }
    }


    handleInputChange = (e) => {
        const { name, value } = e.target;
        // if (name == "url") {
        //     let page = value.split( "https://pledje.com/" + this.baseurl + '/');
        //     this.setState({
        //         url: page[1]
        //     })
        // }
        // else {
        //     this.setState({
        //         [name]: value
        //     })
        // }
                    this.setState({
                [name]: value
            })
    }

    changeday1=(e)=>
    {
        this.setState({
            day1:e.target.value
        })
    }

    changeday2=(e)=>
    {
        this.setState({
            day2:e.target.value
        })
    }

    handleChange = e => {
        e.persist();
        this.setState(prevState => ({
            details: {
                ...prevState.details, 
                [e.target.name]: e.target.value
            }
        }));
    };

    handlePassChange = e => {
        e.persist();
        this.setState(prevState => ({
            details: {
                ...prevState.details, 
                passValid : e.target.value.length < 6 ?false:true,
                [e.target.name]: e.target.value
            }
        }));
    };

    onFileChange = e => {
        e.preventDefault();

        let reader = new FileReader();
        let file = e.target.files[0];

        reader.onloadend = () => {
            this.setState({
                file: file,
                imagePreviewUrl: reader.result,
                blobURL: reader.result,
                showResultant: true
            });
        };

        reader.readAsDataURL(file);
    };

    changeVolunteerInfo = e => {
        e.persist();
        this.setState(prevState => ({
            volunteer_info: {
                ...prevState.volunteer_info,
                [e.target.name]: e.target.value
            }
        }));
    };

    handleChaeckbox1 = (e) => {
        this.setState({
            box1: e.target.checked
        })
    }

    handleCheckbox = e => {
        let sm = [...this.state.socialMedia];
        if (e.target.checked) {
            sm = [...sm, e.target.value];
            this.setState({
                [e.target.name]: sm
            });
        } else {
            const index = sm.indexOf(e.target.value);
            sm.splice(index, 1);
            this.setState({
                [e.target.name]: sm
            });
        }
    };

    handleCheckboxday=(e) =>
    {
        const {name} =e.target;
        const {checked} =e.target;
        this.setState({
            [name] : checked
        })
    }

    handlePasswordChange = e => {

        const {
            details: { password }
        } = this.state;
        console.log('+++++++++++',password)
        this.setState({
            [e.target.name]: e.target.value,
            passMatch: password === e.target.value ? true : false
        });
    };

    handleSubmit = () => {
        const {
            match,
            addUser,
            setVolunteer,
            history,
            handleAddUserFormClose,
            organizationId,
            edit,
            updateUser
        } = this.props;
        const { details, volunteer_info, url, pageName, socialMedia } = this.state;
        if (url == undefined) {
            url = "";
        }
        volunteer_info.socialMedia = socialMedia;
        const data = {
            user: {
                ...details,
                pageTitle: this.state.pageTitle,
                url: this.state.url,
                organizationId: this.organizationId,
                campaignId: this.campaignId,
                goal: this.state.goal,
                pic: this.state.previewUrl,
                selfieUrl: this.state.selfieUrl,
                volunteer_info,
                role: "volunteer",
                organizationId: history ? match.params.organization : organizationId,
                status: "Inactive"
            }
        };


        setVolunteer(details);
        this.setState({ disableBtn: true, loading: true });
        if (!edit) {
            addUser(data)
                .then(() => {
                    this.showSnackBar(
                        "You have been signed up as volunteer successfully!",
                        false
                    );

                    if (history) {
                        history.push(
                            `/volunteer-confirmation/${this.organizationId}/${
                            this.campaignId
                            }`,
                            {
                                name: history.location.state
                                    ? history.location.state.name
                                    : pageName,
                                campaignName: this.state.pageName
                            }
                        );
                    } else {
                        handleAddUserFormClose();
                    }
                    this.setState({
                        details: initialState,
                        disableBtn: false,
                        loading: false
                    });
                })
                .catch(err => {
                    this.setState({ disableBtn: false, loading: false });

                    this.showSnackBar(
                        err.response.data.error.message
                            ? err.response.data.error.message
                            : err.response.data.error,
                        true
                    );
                });
        } else {
            updateUser(data);
        }
    };

    showSnackBar = (message, isError) => {
        this.setState({ showMessage: true, message, error: isError });
        setTimeout(() => {
            this.setState({ showMessage: false, message: "" });
        }, 4000);
    };

    handleBlur = e => {
        this.refs[e.target.name].validate(e.target.value, true);
    };

    handleCancel = () => {
        const { history, handleAddUserFormClose } = this.props;
        if (history) {
            history.goBack();
        } else {
            handleAddUserFormClose();
        }
    };

    onCropChange = crop => {
        this.setState({ crop });
    };



    onCropComplete = (croppedArea, croppedAreaPixels) => {
        this.setState({ croppedAreaPixels });
    };

    showCroppedImage = async () => {
        const { fileBlob, blobURL } = await getCroppedImg(
            this.state.blobURL,
            this.state.croppedAreaPixels
        );
        firebase
            .storage()
            .ref()
            .child("CampaignPicture")
            .child(JSON.stringify(new Date()))
            .put(fileBlob)
            .then(snapshot => {
                console.log(snapshot);
                snapshot.ref.getDownloadURL().then(url => {
                    this.setState(
                        {
                            imagePreviewUrl: url,
                            blobURL: blobURL,
                            showResultant: false,
                            imageCropped: true
                        },
                        () => alert("file uploaded")
                    );
                });
            });
    };

    onZoomChange = zoom => {
        this.setState({ zoom });
    };

    handleError = () => {
        this.showSnackBar(
            "Please Fill in the marked fields with correct data",
            true
        );
    };

    cropImageUpload = e => {
        e.preventDefault();
        this.showCroppedImage();
    };

    showImage = e => {
        e.preventDefault();
        this.setState({
            showResultant: true,
            imageCropped: false
        });
    };

    cancelCrop = e => {
        e.preventDefault();
        this.setState({
            showResultant: false,
            imageCropped: true
        });
    };

    render() {
        const { campaign } = this.props.match.params;
        const {
            details: { firstName, lastName, email, password, phone },
            volunteer_info: {
                day1Morning,
                day1Afternoon,
                day1Evening,
                day2Morning,
                day2Afternoon,
                day2Evening,
                laptop
            },
            repeatPassword,
            passMatch,
            pageTitle,
            goal,
            pic,
            selfieUrl,
            disableBtn,
            loading,
            passValid,
            socialMedia
        } = this.state;
        let { url } = this.state;
        if (url == undefined) {
            url = '';
        }
        this.campaignUrl = "https://pledje.com/"+ this.baseurl + "/" +  url;
        console.log('+++++++++++++++', passValid);
        const { edit } = this.props;


        return (
            <LoadingOverlay active={loading} spinner>
                <div className="volunteer-form">
                    <div className="header">
                        <h3>VOLUNTEER SIGN UP PAGE</h3>
                    </div>
                    <Grid container>
                        <Snackbar
                            message={this.state.message}
                            color={this.state.error ? "danger" : "success"}
                            icon={this.state.error ? ErrorIcon : CheckCircleIcon}
                            place="tr"
                            open={this.state.showMessage}
                        />
                        <ValidatorForm
                            className="add-volunteer"
                            ref="form"
                            onSubmit={this.handleSubmit}
                            onError={this.handleError}
                            instantValidate={true}
                        >
                            <div className="title">
                                <h2 className="mb-0"> Please answer the following questions to get started. For assistance, please send an email to <a href="mailto:support@pledje.com"> support@pledje.com</a></h2>
                            </div>
                            <Grid item container xs={12}>
                                <Grid item xs={12}>
                                    <div className="title-form">YOUR INFORMATION </div>
                                </Grid>
                                <Grid item sm={6} xs={12}>
                                    <div className="user-info">
                                        <label className="input-label">First Name</label>
                                        <TextValidator
                                            autoComplete={"nope"}
                                            validators={["required"]}
                                            errorMessages={["This field is required"]}
                                            ref="firstName"
                                            name="firstName"
                                            value={firstName}
                                            className="input"
                                            onChange={this.handleChange}
                                            onBlur={this.handleBlur}
                                        />
                                    </div>
                                </Grid>
                                <Grid item sm={6} xs={12}>
                                    <div className="user-info">
                                        <label className="input-label">Last Name</label>
                                        <TextValidator
                                            autoComplete={"nope"}
                                            validators={["required"]}
                                            errorMessages={["This field is required"]}
                                            ref="lastName"
                                            name="lastName"
                                            value={lastName}
                                            className="input"
                                            onChange={this.handleChange}
                                            onBlur={this.handleBlur}
                                        />
                                    </div>
                                </Grid>
                            </Grid>

                            <Grid item container xs={12}>
                                <Grid item sm={6} xs={12}>
                                    <div className="user-info">
                                        <label className="input-label">Mobile Number</label>
                                        <TextValidator
                                            inputProps={{
                                                autocomplete: "new-password",
                                                form: {
                                                    autocomplete: "off"
                                                }
                                            }}
                                            validators={["required"]}
                                            errorMessages={["This field is required"]}
                                            ref="phone"
                                            name="phone"
                                            type="number"
                                            value={phone}
                                            className="input"
                                            onChange={this.handleChange}
                                            onBlur={this.handleBlur}
                                        />
                                    </div>
                                </Grid>
                            </Grid>
                            {!edit && (
                                <Grid item container xs={12}>
                                    <Grid item xs={12}>
                                        <div className="title-form">VOLUNTEER ACCOUNT SET UP </div>
                                    </Grid>
                                    <Grid item sm={12} xs={12}>
                                    <Grid item sm={6} xs={12}>
                                    <div className="user-info">
                                        <label className="input-label">Email</label>
                                        <TextValidator
                                            autoComplete={"nope"}
                                            validators={["required", "isEmail"]}
                                            errorMessages={[
                                                "This field is required",
                                                "Invalid email address"
                                            ]}
                                            ref="email"
                                            name="email"
                                            value={email}
                                            className="input"
                                            onChange={this.handleChange}
                                            onBlur={this.handleBlur}
                                        />
                                    </div>
                                </Grid>
                                </Grid>
                                    <Grid item sm={6} xs={12}>
                                        <div className="user-info">
                                            <label className="input-label">Choose a Password</label>
                                            <TextValidator
                                                validators={["required"]}
                                                errorMessages={["This field is required"]}
                                                ref="password"
                                                name="password"
                                                value={password}
                                                type="password"
                                                className="input"
                                                onChange={this.handlePassChange}
                                                onBlur={this.handleBlur}
                                            />
                                        </div>
                                        {!passValid && (
                                                    <p style={{ color: "red", fontSize: 11 }}>
                                                        Password should contain minimum 6 characters
                          </p>
                                                )}
                                    </Grid>
                                    <Grid item sm={6} xs={12}>
                                        <div className="user-info">
                                            <label className="input-label">
                                            Type Your Password Again
                      </label>
                                            <div className="c-pass">
                                                <TextValidator
                                                    validators={["required"]}
                                                    errorMessages={["This field is required"]}
                                                    name="repeatPassword"
                                                    value={repeatPassword}
                                                    type="password"
                                                    className="input"
                                                    onChange={this.handlePasswordChange}
                                                />
                                                {!passMatch && (
                                                    <p style={{ color: "red", fontSize: 11 }}>
                                                        Password does not match
                          </p>
                                                )}
                                                                                      
                                            </div>
                                        </div>
                                    </Grid>
                                </Grid>
                            )}
                            <Grid item container xs={12}>
                                <div className="title-form">PERSONAL PAGE SET UP </div>
                                <Grid item sm={9} xs={10}>
                                    <div className="user-info">
                                    Would you like to set up a personal sub-campaign fundraising page & make a make a bigger impact?
                                    </div>
                                </Grid>
                                <Grid item sm={3} xs={12}>
                                    <div className="user-info">
                                        <RadioGroup aria-label="gender" name="page" className="inlineRadio" value={this.state.page} onChange={this.handleRadioPage}>
                                            <FormControlLabel
                                                value="yes"
                                                control={<Radio color="primary" />}
                                                label="Yes"
                                                labelPlacement="start"
                                            />
                                            <FormControlLabel
                                            value="no"
                                                control={<Radio color="primary" />}
                                                label="No"
                                                labelPlacement="start"
                                            />
                                        </RadioGroup>
                                    </div>
                                </Grid>
                            </Grid>
                            {this.state.page == "yes" &&
                                <div>
                                    <Grid item container xs={12}>
                                        <Grid item sm={6} xs={12}>
                                            <div className="user-info">
                                                <label className="input-label">Title of Your Page <span style={{fontSize:"14px", color : "grey"}}>(100 Characters Max)</span>
</label><label className="input-label">Example: David & Sarah's Fundraising page</label>
                                                <TextValidator
                                                    autoComplete={"nope"}
                                                    validators={["required"]}
                                                    errorMessages={["This field is required"]}
                                                    ref="pageTitle"
                                                    name="pageTitle"
                                                    value={pageTitle}
                                                    className="input"
                                                    placeholder=""
                                                    onChange={this.handleInputChange}
                                                    onBlur={this.handleBlur}
                                                />
                                            </div>
                                        </Grid>
                                        <Grid item sm={6} xs={12}>
                                            <div className="user-info">
                                                <label className="input-label">Your Fundraising Goal </label>
                                                <label className="input-label">Campaign Goal in $ </label>
                                                <TextValidator
                                                    autoComplete={"nope"}
                                                    validators={["required"]}
                                                    errorMessages={["This field is required"]}
                                                    ref="goal"
                                                    name="goal"
                                                    value={goal}
                                                    type="number"
                                                    placeholder=""
                                                    className="input"
                                                    onChange={this.handleInputChange}
                                                    onBlur={this.handleBlur}
                                                />
                                            </div>
                                        </Grid>
                                    </Grid>
                                    <Grid item container xs={12}>
                                        <Grid item sm={6} xs={12}>
                                            <div className="user-info left-insideRow">
                                                <label className="input-label">Upload a Picture of Yourself
                                                <span style={{fontSize:"14px", color : "grey"}}> (Optional)</span></label>
                                                <TextValidator
                                                    autoComplete={"nope"}

                                                    errorMessages={["This field is required"]}
                                                    ref="pic"
                                                    onChange={this.onFileChange}
                                                    name="pic"
                                                    type="file"
                                                    className="input"
                                                />
                                                {this.state.showResultant && (
                                                    <Fragment>
                                                        <div className="crop-container">
                                                            <Cropper
                                                                image={this.state.imagePreviewUrl}
                                                                crop={this.state.crop}
                                                                zoom={this.state.zoom}
                                                                aspect={this.state.aspect}
                                                                onCropChange={this.onCropChange}
                                                                onCropComplete={this.onCropComplete}
                                                                onZoomChange={this.onZoomChange}
                                                            />
                                                        </div>
                                                        <div className="controls" />
                                                        <button
                                                            className="crop-btn"
                                                            onClick={this.cropImageUpload}
                                                        >
                                                            {"Crop Avatar and Upload"}
                                                        </button>
                                                    </Fragment>
                                                )}
                                                {this.state.imageCropped ? (
                                                    <button
                                                        onClick={this.showImage}
                                                        variant="contained"
                                                        color="primary"
                                                        className="crop-btn"
                                                    >
                                                        Show Image
                      </button>
                                                ) : (
                                                        ""
                                                    )}
                                                {this.state.showResultant ? (
                                                    <button className="crop-btn" onClick={this.cancelCrop}>
                                                        Cancel
                      </button>
                                                ) : (
                                                        ""
                                                    )}
                                            </div>
                                        </Grid>
                                        <Grid item sm={6} xs={12}>
                                            <div className="user-info">
                                                <label className="input-label">Desired Path for Your Team Page
</label>                                    <div className="row-custom">
                                                
                                                <div style={{marginTop:'4px'}} classNames="mt-5">
                                                https://pledje.com/{this.baseurl}/
                                                </div>
                                                <TextValidator
                                                    autoComplete={"nope"}
                                                    validators={["required"]}
                                                    errorMessages={["This field is required"]}
                                                    ref="url"
                                                    name="url"
                                                    value={this.state.url}
                                                    className="input"
                                                    onChange={this.handleInputChange}
                                                    onBlur={this.handleBlur}
                                                />
                                                </div>
                                            </div>
                                        </Grid>
                                    </Grid>
                                </div>
                            }

                            
                            <Grid item container xs={12}>
                                <div className="title-form">Almost done </div>
                                                            {<p>
                                Which of the following social media hubs do you have access to
                                and use (this will help us spread the news about the campaign
                                faster and more efficiently)
              </p> }
                            { <div className="user-info">
                                <FormControl component="fieldset">
                                    <FormGroup row>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    name="socialMedia"
                                                    color="default"
                                                    checked={socialMedia.indexOf("facebook") > -1}
                                                    onChange={this.handleCheckbox}
                                                    value="facebook"
                                                />
                                            }
                                            label="Facebook"
                                            className="campaignDayLabel"
                                        />
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    name="socialMedia"
                                                    color="default"
                                                    checked={socialMedia.indexOf("instagram") > -1}
                                                    onChange={this.handleCheckbox}
                                                    value="instagram"
                                                />
                                            }
                                            label="Instagram"
                                            className="campaignDayLabel"
                                        />
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    name="socialMedia"
                                                    color="default"
                                                    checked={socialMedia.indexOf("whatsapp") > -1}
                                                    onChange={this.handleCheckbox}
                                                    value="whatsapp"
                                                />
                                            }
                                            label="Whatsapp"
                                            className="campaignDayLabel"
                                        />
                                    </FormGroup>
                                </FormControl>
                            </div> }
                                <p>
                                Approximate hours you may be able to spend at the campaign room? <span style={{opacity:"0"}}>(Please note that childcare/refreshments/lunch/dinner will be available in the campaign headquarters)</span>
                                </p>
                                
                                <Grid item sm={3} xs={12}>
                                    <div className="user-info hour-info">
                                        <label>Day 1 of the campaign</label>
                                        {/* <NativeSelect
                                            className="select"
                                            onChange={this.changeVolunteerInfo}
                                            name="day1Morning"
                                            value={day1Morning}
                                            disableUnderline={true}
                                        >
                                            {hours.map(h => (
                                                <option key={h} value={h}>
                                                    {h}
                                                </option>
                                            ))}
                                        </NativeSelect> */}
                                    </div>
                                </Grid>
                                <Grid item sm={5} xs={12}>
                                    <FormControl component="fieldset">
                                        <FormGroup row classNames="campaignDay">
                                            <FormControlLabel
                                                classNames="campaignDay"
                                                control={
                                                    <Checkbox
                                                        name="day1morning"
                                                        color="default"
                                                        checked={this.state.day1morning}
                                                        onChange={this.handleCheckboxday}
                                                        value="day1morning"
                                                    />
                                                }
                                                label="Morning
                                                "
                                                className="campaignDayLabel"
                                            />
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        name="day1afternoon"
                                                        color="default"
                                                        checked={this.state.day1afternoon}
                                                        onChange={this.handleCheckboxday}
                                                        value="day1afternoon"
                                                    />
                                                }
                                                label="Afternoon
                                                "
                                                className="campaignDayLabel"
                                            />
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        name="day1evening"
                                                        color="default"
                                                        checked={this.state.evening}
                                                        onChange={this.handleCheckbox}
                                                        value="day1evening"
                                                    />
                                                }
                                                label="Evening
                                                "
                                                className="campaignDayLabel"
                                            />
                                        </FormGroup>
                                    </FormControl>
                                </Grid>
                                <Grid item sm={4} xs={12}>
                                    <div className="user-info hour-info">
                                        <label>Total number of hours</label>
                                        <NativeSelect
                                            className="select"
                                            onChange={this.changeday1}
                                            name="day1"
                                            value={this.state.day1}
                                            disableUnderline={true}
                                        >
                                            {hours.map(h => (
                                                <option key={h} value={h}>
                                                    {h}
                                                </option>
                                            ))}
                                        </NativeSelect>
                                    </div>
                                </Grid>

                                                                <Grid item sm={3} xs={12}>
                                    <div className="user-info hour-info">
                                        <label>Day 2 of the campaign</label>
                                        {/* <NativeSelect
                                            className="select"
                                            onChange={this.changeVolunteerInfo}
                                            name="day1Morning"
                                            value={day1Morning}
                                            disableUnderline={true}
                                        >
                                            {hours.map(h => (
                                                <option key={h} value={h}>
                                                    {h}
                                                </option>
                                            ))}
                                        </NativeSelect> */}
                                    </div>
                                </Grid>
                                <Grid item sm={5} xs={12}>
                                    <FormControl component="fieldset">
                                        <FormGroup row classNames="campaignDay">
                                            <FormControlLabel
                                                classNames="campaignDay"
                                                control={
                                                    <Checkbox
                                                        name="day2morning"
                                                        color="default"
                                                        checked={this.state.day2morning}
                                                        onChange={this.handleCheckbox}
                                                        value="day2morning"
                                                    />
                                                }
                                                label="Morning
                                                "
                                                className="campaignDayLabel"
                                            />
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        name="day2afternoon"
                                                        color="default"
                                                        checked={this.state.day2afternoon}
                                                        onChange={this.handleCheckbox}
                                                        value="day2afternoon"
                                                    />
                                                }
                                                label="Afternoon
                                                "
                                                className="campaignDayLabel"
                                            />
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        name="day2evening"
                                                        color="default"
                                                        checked={this.state.day2evening}
                                                        onChange={this.handleCheckbox}
                                                        value="day2evening"
                                                    />
                                                }
                                                label="Evening
                                                "
                                                className="campaignDayLabel"
                                            />
                                        </FormGroup>
                                    </FormControl>
                                </Grid>
                                <Grid item sm={4} xs={12}>
                                    <div className="user-info hour-info">
                                        <label>Total number of hours</label>
                                        <NativeSelect
                                            className="select"
                                            onChange={this.changeday2}
                                            name="day2"
                                            value={this.state.day2}
                                            disableUnderline={true}
                                        >
                                            {hours.map(h => (
                                                <option key={h} value={h}>
                                                    {h}
                                                </option>
                                            ))}
                                        </NativeSelect>
                                    </div>
                                </Grid>
                                
                                {/* <Grid item sm={4} xs={12}>
                                    <div className="user-info hour-info">
                                        <label>[Day 1] Afternoon</label>
                                        <NativeSelect
                                            className="select"
                                            onChange={this.changeVolunteerInfo}
                                            name="day1Afternoon"
                                            value={day1Afternoon}
                                            disableUnderline={true}
                                        >
                                            {hours.map(h => (
                                                <option key={h} value={h}>
                                                    {h}
                                                </option>
                                            ))}
                                        </NativeSelect>
                                    </div>
                                </Grid> */}
                                {/* <Grid item sm={4} xs={12}>
                                    <div className="user-info hour-info">
                                        <label>[Day 1] Evening</label>
                                        <NativeSelect
                                            className="select"
                                            onChange={this.changeVolunteerInfo}
                                            name="day1Evening"
                                            value={day1Evening}
                                            disableUnderline={true}
                                        >
                                            {hours.map(h => (
                                                <option key={h} value={h}>
                                                    {h}
                                                </option>
                                            ))}
                                        </NativeSelect>
                                    </div>
                                </Grid> */}
                            </Grid>
                            <Grid item container xs={12}>
                                {/* <Grid item sm={4} xs={12}>
                                    <div className="user-info hour-info">
                                        <label>[Day 2] Morning</label>
                                        <NativeSelect
                                            className="select"
                                            onChange={this.changeVolunteerInfo}
                                            name="day2Morning"
                                            value={day2Morning}
                                            disableUnderline={true}
                                        >
                                            {hours.map(h => (
                                                <option key={h} value={h}>
                                                    {h}
                                                </option>
                                            ))}
                                        </NativeSelect>
                                    </div>
                                </Grid> */}
                                {/* <Grid item sm={4} xs={12}>
                                    <div className="user-info hour-info">
                                        <label>[Day 2] Afternoon</label>
                                        <NativeSelect
                                            className="select"
                                            onChange={this.changeVolunteerInfo}
                                            name="day2Afternoon"
                                            value={day2Afternoon}
                                            disableUnderline={true}
                                        >
                                            {hours.map(h => (
                                                <option key={h} value={h}>
                                                    {h}
                                                </option>
                                            ))}
                                        </NativeSelect>
                                    </div>
                                </Grid> */}
                                {/* <Grid item sm={4} xs={12}>
                                    <div className="user-info hour-info">
                                        <label>[Day 2] Evening</label>
                                        <NativeSelect
                                            className="select"
                                            onChange={this.changeVolunteerInfo}
                                            name="day2Evening"
                                            value={day2Evening}
                                            disableUnderline={true}
                                        >
                                            {hours.map(h => (
                                                <option key={h} value={h}>
                                                    {h}
                                                </option>
                                            ))}
                                        </NativeSelect>
                                    </div>
                                </Grid> */}
                            </Grid>


                                          <Grid item container xs={12}>
                                <Grid item sm={9} xs={12}>
                                    <div className="user-info">
                                    If the option is available, are you interested in making calls from your home/office?
                                    </div>
                                </Grid>
                                <Grid item sm={3} xs={12}>
                                    <div className="user-info">
                                        <RadioGroup aria-label="gender" name="laptop" className="inlineRadio" value={this.state.calls} onChange={this.handleRadioCalls}>
                                            <FormControlLabel
                                                value="yes"
                                                control={<Radio color="primary" />}
                                                label="Yes"
                                                labelPlacement="start"
                                            />
                                            <FormControlLabel
                                            value="no"
                                                control={<Radio color="primary" />}
                                                label="No"
                                                labelPlacement="start"
                                            />
                                        </RadioGroup>
                                    </div>
                                </Grid>
                            </Grid>
                            {this.state.calls == "yes" && <div>
                            <Grid item sm={9} xs={12}>
                                    <div className="user-info">
                                    Will you utilize your own contacts or would you need a list of prospects to call ?
                                    </div>
                                </Grid>
                                <Grid item sm={12} xs={12}>
                                    <div className="user-info">
                                        <RadioGroup aria-label="gender" name="laptop" className="inlineRadio" value={this.state.contact_type} onChange={this.handleRadioContactType}>
                                            <FormControlLabel
                                                value="personal"
                                                control={<Radio color="primary" />}
                                                label="Personal Contacts"
                                                className="ml-0"
                                                labelPlacement="start"
                                            />
                                            <FormControlLabel
                                            value="prospect-list"
                                                control={<Radio color="prospect-list" />}
                                                label="Need a list of prospects"
                                                labelPlacement="start"
                                            />
                                                                                                                        <FormControlLabel
                                            value="both"
                                                control={<Radio color="primary" />}
                                                label="Both"
                                                labelPlacement="start"
                                            />
                                        </RadioGroup>
                                    </div>
                                </Grid></div>}
                            <Grid item container xs={12}>
                                
                                <Grid item sm={9} xs={12}>
                                    <div className="user-info">
                                        <label>Do you own a tablet/laptop that you can bring to the campaign headquarter? </label>
                                    </div>
                                </Grid>
                                <Grid item sm={3} xs={12}>
                                    <div className="user-info">
                                        <RadioGroup aria-label="gender" name="laptop" className="inlineRadio" value={this.state.laptop} onChange={this.handleRadio}>
                                            <FormControlLabel
                                                value="yes"
                                                control={<Radio color="primary" />}
                                                label="Yes"
                                                labelPlacement="start"
                                            />
                                            <FormControlLabel
                                            value="no"
                                                control={<Radio color="primary" />}
                                                label="No"
                                                labelPlacement="start"
                                            />
                                        </RadioGroup>
                                    </div>
                                </Grid>
                                    {/* <NativeSelect
                                        className="select"
                                        onChange={this.changeVolunteerInfo}
                                        name="laptop"
                                        defaultValue={laptop}
                                        disableUnderline={true}
                                    >
                                        <option value={true}>Yes</option>
                                        <option value={false}>No</option>
                                    </NativeSelect> */}
                                    
                                
                            </Grid>
                            {/* <Grid item container xs={12}>
                                <Grid item sm={6} xs={12}>
                                    <div className="user-info">
                                        <label className="input-label">Enter a url for your selfie video

</label>
                                        <p>(You may do this from at a later time by logging into your back office with your unique id and password)
</p>
                                        <TextValidator
                                            autoComplete={"nope"}

                                            errorMessages={["This field is required"]}
                                            ref="selfieUrl"
                                            name="selfieUrl"
                                            placeholder="Enter a url for your selfie video"
                                            value={selfieUrl}
                                            className="input"
                                            placeholder="Example: David & Sarah's Fundraising page"
                                            onChange={this.handleInputChange}
                                            onBlur={this.handleBlur}
                                        />
                                    </div>
                                </Grid>
                            </Grid> */}
                            <div className="btn-container">
                                <Button className="cancel-btn" onClick={this.handleCancel}>
                                    Cancel
                </Button>
                                {edit ? (
                                    <Button
                                        disabled={disableBtn}
                                        type="submit"
                                        className="submit-btn"
                                    >
                                        Update
                  </Button>
                                ) : (
                                        <Button
                                            disabled={disableBtn}
                                            type="submit"
                                            className="submit-btn"
                                        >
                                            Submit
                  </Button>
                                    )}
                            </div>
                        </ValidatorForm>
                    </Grid>
                </div>
            </LoadingOverlay>
        );
    }
}

const mapStateToProps = state => {
    return {
        campaign: state.campaign.get("campaignData")
    };
};

const mapDispatchToProps = dispatch => {
    return {
        addUser: bindActionCreators(add, dispatch),
        setVolunteer: bindActionCreators(setVolunteer, dispatch),
        getCampaignById: bindActionCreators(getCampaignById, dispatch)
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(VolunteerTeam);
