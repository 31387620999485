import React, { Component } from "react";
import { Dialog, DialogTitle, DialogActions, Grid, withMobileDialog, Switch, withStyles } from "@material-ui/core";
import FileUploader from "react-firebase-file-uploader";
import firebase from "../../apis/firebase.apis";

const paperStyle = {
  colorSwitchBase: {
    "&$colorChecked": {
      color: "#008eb5",
      "& + $colorBar": {
        backgroundColor: "#008eb5"
      }
    }
  },
  colorBar: {},
  colorChecked: {}
};

class SuperDonors extends Component {
  constructor() {
    super();

    this.state = {
      showSuperDonors: true,
      superDonors: []
    };
  }

  componentDidMount() {
    const { superDonors, showSuperDonors } = this.props;

    this.setState({
      superDonors,
      showSuperDonors
    })
  }

  onUploadError = error => {
    const { showSnackBar } = this.props;
    showSnackBar("Error uploading file", true);
  };

  onUploadSuccess = (source, filename, i) => {
    const dInfo = [...this.state.superDonors];
    const { showSnackBar } = this.props;
    firebase
      .storage()
      .ref(source)
      .child(filename)
      .getDownloadURL()
      .then(url => {
        dInfo[i].icon = url
        this.setState({ superDonors: dInfo }, () =>
          showSnackBar("File uploaded", false)
        )
      });
  };

  handleSave = () => {
    const { superDonors } = this.state;
    const { saveSuperDonors, showSnackBar } = this.props;

    if (superDonors.length > 0) {
      saveSuperDonors(superDonors);
    } else {
      showSnackBar("Please Add at lease 1 super donor", true)
    }
  }

  handleChange = (e, i) => {
    const { value, name } = e.target;
    let donors = [...this.state.superDonors];
    donors[i][name] = value;

    this.setState({ superDonors: donors });
  }

  removeIcon = (e, i) => {
    let donors = [...this.state.superDonors];
    donors[i].icon = '';

    this.setState({ superDonors: donors });
  }

  handleAdd = () => {
    const donors = [...this.state.superDonors];
    const info = {
      name: '',
      icon: '',
      category: ''
    }

    donors.push(info);
    this.setState({ superDonors: donors });
  }

  handleRemove = (e, i) => {
    const donors = [...this.state.superDonors];
    donors.splice(i, 1);
    this.setState({ superDonors: donors });
  }

  render() {
    const { fullScreen, handleSuperDonors, classes } = this.props;
    const { superDonors, showSuperDonors } = this.state;
    const switchStyle = {
      switchBase: classes.colorSwitchBase,
      checked: classes.colorChecked,
      bar: classes.colorBar
    };

    return (
      <Dialog
        id='additional-info-dialog'
        fullScreen={fullScreen}
        maxWidth={'md'}
        open={true}
      >
        <DialogTitle className="title">
          Super Donors
        </DialogTitle>
        <Grid className="container" container spacing={0}>
          <Grid className="item" item xs={12}>
            <label className="switch-label">Show Super Donors</label>
            <Switch
              classes={switchStyle}
              name="showSuperDonors"
              onChange={this.handleSwitch}
              checked={showSuperDonors}
              value={showSuperDonors}
            />
          </Grid>
          { superDonors.map((e, i) => {
            return (
              <Grid className="items closable" container key={i}>
                <div onClick={e => this.handleRemove(e, i)} className="close">x</div>
                <Grid className="item" item xs={12}>
                  <h4>Super Donor {i + 1}</h4>
                </Grid>
                <Grid className="item" item xs={12} sm={6} md={4}>
                  <label>Super Donor Name</label>
                  <input className="input" name="name" onChange={e => this.handleChange(e, i)} value={e.name} />
                </Grid>
                <Grid className="item" item xs={12} sm={6} md={4}>
                  <label>Category</label>
                  <input className="input" name="category" onChange={e => this.handleChange(e, i)} value={e.category} />
                </Grid>
                { !e.icon && 
                  <Grid className="item" item xs={12} sm={6} md={4}>
                    <label>Super Donor Image</label>
                    <FileUploader
                      multiple={false}
                      accept="image/*"
                      name="icon"
                      randomizeFilename
                      storageRef={firebase.storage().ref("SuperDonor")}
                      onUploadError={this.onUploadError}
                      onUploadSuccess={filename => this.onUploadSuccess("SuperDonor", filename, i)}
                    />
                  </Grid>
                }
                { e.icon &&
                  <Grid className="item icon" item xs={12} sm={6} md={4}>
                    <label>Super Donor Image</label>
                    <div className="img-container">
                      <img src={e.icon} alt="Icon" />
                    </div>
                    <button onClick={e => this.removeIcon(e, i)}>Remove</button>
                  </Grid>
                }
              </Grid>
            )
          }) }
          <button onClick={this.handleAdd} className="add-btn">Add Super Donor</button>
        </Grid>
        <DialogActions className="actions">
          <button onClick={handleSuperDonors}>Cancel</button>
          <button onClick={this.handleSave}>Save</button>
        </DialogActions>
      </Dialog>
    );
  }
}

export default withStyles(paperStyle)(withMobileDialog()(SuperDonors));
