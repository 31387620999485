import React from 'react';

export const Multiplier = ({ matchFactor,donate_type,total_amount, donate_amount, duration, currency }) => {
  return (
    <div className='multiplier'>
      <label>Thanks to Matching Pledges</label>
      <div className='multiply'>
        <p>{matchFactor}</p>
        <span className='cross'>x</span>
        <span className='equal'>=</span>
      </div>
      <span>=</span>
      <div className='calculated-total'>
        {currency.sign}{donate_type == "monthly" ? donate_amount * duration * matchFactor :donate_amount * matchFactor }
      </div>
    </div>
  );
}
