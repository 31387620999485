import * as yup from "yup";

const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

const teamValidation = yup.object().shape({
  email: yup.string().email("E-mail is not valid!").required("Please enter E-mail!"),
  goal: yup.number().required('Please enter Team Goal').positive().integer(),
  title: yup.string().required('Please Enter Title of the Team Page').min(3).max(100),
  firstName: yup.string().required('Please Enter First Name of Team Page Owner'),
  lastName: yup.string().required('Please Enter Last Name of Team Page Owner'),
  phone: yup.string().matches(phoneRegExp, "Phone Number is not valid"),
  teamPageURL: yup.string().required('Please Enter the Team Page URL'),
  url: yup.string().url(),
});

export default teamValidation;
