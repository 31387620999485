const _v = require("uuid/v4");
const _v2 = _interopRequireDefault(_v);
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : { default: obj };
}

export const initialValues = {
  autoCapture: false,
  bonusGoal1MatchFactor: 1,
  bonusGoal1Name: '',
  bonusGoalAmount1: 1,
  bonusGoalEndDate: new Date(),
  campaignEndTime: new Date(),
  campaignId: (0, _v2.default)(),
  campaignPage: '',
  campaignStartTime: new Date(),
  donationMedalAmount: 0,
  donationTrophyAmount: 0,
  enableAnonymous: true,
  enableCheckOption: true,
  enableCurrency: false,
  gateway: 'Stripe',
  goalAmount: 1,
  hideCalculator: false,
  isFeatured: false,
  matchFactor: 1,
  name: '',
  preCampaignEndDate: new Date(),
  preCampaignStartDate: new Date(),
  raffleOptions: '',
  recieptExtraInfo: '',
  shareAmount: 0,
  shareCount: 0,
  shareGoal: 0,
  splashMerchantId: '',
  status: 'in progress',
  stripeConnectedAccountId: '',
  enableTransactionCost: true,
  transactionCostFlat: 0.55,
  transactionCostPercent: 3.99,
  videoURL: '',
  preCampaignVideoURL: '',
  campaignCurrency: 'usd'
};
