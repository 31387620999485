import React from 'react';
import { withStyles, Table, TableBody, TableCell, TableHead, TableRow, Paper, TablePagination, Input, Switch } from '@material-ui/core';
import styles from './style';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit,faTrashAlt, faSave} from '@fortawesome/free-solid-svg-icons';

const paperStyle = {
  caption: {
    fontSize: 14,
  },
  toolbar: {
    padding: 0,
    height: 40,
    minHeight: 40
  },
  colorSwitchBase: {
    '&$colorChecked': {
      color: '#008eb5',
      '& + $colorBar': {
        backgroundColor: '#008eb5',
      },
    },
  },
  colorBar: {},
  colorChecked: {},
};

let columns = ['Team Name', 'Page Name', 'Campaign Goal', 'Text 1', 'Disable/Enable', 'Edit', 'Delete'];
function TeamTable({ teams, onEnableDisableClick, handleChangePage, rowsPerPage, page, onEditTeamIconClick,
  editTeamName, handleEditInputChange, onSaveTeamIconClick,
  editCampaignGoal, editText1, onDeleteTeamIconClick, classes }) {
                 
  return (
    <div>
      <Paper style={styles.tableRoot}>
        <Table>
          <TableHead>
            <TableRow>
              {columns.map((columnName,index)=>
                <TableCell key ={index} className="team-table-cell-head team-table-cell" align={index === 3 ? 'left' : ' '} >{columnName}</TableCell>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {teams.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((team, index) => (
              <TableRow key={index} style = {{ backgroundColor :  index % 2 === 0? '#fafafa' : 'white'}}>
                <TableCell component="th" className="team-table-cell">
                  { team.edit ?
                    <Input value ={editTeamName} name= "editTeamName" onChange ={handleEditInputChange}/> :
                    team.teamName
                  }
                </TableCell>
                <TableCell component="th" className="team-table-cell">/{team.pageName}</TableCell>
                <TableCell component="th" className="team-table-cell">
                  { team.edit ?
                    <Input value={editCampaignGoal} name="editCampaignGoal" onChange={handleEditInputChange}/> :
                    team.goalAmount
                  }
                </TableCell>
                <TableCell component="th" className="team-table-cell">
                  { team.edit ?
                    <Input value ={editText1} name ="editText1" onChange ={handleEditInputChange}/> :
                    team.text1
                  }
                </TableCell>
                <TableCell component="th" className="team-table-cell">
                  <Switch
                    classes={{ switchBase: classes.colorSwitchBase, checked: classes.colorChecked, bar: classes.colorBar, }}
                    checked={team.isActive === 'true' || team.isActive === true}
                    name='status'
                    onChange={() => onEnableDisableClick(team)}
                  />
                </TableCell>
                <TableCell component="th" className="team-table-cell">
                  { team.edit ?
                    <FontAwesomeIcon icon={faSave} color ='#aaabaa' onClick ={(e) => onSaveTeamIconClick(e, team)}/> :
                    <FontAwesomeIcon icon={faEdit} color ='#aaabaa' onClick ={() => onEditTeamIconClick(team)}/>
                  }
                </TableCell>
                <TableCell component="th" className="team-table-cell">
                  <FontAwesomeIcon icon={faTrashAlt} color ='#aaabaa' onClick ={() => onDeleteTeamIconClick(team.id)}/>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Paper>
      <TablePagination
        classes={{ caption: classes.caption, toolbar: classes.toolbar }}
        component="div"
        count={teams.length}
        rowsPerPage={rowsPerPage}
        page={page}
        rowsPerPageOptions={[]} 
        onChangePage={handleChangePage}
      />
    </div>
  );
}

export default withStyles(paperStyle)(TeamTable);
