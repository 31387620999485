import React, { Component } from 'react';
import CampaignSlider from '../../components/Slider/Slider'

import './Casestudy.scss';

export default class Casestudy extends Component {
  render() {
    return(
      <div>
        <section className="case_study">
          <div className="head_section">
            <div className="container-fluid">
              <div className="row">
                <div className="col-md-4 col-md-offset-8 col-sm-6 col-sm-offset-6 col-xs-10 col-xs-offset-2 p0">
                  <div className="heading_tag text-left text-uppercase" style={{ "backgroundImage": "url(/images/bluebg2.png)" }}>Case Study</div>
                </div>
              </div>
            </div>
          </div>
          <div className="container">
            <CampaignSlider />
          </div>
        </section>
      </div>
    );
  }
}
