import React, { Component } from "react";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import InputBase from "@material-ui/core/InputBase";
import Checkbox from "@material-ui/core/Checkbox";
import NativeSelect from "@material-ui/core/NativeSelect";
import Radio from "@material-ui/core/Radio";
import FormGroup from "@material-ui/core/FormGroup";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import { TextValidator } from "react-material-ui-form-validator";
import './userInfo.scss'

class UserInfo extends Component {
  prev = "";
  handleBlur = e => {
    this.refs[e.target.name].validate(e.target.value, true);
  };

  hasWhiteSpace = (s) => {
    return s.indexOf(' ') >= 0;
  }

  inputChange = (e) => {

    let res = this.hasWhiteSpace(e.target.value);
    if (!this.emailInvalid && !res) {
      this.props.emailValidChange('email', '');
      this.props.emailValidChange('email', e.target.value.trim());
      this.prev = e.target.value;
    }
    else {

      this.props.emailValidChange('email', this.prev);
      this.emailInvalid = false;
      //this.prev = e.target.value.trim();
    }
  }



  twoCalls = (event) => {

    var k = event ? event.which : window.event.keyCode;
    if (k == 32) {

      this.inputChange(event);
      this.prev = event.target.value;
      this.emailInvalid = true;
      return;
    }
  }


  render() {
    const {
      inputChange,
      inputs,
      handleMultipleRaffle,
      donation: { amount, duration },
      data,
      teams,
      team,
      handleTeam
    } = this.props;
    const {
      firstName,
      lastName,
      email,
      donorsListName,
      message,
      method,
      displayName,
      specialNotes
    } = inputs.toJS();
    let raffle = [];
    let donation_amount = 0;
    if (data && data.raffleOptions) {
      raffle = data.raffleOptions.split(",");
      donation_amount = parseInt(duration)
        ? parseInt(amount * duration)
        : parseInt(amount);
    }

    return (
      <Paper className="checkout user-info">
        <div className="number">
          <span>1</span>
        </div>
        <Grid container spacing={8}>
          <Grid item xs={12} sm={6}>
            <label className="form-name">
              First Name <span>*</span>
            </label>
            <TextValidator
              autoComplete="nope"
              validators={["required"]}
              errorMessages={["This field is required"]}
              ref="firstName"
              onBlur={this.handleBlur}
              placeholder="First Name"
              className="checkout_input"
              value={firstName}
              name="firstName"
              onChange={inputChange}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <label className="form-name">
              Last Name <span>*</span>
            </label>
            <TextValidator
              autoComplete="nope"
              validators={["required"]}
              errorMessages={["This field is required"]}
              ref="lastName"
              onBlur={this.handleBlur}
              placeholder="Last Name"
              className="checkout_input"
              value={lastName}
              name="lastName"
              onChange={inputChange}
            />
          </Grid>
          <Grid item xs={12}>
            <label className="form-name">
              Email Address(To Receive Your Donation Receipt) <span>*</span>
            </label>
            <TextValidator
              autoComplete="nope"
              validators={["required", "isEmail"]}
              errorMessages={[
                "This field is required",
                "Invalid email address"
              ]}
              ref="email"
              type="email"
              onBlur={this.handleBlur}
              placeholder="Email – To Receive Your Donation Receipt"
              className="checkout_input"
              value={email}
              name="email"
              onChange={this.inputChange}
              onKeyDown={this.twoCalls}
            />
          </Grid>
          {data && !data.teamInfo && <Grid item xs={12} className="select-option">
            <label className="form-name">
            Display my donation on the following  volunteer's sub-campaign page also
            </label>
            <NativeSelect
              disableUnderline={true}
              className="select"
              name="team"
              onChange={handleTeam}
              value={team.id}
            >
              <option value="">Select a Volunteer</option>
              {teams.map(e =>
              <option value={e.id} key={e.id}>{e.teamName}</option>
              )}
            </NativeSelect>
                      </Grid>}
          <Grid item xs={12}>
            <label className="form-name">Display My Name As Anonymous</label>
            <Checkbox
              color="default"
              name="displayName"
              checked={displayName}
              onChange={inputChange}
            />
          </Grid>
          {!displayName && (
            <Grid item xs={12}>
              <label className="form-name">
                | OR | Name(Alias) to be displayed in Donors List <span></span>
              </label>
              <TextValidator
                autoComplete="nope"
                errorMessages={["This field is required"]}
                ref="donorsListName"
                onBlur={this.handleBlur}
                placeholder="Alias"
                className="checkout_input"
                value={donorsListName}
                name="donorsListName"
                onChange={inputChange}
              />
            </Grid>
          )}
          <Grid item xs={6}>
            <label className="form-name">Message/Dedication (optional) </label>
            <InputBase
              multiline={true}
              rows={3}
              placeholder="Message/Dedication (Optional)"
              className="input-base"
              value={message}
              name="message"
              onChange={inputChange}
            />
            <p className="message">
              This message will be displayed on the public campaign page.
            </p>
          </Grid>
          <Grid item xs={6}>
            <label className="form-name">Special Request</label>
            <InputBase
              multiline={true}
              rows={3}
              className="input-base"
              value={specialNotes}
              name="specialNotes"
              onChange={inputChange}
            />
            <p>
              This message will not be displayed in the campaign page and will
              be privately submitted to the admistrative of the organization
            </p>
          </Grid>
          {data && data.raffleOptions && donation_amount >= 100 && (
            <Grid item xs={12}>
              <label className="form-name">Choose your Raffle Categories</label>
              <FormGroup className="raffle-check" row>
                {raffle.map(r => (
                  <FormControlLabel
                    key={r}
                    control={
                      <Checkbox
                        color="default"
                        onChange={handleMultipleRaffle}
                        value={r}
                      />
                    }
                    label={r}
                  />
                ))}
              </FormGroup>
            </Grid>
          )}
          <Grid item xs={12}>
            <label className="form-name">Payment Method</label>
            <FormControl className="method" component="fieldset">
              <RadioGroup
                name="method"
                value={method}
                row
                onChange={inputChange}
              >
                <FormControlLabel
                  className="payment-method"
                  value="credit card"
                  label="Credit Card"
                  labelPlacement="end"
                  control={<Radio color="default" />}
                />
                <FormControlLabel
                  className="payment-method"
                  value="check"
                  label="Cheque/Check"
                  labelPlacement="end"
                  control={<Radio color="default" />}
                />
              </RadioGroup>
            </FormControl>
          </Grid>
        </Grid>
      </Paper>
    );
  }
}

export { UserInfo };
