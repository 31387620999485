export const reportFilters = state => {
  const { searchItem, donor, payment, campaignReports } = state;
  let filteredReports = campaignReports;

  if (searchItem) {
    filteredReports = filteredReports.filter(
      report =>
        report.fullname.toUpperCase().indexOf(searchItem.toUpperCase()) > -1 ||
        report.donationDate.indexOf(searchItem) > -1 ||
        (report.alias &&
          report.alias.toUpperCase().indexOf(searchItem.toUpperCase()) > -1) ||
        report.donationAmount === searchItem ||
        (report.teamId &&
          report.teamId.toUpperCase().indexOf(searchItem.toUpperCase()) > -1) ||
        (report.billingPhone && report.billingPhone.indexOf(searchItem) > -1) ||
        (report.billingAddress &&
          report.billingAddress
            .toUpperCase()
            .indexOf(searchItem.toUpperCase()) > -1) ||
        (report.dedicationMessage &&
          report.dedicationMessage
            .toUpperCase()
            .indexOf(searchItem.toUpperCase()) > -1)
    );
  }
  if (donor === "allDonors" && payment === "oneTime")
    filteredReports = filteredReports.filter(
      report => report.mode === "One Time"
    );
  if (donor === "allDonors" && payment === "recurring")
    filteredReports = filteredReports.filter(
      report => report.mode === "Recurring"
    );
  if (donor === "allDonors" && payment === "manual")
    filteredReports = filteredReports.filter(report => report.isManual);
  if (donor === "anonymous" && payment === "allPayments")
    filteredReports = filteredReports.filter(
      report => report.alias === "" || report.alias === "Anonymous"
    );
  if (donor === "aliasDonors" && payment === "allPayments")
    filteredReports = filteredReports.filter(
      report => report.alias !== "" && report.alias !== "Anonymous"
    );
  if (donor === "anonymous" && payment === "oneTime")
    filteredReports = filteredReports.filter(
      report =>
        (report.alias === "" || report.alias === "Anonymous") &&
        report.mode === "One Time"
    );
  if (donor === "aliasDonors" && payment === "oneTime")
    filteredReports = filteredReports.filter(
      report =>
        report.alias !== "" &&
        report.alias !== "Anonymous" &&
        report.mode === "One Time"
    );
  if (donor === "anonymous" && payment === "recurring")
    filteredReports = filteredReports.filter(
      report =>
        (report.alias === "" || report.alias === "Anonymous") &&
        report.mode === "Recurring"
    );
  if (donor === "aliasDonors" && payment === "recurring")
    filteredReports = filteredReports.filter(
      report =>
        report.alias !== "" &&
        report.alias !== "Anonymous" &&
        report.mode === "Recurring"
    );
  if (donor === "anonymous" && payment === "manual")
    filteredReports = filteredReports.filter(
      report =>
        (report.alias === "" || report.alias === "Anonymous") && report.isManual
    );
  if (donor === "aliasDonors" && payment === "manual")
    filteredReports = filteredReports.filter(
      report =>
        report.alias !== "" && report.alias !== "Anonymous" && report.isManual
    );

  return filteredReports;
};
