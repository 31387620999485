import React, { Component } from "react";
import { Grid, Switch, NativeSelect, Paper, FormControl, FormControlLabel, FormGroup, Checkbox, withStyles } from "@material-ui/core";
import { Formik } from "formik";
import FileUploader from "react-firebase-file-uploader";
import DatePicker from "react-datepicker";
import { Editor } from 'react-draft-wysiwyg';
import { initialValues } from "./InitialValues";
import { currencies } from "../../Common/currencies";
import { campaignSchema } from "../../YupValidation/CampaignSchema";
import firebase from "../../apis/firebase.apis";
import { BASE_URL } from './../../apis/backEnd.apis';
import request from 'request';

import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import "react-datepicker/dist/react-datepicker.css";
import "./CampaignForm.scss";

const paperStyle = {
  colorSwitchBase: {
    "&$colorChecked": {
      color: "#008eb5",
      "& + $colorBar": {
        backgroundColor: "#008eb5"
      }
    }
  },
  colorBar: {},
  colorChecked: {}
};
const campStatus = ["in progress", "pre-campaign", "completed"];
const campMode = ["monthly", "oneTime", "e-commerce"];

class CampaignForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      imageURL: "",
      bonusRoundMainContent: "",
      bonusGoal1Image: "",
      campaign: initialValues,
      htmlBody: "",
      email: '',
      userId: '',
      password: '',
      currency: [{
        name: 'United States Dollar (USD)',
        code: 'usd',
        sign: '$'
      }]
    }
    this.userId = '';
    //console.log('constructor ----- organization', this.props.organization);
  }

  handleChange = (e) => {
    const { name, value } = e.target;
    this.setState({
      [name]: value
    })
  }

  saveAdmin = (e) => {
    e.preventDefault();
    const { showSnackBar } = this.props;
    let email = this.state.email;
    let password = this.state.password;
    let userId = this.state.userId;
    if (password.length < 6) {

      showSnackBar("Password should have atleast 6 characters", true);
      return;
    }
    this.props.showOverlay();
    request(
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({ userId, email, password }),
        url: BASE_URL + "users/saveAdmin"
      },
      (error, response, body) => {
        let res = JSON.parse(response.body);
        if (res.success) {
          this.props.hideOverlay();
          alert(res.message);

        } else {
          this.props.hideOverlay();
          console.log(error);
          alert(res.message);

        }
      }
    );
  }



  componentDidUpdate(oldProps) {
    const { campaign } = this.props;
    const { organization } = this.props;
    //console.log('componentDidUpdate ------ organization', organization);
    const FIRESTORE = firebase.firestore();
    if (oldProps.campaign !== campaign) {
      if (campaign.currency && typeof campaign.currency === 'string') {
        let c = {};
        if (campaign.currency !== 'usd') {
          c = currencies.find(e => e.code === campaign.currency)
        }

        campaign.currency = [{
          name: 'United States Dollar (USD)',
          code: 'usd',
          sign: '$'
        }, c]
      }
      FIRESTORE.collection("users").where("organizationId", "==", organization.id).where("role", "==", "admin").get().then(response => {
        response.docs.map(item => {
          let user = item.data();
          const uid = item.id;
          const emailId = user.email;
          this.setState({
            campaign: Object.keys(campaign).length > 0 ? campaign : initialValues,
            imageURL: campaign.imageURL || '',
            bonusGoal1Image: campaign.bonusGoal1Image || '',
            email: emailId,
            userId: uid,
            bonusRoundMainContent: campaign.bonusRoundMainContent || '',
            currency: campaign.currency || [{
              name: 'United States Dollar (USD)',
              code: 'usd',
              sign: '$'
            }]
          });
        })
      })
    }
  }

  formSubmit = (data) => {
    const { saveCampaign } = this.props;
    const { imageURL, htmlBody, bonusRoundMainContent, bonusGoal1Image, currency } = this.state;
    data.mainContent = {
      htmlBody,
      imageURL
    }
    if (!data.enableTransactionCost) {
      data.transactionCostPercent = 1;
      data.transactionCostFlat = 0
    }
    data.bonusRoundMainContent = bonusRoundMainContent;
    data.bonusGoal1Image = bonusGoal1Image;
    data.currency = currency;
    if (data.imageURL) {
      delete data.imageURL;
    }
    saveCampaign(data);
  }

  onUploadError = error => {
    console.log('error', error);
    const { showSnackBar } = this.props;
    showSnackBar("Error uploading file", true);
  };

  onUploadSuccess = (source, filename, name) => {
    const { showSnackBar } = this.props;
    firebase
      .storage()
      .ref(source)
      .child(filename)
      .getDownloadURL()
      .then(url => {
        this.setState({ [name]: url }, () =>
          showSnackBar("File uploaded", false)
        )
      })
  };

  removeMainImg = () => {
    this.setState({ imageURL: '' });
  }

  removeBonusImg = () => {
    this.setState({ bonusGoal1Image: '' });
  }

  handleHtmlBody = (e) => {
    this.setState({ htmlBody: e });
  }

  handleCurrency = (e) => {
    const c = [...this.state.currency];
    const { value } = e.target;
    let f = currencies.find(e => e.code === value);
    const i = c.findIndex(e => e.code === f.code);
    if (value !== 'usd') {
      if (i > -1) {
        c.splice(i, 1);
      } else {
        c.push(f)
      }
    }

    this.setState({ currency: c });
  }

  render() {
    const { imageURL, bonusRoundMainContent, bonusGoal1Image, campaign, htmlBody, currency, email, password } = this.state;
    const { classes, handleSuperDonors, handleItems, handleCalculatorSetup, changeMode, mode, organization, handleOrganizationChange } = this.props;
    const switchStyle = {
      switchBase: classes.colorSwitchBase,
      checked: classes.colorChecked,
      bar: classes.colorBar
    };
    let startTime, endTime, bonusGoalEndDate;

    return (
      <Formik
        validationSchema={campaignSchema}
        onSubmit={this.formSubmit}
        initialValues={campaign}
        enableReinitialize={true}
      >
        {({ handleSubmit, handleChange, values, errors, setFieldValue }) => {
          if (values.campaignStartTime != "Invalid Date") {
            startTime = values.campaignStartTime;
          }
          if (values.campaignEndTime != "Invalid Date") {
            endTime = values.campaignEndTime;
          }
          if (values.bonusGoalEndDate != "Invalid Date") {
            bonusGoalEndDate = values.bonusGoalEndDate;
          }
          return (
            <form id="new-campaign-form" onSubmit={handleSubmit}>
              <Paper className="paper">
                <Grid container spacing={0}>

                  <div className="title">Organization Details</div>

                  <Grid className="item column" item xs={12} sm={6}>
                    <label>Organization Tax ID</label>
                    <input
                      className="input"
                      name="organizationTaxId"
                      onChange={handleOrganizationChange}
                      value={organization.organizationTaxId}
                    />
                  </Grid>

                  <Grid className="item column" item xs={12} sm={6}>
                    <label>Organization Phone</label>
                    <input
                      className="input"
                      name="organizationPhone"
                      onChange={handleOrganizationChange}
                      value={organization.organizationPhone}
                    />
                  </Grid>

                  <Grid className="item column" item xs={12}>
                    <label>Organization Address</label>
                    <textarea
                      row={2}
                      name="organizationAddress"
                      onChange={handleOrganizationChange}
                      value={organization.organizationAddress}
                    />
                  </Grid>

                  <Grid className="item column" item xs={12}>
                    <label>ID (Email)</label>
                    <textarea
                      row={2}
                      name="email"
                      onChange={this.handleChange}
                      value={email}
                    />
                  </Grid>


                  <Grid className="item column" item xs={12}>
                    <label>Set New Password</label>
                    <textarea
                      row={2}
                      name="password"
                      onChange={this.handleChange}
                      value={password}
                    />
                  </Grid>

                  <div className="submit-btn">
                    <button onClick={this.saveAdmin}>Save User Credentials</button>
                  </div>

                  <div className="title">Campaign Details</div>

                  <Grid className="item column" item xs={12}>
                    <label>Campaign Title</label>
                    <input
                      className="input"
                      name="name"
                      onChange={handleChange}
                      value={values.name}
                    />
                    <p>{errors.name}</p>
                  </Grid>
                  <Grid className="item" item xs={12}>
                    <label className="switch-label">Feature This Campaign on Homepage</label>
                    <Switch
                      classes={switchStyle}
                      name="isFeatured"
                      onChange={handleChange}
                      checked={values.isFeatured}
                      value={values.isFeatured}
                    />
                  </Grid>
                  <Grid className="item" item xs={12}>
                    <label className="switch-label">Auto Capture</label>
                    <Switch
                      classes={switchStyle}
                      name="autoCapture"
                      onChange={handleChange}
                      checked={values.autoCapture}
                      value={values.autoCapture}
                    />
                  </Grid>
                  <Grid className="item" item xs={12}>
                    <label className="switch-label">Enable Anonymous</label>
                    <Switch
                      classes={switchStyle}
                      name="enableAnonymous"
                      onChange={handleChange}
                      checked={values.enableAnonymous}
                      value={values.enableAnonymous}
                    />
                  </Grid>
                  <Grid className="item" item xs={12}>
                    <label className="switch-label">Enable Check Option</label>
                    <Switch
                      classes={switchStyle}
                      name="enableCheckOption"
                      onChange={handleChange}
                      checked={values.enableCheckOption}
                      value={values.enableCheckOption}
                    />
                  </Grid>
                  <Grid className="item column" item xs={12} sm={6}>
                    <label>Campaign Page</label>
                    <div className='camp-name'>
                      <label>https://pledje.com/</label>
                      <input
                        className="input"
                        name="campaignPage"
                        onChange={handleChange}
                        value={values.campaignPage}
                      />
                    </div>
                    <p>{errors.campaignPage}</p>
                  </Grid>
                  <Grid className="item column" item xs={12} sm={6}>
                    <label>Campaign Currency</label>
                    <NativeSelect
                      disableUnderline={true}
                      className="select"
                      name="campaignCurrency"
                      onChange={handleChange}
                      value={values.campaignCurrency}
                    >
                      {currencies.map(c => <option value={c.code} key={c.code}>{c.name}</option>)}
                    </NativeSelect>
                  </Grid>
                  <Grid className="item" item xs={12}>
                    <label className="switch-label">Enable Currency</label>
                    <Switch
                      classes={switchStyle}
                      name="enableCurrency"
                      onChange={handleChange}
                      checked={values.enableCurrency}
                      value={values.enableCurrency}
                    />
                  </Grid>
                  {values.enableCurrency && <Grid item xs={12}>
                    <FormControl component="fieldset">
                      <FormGroup row>
                        {currencies.map(c => {
                          return (
                            <FormControlLabel
                              control={
                                <Checkbox
                                  name="currency"
                                  color="default"
                                  checked={currency && currency.findIndex(e => e.code === c.code) > -1}
                                  onChange={this.handleCurrency}
                                  value={c.code}
                                />
                              }
                              key={c.code}
                              label={c.name}
                            />
                          )
                        })}
                      </FormGroup>
                    </FormControl>
                  </Grid>}
                  {!imageURL &&
                    <Grid className="item column" item xs={12}>
                      <label>Main Page Image</label>
                      <FileUploader
                        accept="image/*"
                        name="imageURL"
                        randomizeFilename
                        storageRef={firebase.storage().ref("CampaignPicture")}
                        onUploadError={this.onUploadError}
                        onUploadSuccess={filename => this.onUploadSuccess("CampaignPicture", filename, "imageURL")}
                      />
                    </Grid>
                  }
                  {imageURL &&
                    <Grid className="item column main-image" item xs={12}>
                      <label>Main Page Image</label>
                      <div className="image-container">
                        <img src={imageURL} alt="Campaign" />
                      </div>
                      <button onClick={this.removeMainImg}>Remove Image</button>
                    </Grid>
                  }
                  <Grid className="item column" item xs={12}>
                    <label>Campaign Content</label>
                    <Editor
                      editorState={htmlBody}
                      toolbarClassName="toolbar"
                      editorClassName="editor"
                      onEditorStateChange={this.handleHtmlBody}
                      toolbar={{
                        inline: { inDropdown: true },
                        list: { inDropdown: true },
                        textAlign: { inDropdown: true },
                        link: { inDropdown: true },
                        history: { inDropdown: true }
                      }}
                    />
                  </Grid>
                  <Grid className="item column" item xs={12} sm={6}>
                    <label>Campaign Start Time</label>
                    <DatePicker
                      className="input"
                      name="campaignStartTime"
                      selected={startTime}
                      onChange={e => setFieldValue('campaignStartTime', e)}
                      showTimeSelect
                      timeFormat="HH:mm:ss"
                      timeIntervals={15}
                      dateFormat="MMMM d, yyyy h:mm aa"
                      timeCaption="Time"
                    />
                    {/* {startTime ?
                      <input

                        className="input"


                        value={startTime.toISOString()}
                      /> : null} */}
                    <p>{errors.campaignStartTime}</p>
                  </Grid>
                  <Grid className="item column" item xs={12} sm={6}>
                    <label>Campaign End Time</label>
                    <DatePicker
                      className="input"
                      name="campaignEndTime"
                      selected={endTime}
                      onChange={e => setFieldValue('campaignEndTime', e)}
                      showTimeSelect
                      timeFormat="HH:mm"
                      timeIntervals={15}
                      dateFormat="MMMM d, yyyy h:mm aa"
                      timeCaption="Time"
                    />
                    <p>{errors.campaignEndTime}</p>
                  </Grid>
                  <Grid className="item column" item xs={12} sm={6}>
                    <label>Campaign Status</label>
                    <NativeSelect
                      disableUnderline={true}
                      className="select"
                      name="status"
                      onChange={handleChange}
                      value={values.status}
                    >
                      {campStatus.map(c => <option value={c} key={c}>{c}</option>)}
                    </NativeSelect>
                    <p>{errors.status}</p>
                  </Grid>
                  <Grid className="item column" item xs={12} sm={6}>
                    <label>Campaign Mode</label>
                    <NativeSelect
                      disableUnderline={true}
                      className="select"
                      name="mode"
                      onChange={changeMode}
                      value={mode}
                    >
                      {campMode.map(c => <option value={c} key={c}>{c}</option>)}
                    </NativeSelect>
                  </Grid>
                  <Grid className="item column" item xs={12} sm={6}>
                    <label>Goal Amount</label>
                    <input
                      type="tel"
                      className="input"
                      name="goalAmount"
                      onChange={handleChange}
                      value={values.goalAmount}
                    />
                    <p>{errors.goalAmount}</p>
                  </Grid>
                  <Grid className="item column" item xs={12} sm={6}>
                    <label>Match Factor</label>
                    <input
                      type="tel"
                      className="input"
                      name="matchFactor"
                      onChange={handleChange}
                      value={values.matchFactor}
                    />
                    <p>{errors.matchFactor}</p>
                  </Grid>
                  <Grid className="item column" item xs={12} sm={6}>
                    <label>Share Amount</label>
                    <input
                      type="tel"
                      className="input"
                      name="shareAmount"
                      onChange={handleChange}
                      value={values.shareAmount}
                    />
                    <p>{errors.shareAmount}</p>
                  </Grid>
                  <Grid className="item column" item xs={12} sm={6}>
                    <label>Share Goal</label>
                    <input
                      type="tel"
                      className="input"
                      name="shareGoal"
                      onChange={handleChange}
                      value={values.shareGoal}
                    />
                    <p>{errors.shareGoal}</p>
                  </Grid>
                  <Grid className="item column" item xs={12} sm={6}>
                    <label>Donation Trophy Amount</label>
                    <input
                      type="tel"
                      className="input"
                      name="donationTrophyAmount"
                      onChange={handleChange}
                      value={values.donationTrophyAmount}
                    />
                    <p>{errors.donationTrophyAmount}</p>
                  </Grid>
                  <Grid className="item column" item xs={12} sm={6}>
                    <label>Donation Medal Amount</label>
                    <input
                      type="tel"
                      className="input"
                      name="donationMedalAmount"
                      onChange={handleChange}
                      value={values.donationMedalAmount}
                    />
                    <p>{errors.donationMedalAmount}</p>
                  </Grid>
                  <Grid className="item column" item xs={12} sm={6}>
                    <label>Video URL</label>
                    <input
                      className="input"
                      name="videoURL"
                      onChange={handleChange}
                      value={values.videoURL}
                    />
                    <p>{errors.videoURL}</p>
                  </Grid>
                  <Grid className="item column" item xs={12} sm={6}>
                    <label>Receipt Extra Info</label>
                    <input
                      className="input"
                      name="recieptExtraInfo"
                      onChange={handleChange}
                      value={values.recieptExtraInfo}
                    />
                    <p>{errors.recieptExtraInfo}</p>
                  </Grid>
                  <Grid className="item" item xs={12}>
                    <label className="switch-label">Hide Calculator When Campaign Ends</label>
                    <Switch
                      classes={switchStyle}
                      name="hideCalculator"
                      onChange={handleChange}
                      checked={values.hideCalculator}
                      value={values.hideCalculator}
                    />
                  </Grid>
                  <Grid className="item column" item xs={12}>
                    <label>Raffle Options</label>
                    <textarea
                      placeholder="Please enter comma separated values"
                      rows={2}
                      className="input"
                      name="raffleOptions"
                      onChange={handleChange}
                      value={values.raffleOptions}
                    />
                    <p>{errors.raffleOptions}</p>
                  </Grid>
                  <Grid className="item camp-addons" item xs={12}>
                    {mode !== 'e-commerce' && <div onClick={handleCalculatorSetup}>Calculator Setup</div>}
                    <div onClick={handleSuperDonors}>Add Super Donors</div>
                    <div onClick={handleItems}>Add Items</div>
                  </Grid>

                  <div className="title">Bonus Round</div>

                  <Grid className="item column" item xs={12} sm={6}>
                    <label>Bonus Goal Name</label>
                    <input
                      type="tel"
                      className="input"
                      name="bonusGoal1Name"
                      onChange={handleChange}
                      value={values.bonusGoal1Name}
                    />
                    <p>{errors.bonusGoal1Name}</p>
                  </Grid>
                  <Grid className="item column" item xs={12} sm={6}>
                    <label>Bonus Goal Amount</label>
                    <input
                      type="tel"
                      className="input"
                      name="bonusGoalAmount1"
                      onChange={handleChange}
                      value={values.bonusGoalAmount1}
                    />
                    <p>{errors.bonusGoalAmount1}</p>
                  </Grid>
                  <Grid className="item column" item xs={12} sm={6}>
                    <label>Bonus Goal Multiply Factor</label>
                    <input
                      type="tel"
                      className="input"
                      name="bonusGoal1MatchFactor"
                      onChange={handleChange}
                      value={values.bonusGoal1MatchFactor}
                    />
                    <p>{errors.bonusGoal1MatchFactor}</p>
                  </Grid>
                  <Grid className="item column" item xs={12} sm={6}>
                    <label>Bonus Goal End Time</label>
                    <DatePicker
                      className="input"
                      name="bonusGoalEndDate"
                      selected={bonusGoalEndDate}
                      onChange={e => setFieldValue('bonusGoalEndDate', e)}
                      showTimeSelect
                      timeFormat="HH:mm"
                      timeIntervals={15}
                      dateFormat="MMMM d, yyyy h:mm aa"
                      timeCaption="Time"
                    />
                    <p>{errors.bonusGoalEndDate}</p>
                  </Grid>
                  <Grid className="item column" item xs={12}>
                    <label>Bonus Round Main Content</label>
                    <FileUploader
                      accept="image/*"
                      name="bonusRoundMainContent"
                      randomizeFilename
                      storageRef={firebase.storage().ref("CampaignPicture")}
                      onUploadError={this.onUploadError}
                      onUploadSuccess={filename => this.onUploadSuccess("CampaignPicture", filename, "bonusRoundMainContent")}
                    />
                  </Grid>
                  {bonusRoundMainContent &&
                    <Grid item xs={12}>
                      <div className="image-container">
                        <img src={bonusRoundMainContent} alt="Bonus Main" />
                      </div>
                    </Grid>
                  }
                  {!bonusGoal1Image &&
                    <Grid className="item column" item xs={12} sm={6} md={4}>
                      <label>Bonus Goal Image</label>
                      <FileUploader
                        accept="image/*"
                        name="bonusGoal1Image"
                        randomizeFilename
                        storageRef={firebase.storage().ref("CampaignPicture")}
                        onUploadError={this.onUploadError}
                        onUploadSuccess={filename => this.onUploadSuccess("CampaignPicture", filename, "bonusGoal1Image")}
                      />
                    </Grid>
                  }
                  {bonusGoal1Image &&
                    <Grid className="item column main-image" item xs={12}>
                      <label>Bonus Goal Image</label>
                      <div className="image-container">
                        <img src={bonusGoal1Image} alt="Bonus Goal" />
                      </div>
                      <button onClick={this.removeBonusImg}>Remove Button</button>
                    </Grid>
                  }

                  <div className="title">Pre-Campaign Details</div>

                  <Grid className="item column" item xs={12}>
                    <label>Pre-Campaign Video URL</label>
                    <input
                      className="input"
                      name="preCampaignVideoURL"
                      onChange={handleChange}
                      value={values.preCampaignVideoURL}
                    />
                    <p>{errors.preCampaignVideoURL}</p>
                  </Grid>
                  
                  <div className="title">Campaign Social Share MetaData</div>
                  <Grid className="item column" item xs={12}>
                    <label>Description</label>
                    <input
                      className="input"
                      name="desc"
                      onChange={handleChange}
                      value={values.desc}
                    />
                  </Grid>
                 

                  <div className="title">Payment Details</div>

                  <Grid className="item column" item xs={12} sm={6} md={4}>
                    <label>Merchant Gateway</label>
                    <NativeSelect
                      disableUnderline={true}
                      className="select"
                      name="gateway"
                      onChange={handleChange}
                      value={values.gateway}
                    >
                      <option value="Stripe">Stripe</option>
                      <option value="Splash">Splash</option>
                    </NativeSelect>
                    <p>{errors.gateway}</p>
                  </Grid>
                  <Grid className="item column" item xs={12} sm={6} md={4}>
                    <label>Splash Merchant ID</label>
                    <input
                      className="input"
                      name="splashMerchantId"
                      onChange={handleChange}
                      value={values.splashMerchantId}
                    />
                    <p>{errors.splashMerchantId}</p>
                  </Grid>
                  <Grid className="item column" item xs={12} sm={6} md={4}>
                    <label>Stripe Connected Account ID</label>
                    <input
                      className="input"
                      name="stripeConnectedAccountId"
                      onChange={handleChange}
                      value={values.stripeConnectedAccountId}
                    />
                    <p>{errors.stripeConnectedAccountId}</p>
                  </Grid>
                  <Grid className="item" item xs={12}>
                    <label className="switch-label">Transaction Cost</label>
                    <Switch
                      classes={switchStyle}
                      name="enableTransactionCost"
                      onChange={handleChange}
                      checked={values.enableTransactionCost}
                      value={values.enableTransactionCost}
                    />
                  </Grid>
                  {values.enableTransactionCost !== false &&
                    <Grid className="item column" item xs={12} sm={6}>
                      <label>Transaction Cost Percent</label>
                      <input
                        type="tel"
                        className="input"
                        name="transactionCostPercent"
                        onChange={handleChange}
                        value={values.transactionCostPercent}
                      />
                      <p>{errors.transactionCostPercent}</p>
                    </Grid>
                  }
                  {values.enableTransactionCost !== false &&
                    <Grid className="item column" item xs={12} sm={6}>
                      <label>Transaction Cost Flat</label>
                      <input
                        type="tel"
                        className="input"
                        name="transactionCostFlat"
                        onChange={handleChange}
                        value={values.transactionCostFlat}
                      />
                      <p>{errors.transactionCostFlat}</p>
                    </Grid>
                  }
                  <div className="submit-btn">
                    <button type='submit'>Submit</button>
                  </div>
                </Grid>
              </Paper>
            </form>
          );
        }}
      </Formik>
    );
  }
}

export default withStyles(paperStyle)(CampaignForm);
