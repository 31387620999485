import { BASE_URL } from "../apis/backEnd.apis";
import axios from "axios";
import { config } from "../config";
const token = config.apiAccessKey;

export const getShareCount = params =>
  axios.get(
    `${BASE_URL}campaigns/campaigns/getShareCount/${params.organization}/${
      params.campaign
    }`,
    {
      headers: { "access-token": `${token}` }
    }
  );
export const updateShareCount = body =>
  axios.post(`${BASE_URL}campaigns/updateShareCount`, body, {
    headers: { "access-token": `${token}` }
  });
