import React from 'react';
import { CampaignVideo } from '../Campaign/CampaignVideo';

export const Member = ({ team, status }) => {
  return(
    <section className={!team.videoURL1 ? 'member team-center' : 'member'}>
      <div className='info'>
        { team.avatar && <img src={team.avatar} alt='Avatar' />}
        <h4>{team.teamName}</h4>
      </div>
      <div id='description'>
        { team.videoURL1 && <CampaignVideo video={team.videoURL1} status={status} />}
        { team.videoURL1 && <div className='desc'>
          <p>{team.text1}</p>
        </div>}
      </div>
      {!team.videoURL1 && <div className='descr'>
        <p>{team.text1}</p>
      </div>}
    </section>
  );
}
