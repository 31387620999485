import * as action from "./action";
import { BASE_URL } from "../../../apis/backEnd.apis";
import { config } from "../../../config";

var request = require("request");
const token = config.apiAccessKey;

export const fetching = () => ({ type: action.FETCHING });
export const success = () => ({ type: action.SUCCESS });
export const _error = msg => ({ type: action.ERROR, payload: msg });
export const setUserInfo = user => ({ type: action.USER_INFO, payload: user });

export const checkAuthentication = () => {
  return JSON.parse(localStorage.getItem("userInfo")).role === "admin";
};

export const login = (email, password) => (dispatch, getState) => {
  dispatch(fetching());
  request(
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "access-token": `${token}`
        
      },
      body: JSON.stringify({ email, password }),
      url: BASE_URL + "users/login"
    },
    (error, response, body) => {
      let res = JSON.parse(response.body);
      if (!error) {
        if (res.success) {
          if (res.data.uid) {
            localStorage.setItem("userInfo", JSON.stringify(res.data));
            dispatch(setUserInfo(res.data));
            alert("Login successful");
            window.location.href = "/dashboard/prospectmanagement";
            dispatch(success());
          } else alert(res.data);
          dispatch(_error());
        } else {
          alert(res.error.message);
          dispatch(_error());
        }
      } else {
        console.log(error);
        dispatch(_error());
      }
    }
  );
};

export const logout = () => (dispatch, getState) => {
  dispatch(setUserInfo({}));
};

export const sendLink = (email, baseUrl) => (dispatch, getState) => {
  dispatch(fetching());
  request(
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({ email, baseUrl }),
      url: BASE_URL + "users/sendResetPasswordLink"
    },
    (error, response, body) => {
      let res = JSON.parse(response.body);
      if (res.success) {
        dispatch(success())
        alert(res.message);

      } else {
        console.log(error);
        alert(res.message);
        dispatch(_error());
      }
    }
  );
}

export const resetPassword = (userId, email, password) => (dispatch, getState) => {
  dispatch(fetching());
  request(
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({ userId, email, password }),
      url: BASE_URL + "users/resetPassword"
    },
    (error, response, body) => {
      let res = JSON.parse(response.body);
      if (res.success) {
        dispatch(success())
        alert(res.message);

      } else {
        console.log(error);
        alert(res.message);
        dispatch(_error());
      }
    }
  );
}
