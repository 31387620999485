import React from "react";
import { InputBase, Select, MenuItem } from "@material-ui/core";
import { Goals } from "../../../components/TeamDetail/Goals";
import './Header.scss';

export const Header = ({ ...props }) => {
  const { searchNames, searchItem, details, donation, campaign, onCampaignChange, campaigns, filteredCampaigns, showCampaignName } = props;
  const timeRemaining = new Date(details.campaignEndTime) - new Date();
  const bonus = donation > parseInt(details.goalAmount) && timeRemaining >= 0;
  const campaignList = filteredCampaigns ? filteredCampaigns : campaigns

  return (
    <div className='mp-header'>
      <Goals isMobile={true} startTime={details.campaignStartTime} endTime={details.campaignEndTime} bonus={bonus} bonusEnd={details.bonusGoalEndDate} />
      <div className='search-container'>
        <InputBase className='search-box' placeholder='Search names ...' onChange={searchNames} value={searchItem} />
        <i className="fa fa-search" aria-hidden="true"></i>
      </div>
      { showCampaignName && <div className='select-container'>
        <span>Campaign Name</span>  
        <Select disableUnderline
          className='campaign-selectbox'
          name="campaign"
          value={campaign}
          onChange={onCampaignChange}
        >
          { campaignList && campaignList.map((campaign, i) =>
            <MenuItem value={campaign} key={i}>{campaign.name}</MenuItem>
          )}
        </Select>
      </div>}
    </div>
  );
}
