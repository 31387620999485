import React from 'react';
import { CampaignVideo } from '../Campaign/CampaignVideo';

export const Donor = ({ details }) => {

  return(
    <section className="donor">
      {/* {superDonors && superDonors.length > 0 && superDonors[0].name && <div className="icon">
        <img src='/images/new-page-icon-2.png' alt='Super Donors' />
        <p>MEET OUR POWER DONORS</p>
      </div>} */}
      <div id="description">
        <CampaignVideo video={details.videoURL} status={details.status} />
      </div>
    </section>
  );
}
