import React, { Component } from 'react';
import scrollToComponent from 'react-scroll-to-component';
import { connect } from 'react-redux';
import './Whychooseus.scss';

class Whychooseus extends Component {
    render() {
        return(
            <div>
                <div className="container text-center top-banner">
                    <h2>WHY CHOOSE US?</h2>
                    <p className="middle-font">Scroll down to take a peek at our wide range of offerings!</p>
                </div>

                <section id="benefits-wrapper" className="clearfix">
                    <div className="container-fluid">
                        <a onClick={()=>scrollToComponent(this.art_state, {offset: 0, align: 'middle', duration: 1000})}>
                            <img src="/images/whychooseus/art-technology.png"/>
                            <p>STATE-OF-THE-ART TECHNOLOGY</p>
                        </a>
                        <a onClick={()=>scrollToComponent(this.unique_marketing, {offset: 0, align: 'middle', duration: 1000})}>
                            <img src="/images/whychooseus/unique-marketing.png"/>
                            <p>unique marketing edge</p>
                            </a>
                        <a onClick={()=>scrollToComponent(this.campaign_creation, {offset: 0, align: 'middle', duration: 1000})}>
                            <img src="/images/whychooseus/campaign-creation.png"/>
                            <p>CUSTOMIZED CAMPAIGN CREATION</p>
                        </a>
                        <a onClick={()=>scrollToComponent(this.media_production, {offset: 0, align: 'middle', duration: 1000})}>
                            <img src="/images/whychooseus/media-production.png"/>
                            <p>MEDIA PRODUCTION</p>
                        </a>
                        <a onClick={()=>scrollToComponent(this.verbiage_writeup, {offset: 0, align: 'middle', duration: 1000})}>
                            <img src="/images/whychooseus/material-writers.png"/>
                            <p>PR & Marketing Material Writers</p>
                        </a>
                        <a onClick={()=>scrollToComponent(this.design_team, {offset: 0, align: 'middle', duration: 1000})}>
                            <img src="/images/whychooseus/design-team.png"/>
                            <p>a designated team</p>
                        </a>
                        <a onClick={()=>scrollToComponent(this.power_donor, {offset: 0, align: 'middle', duration: 1000})}>
                            <img src="/images/whychooseus/power-donor.png"/>
                            <p>POWER DONOR RECRUITMENT ASSISTANCE</p>
                        </a>
                        <a onClick={()=>scrollToComponent(this.team_training, {offset: 0, align: 'middle', duration: 1000})}>
                            <img src="/images/whychooseus/team-training.png"/>
                            <p>TEAM TRAINING & MOTIVATION</p>
                        </a>
                        <a onClick={()=>scrollToComponent(this.leadership_training, {offset: 0, align: 'middle', duration: 1000})}>
                            <img src="/images/whychooseus/leadership-training.png"/>
                            <p>post campaign LEADERSHIP TRAINING</p>
                        </a>
                    </div>
                </section>

                <section id="state-of-the-art" className="benefits-detail-wrapper" ref={(section) => { this.art_state = section; }}>
                    <div className="container">
                    <div className="row">
                        <div className="col-md-6 col-sm-12 text-center">
                        <img src="/images/whychooseus/pianotocuh.png" className="img-fluid"/>
                        </div>
                        <div className="col-md-6 col-sm-12">
                        <h2 className="title-font">STATE-OF-THE-ART TECHNOLOGY</h2>
                        <p className="middle-font">STATE-OF-THE-ART TECHNOLOGY TO RAISE MORE, IN LESS TIME</p>
                        <ul className="detail-list blue">
                            <li className="middle-font">Cloud based technology ensuring security & handle on traffic</li>
                            <li className="middle-font">Innovative payment solutions to speed payment processing</li>
                            <li className="middle-font">Highly secured user area, allowing donors to save their credit card information for recurring payments</li>
                            <li className="middle-font">Coming Soon | Worldwide Currencies</li>
                        </ul>
                        <p className="middle-font">Data An in depth report of all campaign activities including</p>
                        <ul className="detail-list red">
                            <li className="middle-font">Donors reports</li>
                            <li className="middle-font">Types of transactions</li>
                            <li className="middle-font">Chart of tracked data</li>
                        </ul>
                        <p className="middle-font">And Much more to help you see the strength and weaknesses of your campaigns allowing you run a bigger more profitable campaign time after time</p>
                        </div>
                    </div>
                    </div>
                </section>

                <section id="unique-marketing" className="benefits-detail-wrapper" ref={(section) => { this.unique_marketing = section; }}>
                    <div className="container">
                    <div className="row">
                        <div className="col-md-6 col-sm-12">
                        <h2 className="title-font">UNIQUE MARKETING EDGE</h2>
                        <p className="middle-font">UNIQUE MARKETING EDGETO GET YOU THERE Our proprietary technology is the most advance system in the world where our team will enable you to automate the entire process of informing, nurturing and collecting a breeze. Some of the features include:</p>
                        <ul className="detail-list yellow">
                            <li className="middle-font">Donors reports</li>
                            <li className="middle-font">Types of transactions</li>
                            <li className="middle-font">Chart of tracked data</li>
                        </ul>
                        </div>
                        <div className="col-md-6 col-sm-12 text-center">
                        <img src="/images/whychooseus/unique-marketing-big.png" className="img-fluid"/>
                        </div>
                    </div>
                    </div>
                </section>

                <section id="customized-campaign" className="benefits-detail-wrapper" ref={(section) => { this.campaign_creation = section; }}>
                    <div className="container">
                    <div className="row">
                        <div className="col-md-6 col-sm-12 text-center">
                        <img src="/images/whychooseus/locationdonor.png" className="img-fluid"/>
                        </div>
                        <div className="col-md-6 col-sm-12">
                        <h2 className="title-font">CUSTOMIZED CAMPAIGN CREATION</h2>
                        <p className="middle-font">Our team work diligently with you to custom tailor each campaign to fit your</p>
                        </div>
                    </div>
                    </div>
                </section>

                <section id="media-production" className="benefits-detail-wrapper" ref={(section) => { this.media_production = section; }}>
                    <div className="container">
                        <div className="row">
                        <div className="col-md-6 col-sm-12">
                            <h2 className="title-font">MEDIA PRODUCTION</h2>
                            <p className="middle-font">With access to a worldwide hub of video productions experts as well as our own state-of-the-art studio located in Los Angeles, we can make your campaign come to life. Our award winning production team with over 15 years has produced hundreds of non-profit, commercials, documentaries, and webisodes for some of the biggest companies in United States and abroad.</p>
                        </div>
                        <div className="col-md-6 col-sm-12 text-center">
                            <img src="/images/whychooseus/greencamera.png" className="img-fluid"/>
                        </div>
                        </div>
                    </div>
                </section>

                <section id="verbiage-writeup" className="benefits-detail-wrapper" ref={(section) => { this.verbiage_writeup = section; }}>
                    <div className="container">
                        <div className="row">
                        <div className="col-md-6 col-sm-12 text-center">
                            <img src="/images/whychooseus/writerdonot.png" className="img-fluid"/>
                        </div>
                        <div className="col-md-6 col-sm-12">
                            <h2 className="title-font">PR & VERBIAGE WRITE UP ASSISTANCE</h2>
                            <p className="middle-font">Each non-profit is unique; our writers bring your story to life.</p>
                            <ul className="detail-list brown">
                                <li className="middle-font">Custom email series</li>
                                <li className="middle-font">Custom social media posts to get your followers excited</li>
                            </ul>
                        </div>
                        </div>
                    </div>
                </section>

                <section id="design-team" className="benefits-detail-wrapper" ref={(section) => { this.design_team = section; }}>
                    <div className="container">
                        <div className="row">
                            <div className="col-md-6 col-sm-12">
                                <h2 className="title-font">A DESIGNATED TEAM</h2>
                                <p className="middle-font">A DESIGNATED TEAM TO HELP YOU WIN When you win, we win. That’s why each campaign is backed by:</p>
                                <ul className="detail-list red">
                                    <li className="middle-font">Fundraising Specialist</li>
                                    <li className="middle-font">Campaign Consultant</li>
                                    <li className="middle-font">On-Demand Customer Support</li>
                                    <li className="middle-font">Creative Directors to help make each campaign unique</li>
                                    <li className="middle-font">Creative Directors to help make each campaign unique</li>
                                    <li className="middle-font">Designated Graphic Designer</li>
                                </ul>
                            </div>
                            <div className="col-md-6 col-sm-12 text-center">
                                <img src="/images/whychooseus/greencamera.png" className="img-fluid"/>
                            </div>
                        </div>
                    </div>
                </section>

                <section id="power-donor" className="benefits-detail-wrapper" ref={(section) => { this.power_donor = section; }}>
                    <div className="container">
                        <div className="row">
                        <div className="col-md-6 col-sm-12 text-center">
                            <img src="/images/whychooseus/powerdonor.png" className="img-fluid"/>
                        </div>
                        <div className="col-md-6 col-sm-12">
                            <h2 className="title-font">POWER DONOR RECRUITMENT ASSISTANCE</h2>
                            <ul className="detail-list lightblue">
                                <li className="middle-font">Help you identify Matchers</li>
                                <li className="middle-font">Train you on how to approach large donors</li>
                                <li className="middle-font">Matcher training webinars</li>
                                <li className="middle-font">Precise approach to getting your Power Donors onboard</li>
                            </ul>
                        </div>
                        </div>
                    </div>
                </section>

                <section id="team-training" className="benefits-detail-wrapper" ref={(section) => { this.team_training = section; }}>
                    <div className="container">
                        <div className="row">
                        <div className="col-md-6 col-sm-12">
                            <h2 className="title-font">TEAM TRAINING & MOTIVATION</h2>
                            <ul className="detail-list darkpink">
                                <li className="middle-font">Support on setup and arrangement</li>
                                <li className="middle-font">Customized calling scripts and credit card forms</li>
                                <li className="middle-font">Volunteer training (via web)</li>
                                <li className="middle-font">Projector-view campaign page for the operation room</li>
                            </ul>
                        </div>
                        <div className="col-md-6 col-sm-12 text-center">
                            <img src="/images/whychooseus/group-donor.png" className="img-fluid"/>
                        </div>
                        </div>
                    </div>
                </section>

                <section id="leadership-training" className="benefits-detail-wrapper" ref={(section) => { this.leadership_training = section; }}>
                    <div className="container">
                        <div className="row">
                        <div className="col-md-6 col-sm-12 text-center">
                            <img src="/images/whychooseus/ranikindonor.png" className="img-fluid"/>
                        </div>
                        <div className="col-md-6 col-sm-12">
                            <h2 className="title-font">POST CAMPAIGN LEADERSHIP TRAINING</h2>
                            <ul className="detail-list green">
                                <li className="middle-font">Tailored donor engagement and retention program</li>
                                <li className="middle-font">Donor analytics</li>
                                <li className="middle-font">Email and graphic templates for post-campaign</li>
                                <li className="middle-font">Social Media strategy</li>
                            </ul>
                        </div>
                        </div>
                    </div>
                </section>
            </div>
        );
    }
}

export default connect(
    (state) => ({
    }),
    (dispatch) => ({
    })
)(Whychooseus);