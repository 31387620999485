import { handleActions } from 'redux-actions';
import * as action from './action';
import { GET_USERS } from '../Users/actions';
import { GET_REPORT } from '../CampaignReports/action';
import {ALL_CAMPAIGNS} from '../CampaignReports/action';
import { ProspectFilters } from './Filters';
import { currencies } from "../../../Common/currencies";

const initialState = {
  isFetching: false,
  prospects: [],
  filteredProspects: '',
  searchItem: '',
  prospect: {},
  prospectId: '',
  users : [],
  campaigns:[],
  initialCampaign:[],
  refresh: false,
}

const filterByAssign = (state, action) => {
  const { value } = action.target;
  const { prospects } = state;
  if(value === 'allProspects') {
    return prospects;
  } else if(value === 'assignedProspects') {
    return prospects.filter(item => item.userId === JSON.parse(localStorage.getItem('userInfo')).uid);
  } else {
    return prospects.filter(item => item.userId === value)
  }
}

const searchProspect = (state , action) => {
    let e = action.payload;
    let searchItem = e.target.value;
    state.searchItem = e.target.value;
    let prospects = state.prospects;

    const filteredProspects = prospects.filter(prospect=>
      prospect.firstName.toUpperCase().indexOf(searchItem.toUpperCase()) >-1 ||
      prospect.lastName.toUpperCase().indexOf(searchItem.toUpperCase()) > -1 ||
      prospect.status.toUpperCase().indexOf(searchItem.toUpperCase()) > -1 ||
      prospect.suggestedDonation.toUpperCase().indexOf(searchItem.toUpperCase()) > -1
    )
    return filteredProspects;
}

const filterProspects = (state, action) => {
  return ProspectFilters(state, action);
}

const addFields = (state, action) => {
  const { prospects, users } = state;
  const campaignReports = action.payload;
  const updated = prospects.map(prospect => {
    const user = users.find(u => u.id === prospect.userId);
    const donation = getDonation(campaignReports, prospect);
    let sign = '';
    if(donation && donation.currency) {
      const s = currencies.find(c => c.code === donation.currecy);
      sign = s ? s.sign : '$';
    }
    prospect.user = (prospect.userId && !user) ? 'Admin' : user ? `${user.firstName} ${user.lastName}` : '';
    prospect.donationAmount = donation && (parseInt(donation.donationAmount) || 0);
    prospect.paymentType = donation && (donation.paymentType || '');
    prospect.sign = sign
    return prospect;
  });

  return updated;
}

const getDonation = (campaignReports, prospect) => {
  const donation = campaignReports && (campaignReports.find(item => item.prospectId === prospect.id) || {});
  return donation;
}

const filterUsers = (payload) => {
  return payload.filter(user => user.role === 'volunteer' && user.status && (user.status.toLowerCase() === 'active' || user.status.toLowerCase() === 'inactive') )
}

const handlers = {
  [action.FETCHING] : state => ({ ...state , isFetching : true , selectedToEdit : '' }),
  [action.SUCCESS] : state => ({ ...state , isFetching : false }),
  [action.REFRESH]: state => ({ ...state, refresh: true }),
  [action.ERROR] : (state, action) => ({ ...state , isFetching : false }),
  [action.UPDATE_LIST] : (state, action) => ({ ...state, prospects: action.payload, refresh: false }),
  [GET_USERS] : (state,action) => ({...state , users: filterUsers(action.payload) }),
  [GET_REPORT]: (state, action) => ({ ...state, prospects: addFields(state, action), searchItem: '', filteredProspects: '' }),
  [ALL_CAMPAIGNS]: (state, action) => ({ ...state, campaigns:action.payload, initialCampaign:action.payload[0] }),
  [action.GET_PROSPECT]: (state, action) => ({ ...state, prospect: action.payload }),
  [action.FETCH_PROSPECTS] : (state, action) => ({ ...state }),
  [action.SEARCH_PROSPECT] : (state,action) => ({...state,filteredProspects: searchProspect(state,action)}),
  [action.APPLY_FILTER]  : (state, action) => ({ ...state, filteredProspects: filterProspects(state, action) }),
  [action.CLEAR_FILTER] : (state, action) => ({ ...state, filteredProspects: false }),
  [action.SET_PROSPECT_ID] : (state,action) => ({...state,prospectId : action.payload}),
  [action.REMOVE_PROSPECT_ID] : (state,action) => ({...state,prospectId:''}),
  [action.FILTER_BY_ASSIGNMENT]: (state, action) => ({ ...state, filteredProspects: filterByAssign(state, action.payload)})
}
export default handleActions(handlers,initialState)