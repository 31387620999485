import React, { Component } from "react";
import Countdown from "react-countdown-now";
import { Carousel } from "react-bootstrap";
import { connect } from "react-redux";

import "./Specialoffer.scss";

class Heroimage extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {}

  render() {
    return (
      <div>
        <section
          className="hero_image"
          style={{ backgroundImage: "url(/images/herobg.png)" }}
        >
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 text-center">
                <h1>
                  Pledje Produced <span>$180,000</span> More{" "}
                </h1>
                <p>
                  From the same pool of donors than previous experiences we had
                  with other platforms.
                </p>
                <p>
                  <strong>David Cohen</strong>, Yachad Outreach Center
                </p>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

export default connect(
  state => ({}),
  dispatch => ({})
)(Heroimage);
