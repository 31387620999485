export const dateformat = date => {
  let d = date.split("-");
  return d[1] + "/" + d[2] + "/" + d[0];
};

export const team = data => {
  var {
    teamName,
    pageName,
    goalAmount,
    imageURL1,
    videoURL1,
    text1,
    avatar,
    isActive,
    phone,
    firstName,
    lastName,
    email
  } = data;
  return {
    firstName: firstName,
    lastName: lastName,
    email: email,
    goalAmount: goalAmount,
    isActive: isActive === false ? false : true,
    teamName: teamName,
    pageName: pageName,
    imageURL1: imageURL1,
    videoURL1: videoURL1,
    text1: text1,
    avatar: avatar,
    phone: phone
  };
};
