import React, { Component, Fragment } from "react";
import { Formik, Field } from "formik";
import teamValidation from "../../YupValidation/YupValidation";
import Cropper from "react-easy-crop";
import firebase from "./../../apis/firebase.apis";
import LoadingOverlay from "react-loading-overlay";
import getCroppedImg from "./cropImage";
import "./addTeam.scss";

let initialState = {
  firstName: "",
  lastName: "",
  email: "",
  phone: "",
  title: "",
  goal: "",
  image: "",
  url: "",
  teamPageURL: "",
};

class MyComponent extends Component {
  state = {
    imageSrc: null,
    crop: { x: 0, y: 0 },
    zoom: 1,
    aspect: 4 / 3,
    image: "",
    imagePreviewUrl: "",
    blobURL: "",
    croppedAreaPixels: null,
    croppedImage: null,
    showResultant: false,
    imageCropped: false,
    teamPageURL: `https://pledje.com${this.props.campaignName}/`,
    pageName: ""
  };

  componentDidMount() {
    const { team, campaignName } = this.props;

    if (team && team.id) {
      initialState = {
        ...team,
        teamPageURL: `https://pledje.com${campaignName}/${team.pageName}/`,
        title: team.teamName,
        url: team.videoURL1,
        goal: team.goalAmount,
      }

      delete initialState['Avatar'];
      delete initialState['edit'];
      delete initialState['goalAmount'];
      delete initialState['pageName'];
      delete initialState['teamName'];
      delete initialState['videoURL1'];
      delete initialState['imageURL1'];

      this.setState({ imagePreviewUrl: team.avatar, teamPageURL: `https://pledje.com${campaignName}/${team.pageName}`, pageName: team.pageName })
    } else {
      initialState = {
        firstName: "",
        lastName: "",
        email: "",
        phone: "",
        title: "",
        goal: "",
        image: "",
        url: "",
        teamPageURL: `https://pledje.com${this.props.campaignName}/`,
      };
    }
  }

  componentDidUpdate(oldProps) {
    const { campaignName } = this.props;
    const { pageName } = this.state;

    if (campaignName !== oldProps.campaignName) {
      initialState.teamPageURL = `https://pledje.com${campaignName}/${pageName}`;
      this.setState({ teamPageURL: `https://pledje.com${campaignName}/${pageName}` })
    }
  }

  onCropChange = crop => {
    this.setState({ crop });
  };

  onCropComplete = (croppedArea, croppedAreaPixels) => {
    this.setState({ croppedAreaPixels });
  };

  showCroppedImage = async () => {
    const { fileBlob, blobURL } = await getCroppedImg(
      this.state.blobURL,
      this.state.croppedAreaPixels
    );
    firebase
      .storage()
      .ref()
      .child("CampaignPicture")
      .child(JSON.stringify(new Date()))
      .put(fileBlob)
      .then(snapshot => {
        console.log(snapshot);
        snapshot.ref.getDownloadURL().then(url => {
          this.setState(
            {
              imagePreviewUrl: url,
              blobURL: blobURL,
              showResultant: false,
              imageCropped: true
            },
            () => alert("file uploaded")
          );
        });
      });
  };

  onZoomChange = zoom => {
    this.setState({ zoom });
  };

  handleClose = () => {
    this.setState({ croppedImage: null });
  };

  onFileChange = e => {
    e.preventDefault();

    let reader = new FileReader();
    let file = e.target.files[0];

    reader.onloadend = () => {
      this.setState({
        file: file,
        imagePreviewUrl: reader.result,
        blobURL: reader.result,
        showResultant: true
      });
    };

    reader.readAsDataURL(file);
  };

  cropImageUpload = e => {
    e.preventDefault();
    this.showCroppedImage();
  };

  showImage = e => {
    e.preventDefault();
    this.setState({
      showResultant: true,
      imageCropped: false
    });
  };

  cancelCrop = e => {
    e.preventDefault();
    this.setState({
      showResultant: false,
      imageCropped: true
    });
  };

  handleSubmit = values => {
    const { team } = this.props;
    let teamURL = values.teamPageURL.split(`${this.props.campaignName}/`).pop();

    if (team && team.id) {
      this.props.editTeam({
        imagePreviewUrl: this.state.imagePreviewUrl,
        ...values,
        teamPageURL: teamURL.toLowerCase()
      })
    } else {
      this.props.addTeam({
        imagePreviewUrl: this.state.imagePreviewUrl,
        ...values,
        teamPageURL: teamURL.toLowerCase()
      });
    }
  }

  render() {
    const { team } = this.props;

    return (
      <LoadingOverlay
        active={this.props.isFetching || this.props.isUploading}
        spinner
        text={
          this.props.isUploading
            ? "File is being uploaded... " + this.props.progress + " "
            : "Please wait..."
        }
      >
        <Formik
          initialValues={initialState}
          onSubmit={this.handleSubmit}
          enableReinitialize={true}
          validationSchema={teamValidation}
        >
          {props => (
            <form onSubmit={props.handleSubmit}>
              <div
                className={
                  this.props.dialog ? "formik-form-dialog" : "formik-form"
                }
              >
                <div className="row1">
                  <div className="left-insideRow">
                    <label>{"Title of you Page (100 Characters Max)"}</label>
                    <Field
                      autoComplete="no"
                      form={{
                        autocomplete: "off"
                      }}
                      name="title"
                      type="text"
                      placeholder="Example: David & Sarah's Fundraising page"
                      className="field"
                    />
                    <p className="error">{props.errors.title}</p>
                  </div>

                  <div className="right-insideRow">
                    <label>{"Campaign Goal"}</label>
                    <Field
                      autoComplete="no"
                      form={{
                        autocomplete: "off"
                      }}
                      name="goal"
                      onChange={props.handleChange}
                      value={props.values.goal}
                      type="number"
                      placeholder="Campaign Goal in $$"
                      className="field"
                    />
                    <p className="error">{props.errors.goal}</p>
                  </div>
                </div>
                <div className="row1">
                  <div className="left-insideRow">
                    <label>{"First Name"}</label>
                    <Field
                      autoComplete="no"
                      form={{
                        autocomplete: "off"
                      }}
                      name="firstName"
                      onChange={props.handleChange}
                      value={props.values.firstName}
                      type="text"
                      placeholder="First Name"
                      className="field"
                    />
                    <p className="error">{props.errors.firstName}</p>
                  </div>

                  <div className="right-insideRow">
                    <label>{"Last Name"}</label>
                    <Field
                      autoComplete="no"
                      form={{
                        autocomplete: "off"
                      }}
                      name="lastName"
                      onChange={props.handleChange}
                      value={props.values.lastName}
                      type="textarea"
                      placeholder="Last Name"
                      className="field"
                    />
                    <p className="error">{props.errors.lastName}</p>
                  </div>
                </div>
                <div className="row1">
                  <div className="left-insideRow">
                    <label>{"Phone"}</label>
                    <Field
                      autoComplete="no"
                      form={{
                        autocomplete: "off"
                      }}
                      type="tel"
                      placeholder="Phone:"
                      onChange={props.handleChange}
                      name="phone"
                      value={props.values.phone}
                      className="field"
                    />
                    <p className="error">{props.errors.phone}</p>
                  </div>

                  <div className="right-insideRow">
                    <label>{"Email "}</label>
                    <Field
                      autoComplete="no"
                      form={{
                        autocomplete: "off"
                      }}
                      type="email"
                      placeholder="Email:"
                      onChange={props.handleChange}
                      name="email"
                      value={props.values.email}
                      className="field"
                    />
                    <p className="error">{props.errors.email}</p>
                  </div>
                </div>
                <div className="row1">
                  <div className="left-insideRow">
                    <label>{"Upload a picture of yourself"}</label>

                    <input type="file" onChange={this.onFileChange} />
                    {this.state.showResultant && (
                      <Fragment>
                        <div className="crop-container">
                          <Cropper
                            image={this.state.imagePreviewUrl}
                            crop={this.state.crop}
                            zoom={this.state.zoom}
                            aspect={this.state.aspect}
                            onCropChange={this.onCropChange}
                            onCropComplete={this.onCropComplete}
                            onZoomChange={this.onZoomChange}
                          />
                        </div>
                        <div className="controls" />
                        <button
                          className="crop-btn"
                          onClick={this.cropImageUpload}
                        >
                          {"Crop Avatar and Upload"}
                        </button>
                      </Fragment>
                    )}

                    {this.state.imageCropped ? (
                      <button
                        onClick={this.showImage}
                        variant="contained"
                        color="primary"
                        className="crop-btn"
                      >
                        Show Image
                      </button>
                    ) : (
                        ""
                      )}
                    {this.state.showResultant ? (
                      <button className="crop-btn" onClick={this.cancelCrop}>
                        Cancel
                      </button>
                    ) : (
                        ""
                      )}
                  </div>

                  <div className="right-insideRow">
                    <label>{"Desired path for your Team Page"}</label>
                    <Field
                      autoComplete="no"
                      form={{ autocomplete: "off" }}
                      type="url"
                      onChange={e => {
                        if (e.target.value.includes(this.state.teamPageURL)) {
                          props.handleChange({
                            target: {
                              value: e.target.value,
                              name: "teamPageURL"
                            }
                          });
                        } else {
                          props.handleChange({
                            target: {
                              value: this.state.teamPageURL,
                              name: "teamPageURL"
                            }
                          });
                        }
                      }}
                      name="teamPageURL"
                      value={props.values.teamPageURL}
                      className="field"
                    />
                  </div>
                </div>
                <div className="row1">
                  <div className="right-insideRow">
                    <label>{"Enter a url for your selfie video"}</label>
                    <p>
                      (You may do this from at a later time by logging into your
                      back office with your unique id and password)
                    </p>
                    <Field
                      autoComplete="no"
                      form={{
                        autocomplete: "off"
                      }}
                      type="url"
                      placeholder="Enter a url for your selfie video"
                      onChange={props.handleChange}
                      name="url"
                      value={props.values.url}
                      className="field"
                    />
                  </div>
                </div>

                <div className="row1">
                  <div className="left-insideRow">
                    <div className="btn-div">
                      <button type="submit" className="btnSubmit">
                        {team && team.id ? 'Update' : 'Submit'}
                      </button>
                      <div
                        className="btnCancel"
                        onClick={this.props.handleBack}
                      >
                        CANCEL
                      </div>
                    </div>
                  </div>
                  <div className="right-insideRow" />
                </div>
              </div>
            </form>
          )}
        </Formik>
      </LoadingOverlay>
    );
  }
}

export default MyComponent;
