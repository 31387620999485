import * as Yup from 'yup';

const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

export const organizationSchema = Yup.object({
  organizationName: Yup.string().required('Enter the Organization Name'),
  organizationAddress: Yup.string(),
  organizationTaxId: Yup.string(),
  organizationPhone: Yup.string().matches(phoneRegExp, 'Invalid Phone Number'),
  firstName: Yup.string().required('Please Enter First Name Admin'),
  lastName: Yup.string().required('Please Enter Last Name of Admin'),
  email: Yup.string().email('Invalid Email').required('Please Enter Email Address'),
  password: Yup.string().min(6, 'Password should be atleast 6 characters long').required('Please Enter Password'),
});
