import React from 'react';
import PropTypes from 'prop-types';
import { withStyles, Table, TableBody, TableCell, TableHead, TablePagination, TableRow, TableSortLabel, Paper } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit } from '@fortawesome/free-solid-svg-icons';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import './campaignreport.scss';
import './CampaignTable.scss';
import './custom.scss';

function desc(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function stableSort(array, cmp) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map(el => el[0]);
}

function getSorting(order, orderBy) {
  return order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
}

let rows = [
  { id: 'firstName', numeric: false, disablePadding: false, label: 'First Name' },
  { id: 'lastName', numeric: false, disablePadding: true, label: 'Last Name' },
  { id: 'email', numeric: false, disablePadding: true, label: 'Email' },
  { id: 'donationDate', numeric: true, disablePadding: false, label: 'Time of Donation' },
  { id: 'alias', numeric: true, disablePadding: false, label: 'Alias/Anonymous' },
  { id: 'donationAmount', numeric: false, disablePadding: true, label: 'Donation Amount' },
  { id: 'donationMatched', numeric: true, disablePadding: false, label: 'Donation Matched' },
  { id: 'paymentType', numeric: true, disablePadding: false, label: 'Payment Method' },
  { id: 'teamId', numeric: false, disablePadding: true, label: 'Team Member' },
  { id: 'mode', numeric: true, disablePadding: false, label: 'Payment Mode' },
  { id: 'transactionCostCovered', numeric: true, disablePadding: false, label: 'Transaction Covered' },
  { id: 'transactionCost', numeric: true, disablePadding: false, label: 'Transaction Cost' },
  { id: 'transactionId', numeric: true, disablePadding: false, label: 'Transaction ID' },
  { id: 'billingPhone', numeric: true, disablePadding: false, label: 'Phone' },
  { id: 'billingAddress', numeric: true, disablePadding: false, label: 'Address' },
  { id: 'dedicationMessage', numeric: true, disablePadding: false, label: 'Dedication Message' },
  { id: 'status', numeric: false, disablePadding: false, label: 'Donation Status' },
  { id: 'raffle', numeric: false, disablePadding: false, label: 'Raffle Categories' },
  { id: 'specialNotes', numeric: false, disablePadding: false, label: 'Special Instrctions' },
  { id: 'items', numeric: false, disablePadding: false, label: 'Items Donated' },
  { id: 'state', numeric: false, disablePadding: false, label: 'State' },
  { id: 'city', numeric: false, disablePadding: false, label: 'City' },
  { id: 'zip', numeric: false, disablePadding: false, label: 'Zip Code' },
  { id: 'action', numeric: false, disablePadding: false, label: 'Action' }
];

class CampaignTableHead extends React.Component {
  createSortHandler = property => event => {
    this.props.onRequestSort(event, property);
  };

  render() {
    const { order, orderBy, fields } = this.props;

    return (
      <TableHead>
        <TableRow>
          {rows.map(
            row => (
              fields[row.id] &&
              <TableCell
                key={row.id}
                className="campaign-table-cell"
              >
                <TableSortLabel
                  active={orderBy === row.id}
                  direction={order}
                  onClick={this.createSortHandler(row.id)}
                  IconComponent = {() => order === 'asc' ? <ArrowDropDownIcon/>:<ArrowDropUpIcon/>}
                >
                  {row.label}
                </TableSortLabel>
              </TableCell>
            )
          )}
        </TableRow>
      </TableHead>
    );
  }
}

CampaignTableHead.propTypes = {
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.string.isRequired,
  orderBy: PropTypes.string.isRequired,
};

const styles = () => ({
  caption: {
    fontSize: 14,
  },
  toolbar: {
    padding: 0,
    height: 40,
    minHeight: 40
  }
});

class CampaignTable extends React.Component {
  constructor(){
    super()
    this.state = {
      order: 'asc',
      orderBy : 'aliasName',
      selected: [],
      page: 0
    };
  }

  handleRequestSort = (event, property) => {
    const orderBy = property;
    let order = 'desc';

    if (this.state.orderBy === property && this.state.order === 'desc')
      order = 'asc';

    this.setState({ order, orderBy });
  };

  handleChangePage = (event, page) => {
    this.setState({ page });
  };

  scrollTable = () => {
    const empty = document.getElementById('empty-wrapper');
    const table = document.getElementById('campaign-report-table');

    empty.scrollLeft = table.scrollLeft;
  }

  render() {
    const { classes, campaignReports, rowsPerPage, fields, onEditDonation } = this.props;
    const {order, orderBy, selected, page } = this.state;

    return (
      <Paper className='table-root'>
        <div onScroll={this.scrollTable} id='campaign-report-table' className='table-body' style={{ marginBottom: campaignReports.lenght > rowsPerPage && 40 }} >
          <Table id='report-table' className='report-table' aria-labelledby="tableTitle">
            <CampaignTableHead
              fields={fields}
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onRequestSort={this.handleRequestSort}
              rowCount={campaignReports.length}
            />
            <TableBody>
              {stableSort(campaignReports, getSorting(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((n ,index) => {
                  console.log('alias',fields);
                  return (
                    <TableRow
                      key={index}
                      style = {{ backgroundColor :  index % 2 === 0? '#fafafa' : 'white'}}
                    >
                      { fields.firstName && <TableCell component="th" scope="row" className="campaign-table-cell">{n.firstName}</TableCell>}
                      { fields.lastName && <TableCell className="campaign-table-cell">{n.lastName}</TableCell>}
                      { fields.email && <TableCell className="campaign-table-cell">{n.email}</TableCell>}
                      { fields.donationDate && <TableCell className="campaign-table-cell">{n.donationDate}</TableCell>}
                      { n.alias ? <TableCell className="campaign-table-cell">{n.alias}</TableCell> :  <TableCell className="campaign-table-cell">{n.fullname}</TableCell>  }
                      { fields.donationAmount && <TableCell component="th" className="campaign-table-cell nb-white-space">{n.donationAmount}</TableCell>}
                      { fields.donationMatched && <TableCell className="campaign-table-cell">{n.donationMatched}</TableCell>}
                      { fields.paymentType && <TableCell className="campaign-table-cell">{n.paymentType}</TableCell>}
                      { fields.teamId && <TableCell className="campaign-table-cell">{n.teamId}</TableCell>}
                      { fields.mode && <TableCell className="campaign-table-cell">{n.mode}</TableCell> }
                      { fields.transactionCostCovered && <TableCell className="campaign-table-cell">{n.transactionCostCovered}</TableCell> }
                      { fields.transactionCost && <TableCell className="campaign-table-cell">{n.transactionCost}</TableCell> }
                      { fields.transactionId && <TableCell className="campaign-table-cell">{n.transactionId}</TableCell> }
                      { fields.billingPhone && <TableCell className="campaign-table-cell">{n.billingPhone}</TableCell> }
                      { fields.billingAddress && <TableCell className="campaign-table-cell">{n.billingAddress}</TableCell> }
                      { fields.dedicationMessage && <TableCell className="campaign-table-cell">{n.dedicationMessage}</TableCell> }
                      { fields.status &&
                        <TableCell style={{ color: n.status === 'Failed' ? '#f44336' : n.status === 'Success' ? '#51b748' : '#838383', fontSize: 14 }}>{n.status}</TableCell>
                      }
                      { fields.raffle && <TableCell className="campaign-table-cell">{n.raffle}</TableCell> }
                      { fields.specialNotes && <TableCell className="campaign-table-cell">{n.specialNotes}</TableCell> }
                      { fields.items && <TableCell className="campaign-table-cell">{n.items}</TableCell> }
                      { fields.state && <TableCell className="campaign-table-cell">{n.state}</TableCell> }
                      { fields.city && <TableCell className="campaign-table-cell">{n.city}</TableCell> }
                      { fields.zip && <TableCell className="campaign-table-cell">{n.zip}</TableCell> }
                      <TableCell onClick={() => onEditDonation(n)} className="campaign-table-cell"><FontAwesomeIcon icon={faEdit} color ='#aaabaa' /></TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </div>
        { campaignReports.length > rowsPerPage && <TablePagination 
          classes={{ caption: classes.caption, toolbar: classes.toolbar }}
          className="pldj-pagination"
          rowsPerPageOptions={[]}
          component="div"
          count={campaignReports.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={this.handleChangePage}
        />}
      </Paper>
    );
  }
}

export default withStyles(styles)(CampaignTable);