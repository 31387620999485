import React from "react";
import {
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  Typography,
  Button
} from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileExport } from "@fortawesome/free-solid-svg-icons";
import { CSVLink } from "react-csv";
import ReactExport from "react-data-export";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const RadioLabels = props => {
  const { labelText } = props;
  return <div className="campaign-radio-label">{labelText}</div>;
};

const getCard = reports => {
  return reports.filter(report => report.paymentType === "credit card");
};

const getManual = reports => {
  return reports.filter(report => report.isManual);
};

export const Filters = ({
  filterByDonor,
  filterByPayment,
  onCampaignSortbyRadioChange,
  onFilterByPayment,
  completeReport,
  visibleFields,
  campaignReports,
  captureDonation
}) => {
  return (
    <div className="filters">
      <div className="report-filters">
        <span className="title">
          <label className="left">Payment Type</label>
          <label className="right">Donor Status</label>
        </span>
        <FormControl className="filter-by">
          <RadioGroup
            className="left"
            aria-label="filterByPayment"
            name="filterByPayment"
            value={filterByPayment}
            onChange={onFilterByPayment}>
            <FormControlLabel
              value="allPayments"
              className="radio-item"
              control={<Radio className="radio" />}
              label={<RadioLabels labelText="All Payments" />}
            />
            <FormControlLabel
              value="oneTime"
              className="radio-item"
              control={<Radio className="radio" />}
              label={<RadioLabels labelText="One-Time" />}
            />
            <FormControlLabel
              value="recurring"
              className="radio-item"
              control={<Radio className="radio" />}
              label={<RadioLabels labelText="Recurring" />}
            />
            <FormControlLabel
              value="manual"
              className="radio-item"
              control={<Radio className="radio" />}
              label={<RadioLabels labelText="Manual" />}
            />
          </RadioGroup>
          <RadioGroup
            className="right"
            aria-label="filterByDonor"
            name="filterByDonor"
            value={filterByDonor}
            onChange={onCampaignSortbyRadioChange}>
            <FormControlLabel
              value="allDonors"
              className="radio-item"
              control={<Radio className="radio" />}
              label={<RadioLabels labelText="All Donors" />}
            />
            <FormControlLabel
              value="anonymous"
              className="radio-item"
              control={<Radio className="radio" />}
              label={<RadioLabels labelText="Anonymous" />}
            />
            <FormControlLabel
              value="aliasDonors"
              className="radio-item"
              control={<Radio className="radio" />}
              label={<RadioLabels labelText="Alias Donors" />}
            />
          </RadioGroup>
        </FormControl>
        <div className="donor-types">
          <Typography className="sort-by-label">
            Number of Donors:{" "}
            <span>{completeReport && completeReport.length}</span>
          </Typography>
          <Typography className="sort-by-label">
            Credit Card/ACH Donation:{" "}
            <span>{completeReport && getCard(completeReport).length}</span>
          </Typography>
          <Typography className="sort-by-label">
            Manual Donations:{" "}
            <span>{completeReport && getManual(completeReport).length}</span>
          </Typography>
        </div>
        <div className='export-actions'>
          {visibleFields.length > 0 && campaignReports.length > 0 && (
            <div className="export-btn">
              <ExcelFile
                element={
                  <Button raised={"true"}>
                    <FontAwesomeIcon
                      icon={faFileExport}
                      style={{ margin: "5%" }}
                      fontSize="large"
                    />
                    Export XLS
                  </Button>
                }>
                <ExcelSheet data={visibleFields || []} name="Campaign Report">
                  {Object.keys(visibleFields[0]).map(columns => {
                    return columns === "alias" ? (
                      <ExcelColumn label="Alias" value={columns} />
                    ) : columns === "firstName" ? (
                      <ExcelColumn label="First Name" value={columns} />
                    ) : columns === "lastName" ? (
                      <ExcelColumn label="Last Name" value={columns} />
                    ) : columns === "email" ? (
                      <ExcelColumn label="Email" value={columns} />
                    ) : columns === "billingPhone" ? (
                      <ExcelColumn label="Phone Number" value={columns} />
                    ) : columns === "status" ? (
                      <ExcelColumn label="Status" value={columns} />
                    ) : columns === "paymentType" ? (
                      <ExcelColumn label="Payment Type" value={columns} />
                    ) : columns === "donationAmount" ? (
                      <ExcelColumn label="Donation Amount" value={columns} />
                    ) : columns === "donationDate" ? (
                      <ExcelColumn label="Donation Date" value={columns} />
                    ) : columns === "donationMatched" ? (
                      <ExcelColumn label="Donation Matched" value={columns} />
                    ) : columns === "billingAddress" ? (
                      <ExcelColumn label="Billing Address" value={columns} />
                    ) : columns === "dedicationMessage" ? (
                      <ExcelColumn label="Dedication Message" value={columns} />
                    ) : columns === "teamId" ? (
                      <ExcelColumn label="Team Id" value={columns} />
                    ) : columns === "transactionCost" ? (
                      <ExcelColumn label="Transaction Cost" value={columns} />
                    ) : columns === "transactionCostCovered" ? (
                      <ExcelColumn label="Transaction Cost Covered" value={columns} />
                    ) : columns === "transactionId" ? (
                      <ExcelColumn label="Transaction Id" value={columns} />
                    ) : columns === "raffle" ? (
                      <ExcelColumn label="Raffle Categories" value={columns} />
                    ) : columns === "specialNotes" ? (
                      <ExcelColumn label="Special Instructions" value={columns} />
                    ) : columns === "items" ? (
                      <ExcelColumn label="Items Donated" value={columns} />
                    ) : columns === "mode" ? (
                      <ExcelColumn label="Payment Mode" value={columns} />
                    ) : columns === "state" ? (
                      <ExcelColumn label="State" value={columns} />
                    ) : columns === "city" ? (
                      <ExcelColumn label="City" value={columns} />
                    ) : columns === "zip" ? (
                      <ExcelColumn label="Zip Code" value={columns} />
                    ) : (
                                                                    ""
                                                                  );
                  })}
                </ExcelSheet>
              </ExcelFile>
            </div>
          )}
          {visibleFields.length > 0 && campaignReports.length > 0 && (
            <div className="export-btn-csv">
              <Button raised={"true"}>
                <CSVLink
                  data={visibleFields || []}
                  headers={Object.keys(visibleFields[0]).map(columns => {
                    return columns === "alias"
                      ? { label: "Alias", key: "alias" }
                      : columns === "firstName"
                        ? { label: "First Name", key: "firstName" }
                        : columns === "lastName"
                          ? { label: "Last Name", key: "lastName" }
                          : columns === "email"
                            ? { label: "Email", key: "email" }
                            : columns === "billingPhone"
                              ? { label: "Phone Number", key: "billingPhone" }
                              : columns === "status"
                                ? { label: "Status", key: "status" }
                                : columns === "raffle"
                                  ? { label: "Raffle Categories", key: "raffle" }
                                  : columns === "paymentType"
                                    ? { label: "Payment Type", key: "paymentType" }
                                    : columns === "donationAmount"
                                      ? { label: "Donation Amount", key: "donationAmount" }
                                      : columns === "donationDate"
                                        ? { label: "Donation Date", key: "donationDate" }
                                        : columns === "donationMatched"
                                          ? { label: "Donation Matched", key: "donationMatched" }
                                          : columns === "billingAddress"
                                            ? { label: "Billing Address", key: "billingAddress" }
                                            : columns === "dedicationMessage"
                                              ? { label: "Dedication Message", key: "dedicationMessage" }
                                              : columns === "teamId"
                                                ? { label: "Team Id", key: "teamId" }
                                                : columns === "transactionCost"
                                                  ? { label: "Transaction Cost", key: "transactionCost" }
                                                  : columns === "transactionCostCovered"
                                                    ? { label: "Transaction Cost Covered", key: "transactionCostCovered" }
                                                    : columns === "transactionId"
                                                      ? { label: "Transaction Id", key: "transactionId" }
                                                      : columns === "specialNotes"
                                                        ? { label: "Special Instructions", key: "specialNotes" }
                                                        : columns === "items"
                                                          ? { label: "Items Donated", key: "items" }
                                                          : columns === "mode"
                                                            ? { label: "Payment Mode", key: "mode" }
                                                            : columns === "state"
                                                              ? { label: "State", key: "state" }
                                                              : columns === "city"
                                                                ? { label: "City", key: "city" }
                                                                : columns === "zip"
                                                                  ? { label: "Zip Code", key: "zip" }
                                                                  : "";
                  })}>
                  <FontAwesomeIcon
                    icon={faFileExport}
                    style={{ margin: "5%" }}
                    fontSize="large"
                  />
                  Export CSV
                </CSVLink>
              </Button>
            </div>
          )}
          {/* <Button onClick={captureDonation}>Capture Donations</Button> */}
        </div>
      </div>
    </div>
  );
};
