import React from 'react';
import './Video.scss';

export const CampaignVideo = ({ video, status, location, preCampaignEnd, displayImg }) => {
  return (
    displayImg && new Date() > new Date(preCampaignEnd) && location.pathname === '/yachad19' ?
      <div className='frame-container' style={{ width: '100%' }}>
        <iframe sandbox="allow-scripts allow-same-origin allow-presentation" className='frame' src={video.replace("watch?v=", "embed/")} title="Campaign" frameBorder={0} id="fitvid673363"></iframe>
      </div>
      :
      video ? <div className="project-header">
        <div className="video-container">
          <div className="fluid-width-video-wrapper"
            style={{
              "paddingTop": (video.indexOf('.jpg') === -1 && video.indexOf('.jpeg') === -1 && video.indexOf('.png') === -1) && "56.25%",
              display: status !== 'pre-campaign' && 'flex',
              justifyContent: status !== 'pre-campaign' && 'center'
            }}
          >
            {video.indexOf('.jpg') > -1 || video.indexOf('.jpeg') > -1 || video.indexOf('.png') > -1 ?
              <img style={{ maxHeight: status === 'pre-campaign' ? 250 : 350 }} src={video} alt='Campaign'></img> :
              <iframe sandbox="allow-scripts allow-same-origin allow-presentation" src={video.replace("watch?v=", "embed/")} title="Campaign" frameBorder={0} id="fitvid673363"></iframe>
            }
          </div>
        </div>
      </div>
        : null
  );
}
