import { createAction, handleActions } from 'redux-actions';
import { pender } from 'redux-pender';
import * as teamAPI from '../../lib/team';
import { Map } from 'immutable';

const GET_TEAMDATA = 'team/GET_TEAMDATA';
const GET_DONATIONS = 'team/GET_DONATIONS';

export const getTeam = createAction(GET_TEAMDATA, teamAPI.getTeam);
export const teamDonation = createAction(GET_DONATIONS, teamAPI.teamDonation);

const initialState = Map({
  team_data: {},
  team_donation: {}
})

export default handleActions({
  ...pender({
    type: GET_TEAMDATA,
    onSuccess: (state, action) => state.set('team_data', action.payload.data)
  }),
  ...pender({
    type: GET_DONATIONS,
    onSuccess: (state, action) => state.set('team_donation', action.payload)
  }),
}, initialState)