export const ProspectFilters = (state, action) => {
  let { status, amountFrom, amountTo, suggFrom, suggTo, method, notes } = action.payload;
  let prospects = state.prospects;
  let filteredProspects = prospects;

  if(status) {
    filteredProspects = filteredProspects.filter(prospect => prospect.status.toLowerCase() === status.toLowerCase());
  }
  if(amountFrom) {
    filteredProspects = filteredProspects.filter(prospect => parseFloat(prospect.donationAmount) >= parseFloat(amountFrom));
  }
  if(amountTo) {
    filteredProspects = filteredProspects.filter(prospect => parseFloat(prospect.donationAmount) <= parseFloat(amountTo));
  }
  if(suggFrom) {
    filteredProspects = filteredProspects.filter(prospect => parseFloat(prospect.suggestedDonation) >= parseFloat(suggFrom));
  }
  if(suggTo) {
    filteredProspects = filteredProspects.filter(prospect => parseFloat(prospect.suggestedDonation) <= parseFloat(suggTo));    
  }
  if(method) {
    filteredProspects = filteredProspects.filter(prospect => prospect.paymentType === method);    
  }
  if(notes) {
    filteredProspects = filteredProspects.filter(prospect => prospect.specialNotes);    
  }

  return filteredProspects;
}